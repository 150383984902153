import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { useTranslation } from 'react-i18next'

import A from '../A'
import Icon from '../Icon'
import CloudinaryImage from '../CloudinaryImage'
import { iconsKeys } from '../Icon/Icon.assets'
import { colors, sizes } from '../../theme'
import { imageTypes } from '../CloudinaryImage/types'
import { getWindowSize } from '../../utils/WindowSize'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const defaultImage = 'defaults/article-banner'
const useStyles = createUseStyles(styles)

const ArticleCard = (props) => {
  const classes = useStyles(props)
  const { className, title, description, image, link, labelButton, category, featuredLabel } = props

  const [isDesktop, setIsDesktop] = useState(false)

  const { t } = useTranslation()

  function handleResize() {
    const windowSize = getWindowSize()

    if (!isDesktop && windowSize.width >= sizes.tablet) {
      setIsDesktop(true)
    } else if (isDesktop && windowSize.width < sizes.tablet) {
      setIsDesktop(false)
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  // eslint-disable-next-line complexity
  function renderContent() {
    return (
      <div
        className={
          cx(
            classes.content,
            category.theme === 'PORTRAIT' && classes.isPortrait,
            category.theme === 'FEATURED' && classes.isFeatured
          )
        }
      >
        {category.theme === 'FEATURED' && (
          <span
            className={classes.featuredLabel}
            dangerouslySetInnerHTML={{ __html: featuredLabel }}
          />
        )}
        {category.name && (
          <div
            className={
              cx(
                classes.typeLabel,
                (category.theme === 'PORTRAIT' || category.theme === 'FEATURED') && classes.colorWhite,
                category.theme === 'FEATURED' && classes.noBorder
              )
            }
          >
            {category.name}
          </div>
        )}
        {title && (
          <div className={cx(classes.title, (category.theme === 'PORTRAIT' || category.theme === 'FEATURED') && classes.colorWhite)}>
            {title}
          </div>
        )}
        {description && (
          <div className={cx(classes.description, (category.theme === 'PORTRAIT' || category.theme === 'FEATURED') && classes.colorWhite)}>
            {description}
          </div>
        )}
        {labelButton && category.theme !== 'BREF' && (
          <div className={cx(classes.btn, (category.theme === 'PORTRAIT' || category.theme === 'FEATURED') && classes.colorWhite)}>
            {labelButton}
            <Icon
              icon={iconsKeys.ArrowRight}
              color={(category.theme === 'PORTRAIT' || category.theme === 'FEATURED') ? colors.pureWhite : colors.darkBlue}
              className={classes.btnIcon}
            />
          </div>
        )}
      </div>
    )
  }

  function renderImage() {
    return (
      <CloudinaryImage
        id={image || defaultImage}
        className={classes.image}
        type={imageTypes.Image}
        covered
        options={{
          width: '300',
          height: '150',
          crop: 'fill',
        }}
        lazyLoad
        alt={t('SEO_actu_related_image') + title}
      />
    )
  }

  function renderBackgroundCover() {
    return (
      <CloudinaryImage
        id={image || defaultImage}
        className={classes.cover}
        type={imageTypes.Background}
        covered
        options={{
          width: category.theme === 'FEATURED' ? (
            isDesktop ? '940' : '300'
          ) : '300',
          height: '415',
          crop: 'fill',
        }}
        lazyLoad
        alt={t('SEO_actu_related_image') + title}
      >
        {renderContent()}
      </CloudinaryImage>
    )
  }

  return (
    <A
      href={link}
      className={
        cx(
          classes.container,
          className
        )
      }
      title={title}
    >
      {category.theme === 'IMAGE' && renderImage()}
      {(category.theme === 'PORTRAIT' || category.theme === 'FEATURED') && renderBackgroundCover()}
      {(category.theme === 'BREF' || category.theme === 'IMAGE') && renderContent()}
    </A>
  )
}

ArticleCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  link: PropTypes.string,
  labelButton: PropTypes.string,
  category: PropTypes.shape({
    name: PropTypes.string,
    theme: PropTypes.string,
    bgColor: PropTypes.string,
  }),
  featuredLabel: PropTypes.string,
}

ArticleCard.defaultProps = {
  className: '',
  title: null,
  description: null,
  image: null,
  link: null,
  labelButton: null,
  category: null,
  featuredLabel: null,
}

export default withMemo()(ArticleCard)
