import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import MarkdownText from '../../../MarkdownText'
import withMemo from '../../../../decorators/WithMemo'
import { ActionButton } from '../../../index'
import Linkable from '../../../../data/models/Linkable'
import EventModel from '../../../../data/models/Event'
import EventCard from '../../../EventCard'
import CloudinaryImage from '../../../CloudinaryImage'

import styles from './styles'


const useStyles = createUseStyles(styles)

const LastEvents = (props) => {
  const classes = useStyles(props)
  const {
    className,
    title,
    events,
    ctaLabel,
    ctaLinkable,
    ctaUrl,
  } = props

  return (
    <div className={cx(classes.container, className)}>
      <CloudinaryImage
        className={classes.background}
        id="Homepage/background-events"
      />
      <MarkdownText
        tag="h2"
        className={classes.title}
        text={title}
        inline
      />
      <div className={classes.wrapper}>
        <div className={classes.entries}>
          {
          events?.map((event, key) => (
            <EventCard
              {...event}
              key={key}
              className={classes.entry}
            />
          ))
          }
        </div>
        <ActionButton
          className={classes.cta}
          label={ctaLabel}
          route={ctaLinkable?.route?.name}
          routeParams={ctaLinkable?.route?.params}
          link={ctaUrl}
        />
      </div>
    </div>
  )
}

LastEvents.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  events: PropTypes.arrayOf(EventModel.propTypes),
  ctaLabel: PropTypes.string,
  ctaUrl: PropTypes.string,
  ctaLinkable: Linkable.propTypes,
}

LastEvents.defaultProps = {
  className: '',
  title: '',
  events: null,
  ctaLabel: '',
  ctaUrl: '',
  ctaLinkable: null,
}

export default withMemo()(LastEvents)
