import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Immutable from 'seamless-immutable'
import { useSelector } from 'react-redux'

import { Router } from '../../routes'
import { selectors as AppSelectors } from '../../redux/app/redux'
import { composeQuery, getFilterOptions, parseQueryParams, getPagination } from '../../helpers/FilterHelpers'

import ArticleFragment from './index'
import { articlePrefix } from './config'


function ArticleFragmentWrapper(props) {
  const { t } = useTranslation()
  const route = useSelector(AppSelectors.route)
  const { articles, pagination, filterConfig, filterValues } = props

  const params = {
    ...parseQueryParams(route?.asPath, route?.slug),
  }

  const newPagination = getPagination(pagination, route?.asPath, route?.query, params)


  const handleSubmit = useCallback((p, resetPage = true) => {
    const href = composeQuery(p, route?.query, pagination, resetPage)

    Router.pushRoute(href)
  }, [route, pagination])

  const filterOptions = useMemo(() => getFilterOptions(t, filterConfig, articlePrefix), [filterConfig, t])

  let returnedArticles = articles

  const highlightedArticle = returnedArticles.length > 0
    ? returnedArticles.find((article) => article.isHighlighted)
    : null

  if (highlightedArticle) {
    const isMutable = Immutable.isImmutable(returnedArticles)

    if (isMutable) {
      returnedArticles = Immutable.asMutable(returnedArticles)
    }
    returnedArticles = returnedArticles.filter((_, index) => index !== returnedArticles.indexOf(highlightedArticle))
    returnedArticles.unshift({ ...highlightedArticle, category: { name: highlightedArticle.category.name, theme: 'FEATURED' } })
  }

  const data = {
    ...props,
    articles: returnedArticles,
    emptyArticlesText: t('emptyArticlesText'),
    pagination: {
      ...newPagination,
      routeParams: {
        slug: route.query.slug,
      },
    },
    filterBoxProps: {
      placeholder: t('articleFragment_filterPlaceholder'),
      filters: filterOptions,
      values: filterValues,
    },
    handleSubmit,
    filterTitle: t('articleFragment_filterTitle'),
  }

  return <ArticleFragment {...data} />
}

ArticleFragmentWrapper.propTypes = ArticleFragment.propTypes

ArticleFragmentWrapper.defaultProps = ArticleFragment.defaultProps

export default ArticleFragmentWrapper
