import routes from '../../routes'
import { routeParamSlug } from '../../helpers/UrlHelpers'


export default (props) => ({
  __typename: 'EventPublicBlock',
  ...props.title && {
    title: props.title,
  },
  ...props.introduction && {
    introduction: props.introduction,
  },
  ...props.linkable && (props.linkable?.__typename === 'Page' ? {
    route: routes.page.name,
    routeParams: {
      slug: routeParamSlug(props.linkable.full_slug),
    },
  } : {
    route: props.linkable?.key,
  }),
  ...props.cta_title && props.url && props.url !== '' && {
    ctaLink: props.url,
  },
  ...props.cta_title && props.linkable?.full_slug && {
    ctaLink: props.linkable.full_slug,
  },
  ctaLabel: props.cta_title,
  ...props.text_before_title_texts && {
    textBeforeTitleTexts: props.text_before_title_texts,
  },
  ...props.text_after_title_texts && {
    textAfterTitleTexts: props.text_after_title_texts,
  },
  ...props.title_text_container.length && {
    titleTextContainer: Object.defineProperty(
      { ...props.title_text_container[0] },
      'titleTexts',
      Object.getOwnPropertyDescriptor(props.title_text_container[0], 'title_texts')
    ),
  },
})
