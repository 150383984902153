import routes from '../../routes'
import { pageParamSlug } from '../../helpers/UrlHelpers'


export default (props) => ({
  ...props,
  ...props.routable && (props.routable?.__typename === 'Page' ? {
    route: routes.page.name,
    routeParams: {
      slug: pageParamSlug(props.routable.full_slug),
    },
  } : {
    route: props.routable?.key,
  }),
})
