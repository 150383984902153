export const postType = {
  POST_TYPE_LINKING: 'POST_TYPE_LINKING',
  POST_TYPE_PURCHASING_GROUP: 'POST_TYPE_PURCHASING_GROUP',
  POST_TYPE_CALL_FOR_PROMOTION: 'POST_TYPE_CALL_FOR_PROMOTION',
  POST_TYPE_OGCN: 'POST_TYPE_OGCN',
}

export const postTypeArray = [
  postType.POST_TYPE_LINKING,
  postType.POST_TYPE_PURCHASING_GROUP,
  postType.POST_TYPE_CALL_FOR_PROMOTION,
  postType.POST_TYPE_OGCN,
]

export const postTypeSlugs = {
  [postType.POST_TYPE_LINKING]: 'linking',
  [postType.POST_TYPE_PURCHASING_GROUP]: 'purchasing-group',
  [postType.POST_TYPE_CALL_FOR_PROMOTION]: 'call-for-promotion',
  [postType.POST_TYPE_OGCN]: 'ogcn',
}

export const postTypeConfig = postTypeArray.map((value) => ({
  key: `postType_${value}`,
  id: value,
  value: postTypeSlugs[value],
}))

export const postTypeOptions = (t) => postTypeArray.map((value) => ({
  label: t(`postType_${value}`),
  value,
}))
