
export const filterDateType = {
  FILTER_DATE_SAME_DAY: 'FILTER_DATE_SAME_DAY',
  FILTER_DATE_SAME_WEEK: 'FILTER_DATE_SAME_WEEK',
  FILTER_DATE_SAME_MONTH: 'FILTER_DATE_SAME_MONTH',
}

export const filterDateTypeArray = [
  filterDateType.FILTER_DATE_SAME_DAY,
  filterDateType.FILTER_DATE_SAME_WEEK,
  filterDateType.FILTER_DATE_SAME_MONTH,
]

const filterDateTypeSlugs = {
  [filterDateType.FILTER_DATE_SAME_DAY]: 'today',
  [filterDateType.FILTER_DATE_SAME_WEEK]: 'current-week',
  [filterDateType.FILTER_DATE_SAME_MONTH]: 'current-month',
}

export const filterDateTypeConfig = filterDateTypeArray.map((value) => ({
  key: `filterDateType_${value}`,
  id: value,
  value: filterDateTypeSlugs[value],
}))
