export const offerDeadline = {
  OFFER_DEADLINE_1_MONTH: 'OFFER_DEADLINE_1_MONTH',
  OFFER_DEADLINE_3_MONTHS: 'OFFER_DEADLINE_3_MONTHS',
  OFFER_DEADLINE_6_MONTHS: 'OFFER_DEADLINE_6_MONTHS',
  OFFER_DEADLINE_THIS_YEAR: 'OFFER_DEADLINE_THIS_YEAR',
}

export const offerDeadlineArray = [
  offerDeadline.OFFER_DEADLINE_1_MONTH,
  offerDeadline.OFFER_DEADLINE_3_MONTHS,
  offerDeadline.OFFER_DEADLINE_6_MONTHS,
  offerDeadline.OFFER_DEADLINE_THIS_YEAR,
]

export const offerDeadlineSlugs = {
  [offerDeadline.OFFER_DEADLINE_1_MONTH]: '1m',
  [offerDeadline.OFFER_DEADLINE_3_MONTHS]: '3m',
  [offerDeadline.OFFER_DEADLINE_6_MONTHS]: '6m',
  [offerDeadline.OFFER_DEADLINE_THIS_YEAR]: '1y',
}

export const offerDeadlineConfig = offerDeadlineArray.map((value) => ({
  key: `offerDeadline_${value}`,
  id: value,
  value: offerDeadlineSlugs[value],
}))

export const offerDeadlineOptions = (t) => offerDeadlineArray.map((value) => ({
  label: t(`offerDeadline_${value}`),
  value,
}))
