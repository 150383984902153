import React, { useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { useTranslation } from 'react-i18next'

import withMemo from '../../decorators/WithMemo'
import ActionButton from '../ActionButton'
import PublicTitleTextContainer from '../PublicTitleTextContainer'
import MarkdownText from '../MarkdownText'
import RegisterModal from '../RegisterModal'
import app from '../../config/app'

import styles from './styles'


const useStyles = createUseStyles(styles)

const DirectoryPublic = (props) => {
  const {
    className,
    isConnected,
    title,
    introduction,
    ctaLink,
    ctaLabel,
    textBeforeTitleTexts,
    firstTitleTextContainer,
    textBetweenTitleTexts,
    secondTitleTextContainer,
    textAfterTitleTexts,
  } = props

  const { t } = useTranslation()

  const [showingMore, setShowingMore] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
  const classes = useStyles({ ...props, showingMore })

  const showLess = () => setShowingMore(false)
  const showMore = () => setShowingMore(true)

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.containerWhite}>
        <div className={classes.subcontainer}>
          <MarkdownText
            className={classes.title}
            text={title}
          />
          <MarkdownText
            className={classes.text}
            text={introduction}
          />
          <RegisterModal
            modalTitle={t('directoryPublic_modal_title_unauthenticated')}
            modalText={t('directoryPublic_modal_text_unauthenticated')}
            modalCta={t('directoryPublic_modal_cta_unauthenticated')}
            isOpen={isModalOpen}
            onClose={() => setModalOpen(false)}
          />
          {ctaLabel && ctaLink && (
            <ActionButton
              className={classes.cta}
              label={ctaLabel}
              link={isConnected ? app.REGISTER_URL : null}
              onClick={isConnected ? null : () => setModalOpen(true)}
            />
          )}
        </div>
      </div>
      <div className={classes.containerGrey}>
        <div className={classes.subcontainer}>
          <MarkdownText
            text={textBeforeTitleTexts}
          />
          {showingMore && (
            <>
              <PublicTitleTextContainer
                className={classes.titleTextContainer}
                titleTexts={firstTitleTextContainer.titleTexts}
                textBlue
              />
              <MarkdownText
                text={textBetweenTitleTexts}
              />
              <PublicTitleTextContainer
                className={classes.titleTextContainer}
                titleTexts={secondTitleTextContainer.titleTexts}
              />
              <MarkdownText
                text={textAfterTitleTexts}
              />
            </>
          )}
          <div className={classes.showMoreContainer}>
            <ActionButton
              className={classes.showMore}
              label={showingMore ? 'Voir moins' : 'Voir plus'}
              onClick={showingMore ? showLess : showMore}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

DirectoryPublic.propTypes = {
  className: PropTypes.string,
  isConnected: PropTypes.bool,
  title: PropTypes.string.isRequired,
  introduction: PropTypes.string,
  ctaLink: PropTypes.string,
  ctaLabel: PropTypes.string,
  textBeforeTitleTexts: PropTypes.string,
  firstTitleTextContainer: PropTypes.object,
  textBetweenTitleTexts: PropTypes.string,
  secondTitleTextContainer: PropTypes.object,
  textAfterTitleTexts: PropTypes.string,
}

DirectoryPublic.defaultProps = {
  className: '',
  isConnected: false,
  introduction: null,
  ctaLink: null,
  ctaLabel: null,
  textBeforeTitleTexts: '',
  firstTitleTextContainer: {},
  textBetweenTitleTexts: '',
  secondTitleTextContainer: {},
  textAfterTitleTexts: '',
}

export default withMemo()(DirectoryPublic)
