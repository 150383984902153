import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import VideoPlayer from '../../../VideoPlayer'
import ActionButton from '../../../ActionButton'
import { iconsKeys } from '../../../Icon/Icon.assets'
import { colors } from '../../../../theme'
import withMemo from '../../../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const Video = (props) => {
  const classes = useStyles(props)
  const { className, video, mail, ctaLabel } = props

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        <div className={classes.video}>
          <VideoPlayer video={video} />
        </div>
        <ActionButton
          className={classes.cta}
          label={ctaLabel}
          link={mail}
          target="_blank"
          color="white"
          isOutlined
          icon={iconsKeys.Calendar}
          colorIcon={colors.pureWhite}
          colorIconHover={colors.darkBlue}
        />
      </div>
    </div>
  )
}

Video.propTypes = {
  className: PropTypes.string,
  video: PropTypes.string,
  mail: PropTypes.string,
  ctaLabel: PropTypes.string,
}

Video.defaultProps = {
  className: '',
  video: '',
  mail: '',
  ctaLabel: '',
}


export default withMemo()(Video)
