import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import withMemo from '../../decorators/WithMemo'
import {
  buildQueryWithSlug,
  getFilterOptions,
  getPagination,
  getSortOptions,
  parseQueryParams
} from '../../helpers/FilterHelpers'
import { selectors as AppSelectors } from '../../redux/app/redux'
import { selectors as authSelectors } from '../../redux/auth/redux'
import { Router } from '../../routes'
import PushPremiumWrapper from '../PushPremium/wrapper'

import { eventsPrefix } from './config'
import EventsResults from './index'


function EventsResultsWrapper(props) {
  const { t } = useTranslation()
  const route = useSelector(AppSelectors.route)
  const isPremium = useSelector(authSelectors.isActivePremium)
  const isOGCN = useSelector(authSelectors.isOGCN)
  const {
    filters,
    events, pagination,
    pushPremium, sortValue, filterValues, sortConfig,
    filterConfig,
  } = props

  const handleSubmit = useCallback((p, resetPage = false) => {
    const href = buildQueryWithSlug(route, filterConfig, pagination, p, resetPage)

    Router.pushRoute(href)
  }, [route, filterConfig, pagination])

  const params = {
    ...filters,
    ...parseQueryParams(route?.asPath, route?.slug),
  }

  const filterOptions = useMemo(() => getFilterOptions(t, filterConfig, eventsPrefix), [filterConfig, t])
  const sortOptions = useMemo(() => getSortOptions(t, sortConfig, eventsPrefix), [sortConfig, t])

  // OGCN filter only visible for OGCN members
  const newFilterOptions = isOGCN
    ? filterOptions
    : [{ ...filterOptions[0], options: filterOptions[0].options.filter((o) => o.value !== 'ogcn') }, filterOptions[1]]

  // Pagination params
  const paginationProps = useMemo(() => {
    const p = getPagination(pagination, route?.asPath, route?.query, params)

    return p
  }, [pagination, params, route])

  const data = {
    ...props,
    sortBoxProps: {
      placeholder: t('eventsResults_sortPlaceholder'),
      options: sortOptions,
      value: sortValue,
    },
    filterBoxProps: {
      placeholder: t('eventsResults_filterPlaceholder'),
      filters: newFilterOptions,
      values: filterValues,
    },
    events: events.map((event) => ({
      ...event,
      ctaLabel: t('eventCardKnowMore'),
    })),
    handleSubmit,
    emptyEventsText: t('eventsResults_emptyLabel'),
    pagination: paginationProps,
  }

  return (
    <EventsResults
      {...data}
      pushPremium={!isPremium && pushPremium && <PushPremiumWrapper />}
      isCurrentOGCN={isOGCN}
    />
  )
}

EventsResultsWrapper.propTypes = EventsResults.propTypes

EventsResultsWrapper.defaultProps = EventsResults.defaultProps

export default withMemo()(EventsResultsWrapper)
