export const articlePrefix = 'articleFragment'


export const articlesFiltersConfig = (categories) => [
  {
    isMulti: true,
    isPath: true,
    name: 'categories',
    slug: 'categorie',
    prefix: 'articleCategory',
    options: categories?.map((s) => ({
      label: s.name,
      value: s.id,
      name: s.id,
      id: s.id,
    })),
  },
]
