export const offerBudget = {
  OFFER_BUDGET_LESS_THAN_5K: 'OFFER_BUDGET_LESS_THAN_5K',
  OFFER_BUDGET_BETWEEN_5K_AND_10K: 'OFFER_BUDGET_BETWEEN_5K_AND_10K',
  OFFER_BUDGET_BETWEEN_10K_AND_20K: 'OFFER_BUDGET_BETWEEN_10K_AND_20K',
  OFFER_BUDGET_BETWEEN_20K_AND_50K: 'OFFER_BUDGET_BETWEEN_20K_AND_50K',
  OFFER_BUDGET_BETWEEN_50K_AND_100K: 'OFFER_BUDGET_BETWEEN_50K_AND_100K',
  OFFER_BUDGET_MORE_THAN_100K: 'OFFER_BUDGET_MORE_THAN_100K',
}

export const offerBudgetArray = [
  offerBudget.OFFER_BUDGET_LESS_THAN_5K,
  offerBudget.OFFER_BUDGET_BETWEEN_5K_AND_10K,
  offerBudget.OFFER_BUDGET_BETWEEN_10K_AND_20K,
  offerBudget.OFFER_BUDGET_BETWEEN_20K_AND_50K,
  offerBudget.OFFER_BUDGET_BETWEEN_50K_AND_100K,
  offerBudget.OFFER_BUDGET_MORE_THAN_100K,
]

export const offerBudgetSlugs = {
  [offerBudget.OFFER_BUDGET_LESS_THAN_5K]: '5k',
  [offerBudget.OFFER_BUDGET_BETWEEN_5K_AND_10K]: '10k',
  [offerBudget.OFFER_BUDGET_BETWEEN_10K_AND_20K]: '20k',
  [offerBudget.OFFER_BUDGET_BETWEEN_20K_AND_50K]: '50k',
  [offerBudget.OFFER_BUDGET_BETWEEN_50K_AND_100K]: '100k',
  [offerBudget.OFFER_BUDGET_MORE_THAN_100K]: '100k+',
}

export const offerBudgetConfig = offerBudgetArray.map((value) => ({
  key: `offerBudget_${value}`,
  id: value,
  value: offerBudgetSlugs[value],
}))

export const offerBudgetOptions = (t) => offerBudgetArray.map((value) => ({
  label: t(`offerBudget_${value}`),
  value,
}))
