import { colors, fonts, sizes, tablet } from '../../../../theme'


export default {
  container: {
    position: 'relative',
  },
  background: {
    position: 'absolute',
    '& img': {
      maxWidth: 'none',
      maxHeight: 'none',
    },
  },
  wrapper: {
    maxWidth: `${sizes.desktop / 10}rem`,
    padding: '5rem 2rem',
    margin: '0 auto',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    ...fonts.FiraSansBold,
    margin: '0',
    fontSize: '3rem',
    lineHeight: '120%',
    marginBottom: '1rem',
    color: colors.pureWhite,
  },
  text: {
    ...fonts.FiraSansRegular,
    fontSize: '1.8rem',
    lineHeight: '120%',
    marginBottom: '6rem',
    color: colors.gainsboro,
  },
  entries: {
    display: 'flex',
    flexDirection: 'column',
    margin: '-2rem 0 3rem',
    ...tablet({
      flexDirection: 'row',
      margin: '0 -2rem 3rem',
    }),
  },
  entry: {
    ...fonts.FiraSansRegular,
    fontSize: '2rem',
    lineHeight: '120%',
    marginBottom: '4rem',
    color: colors.icebergBlue,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '2rem',
  },
  entryTitle: {
    ...fonts.FiraSansBold,
    margin: '0',
    fontSize: '2rem',
    lineHeight: '120%',
    marginBottom: '2rem',
    color: colors.icebergBlue,
  },
  entryText: {
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    lineHeight: '120%',
    color: colors.gainsboro,
  },
  separator: {
    width: '5rem',
    height: '0.2rem',
    background: colors.icebergBlue,
    marginBottom: '2rem',
  },
  cta: {
    alignSelf: 'center',
    marginTop: '-2rem',
    marginBottom: '4rem',
    ...tablet({
      marginBottom: '5rem',
    }),
  },
}
