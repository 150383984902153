/* eslint-disable max-lines */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import FilterBasicBox from '../FilterBasicBox'
import Pagination from '../Pagination'
import withMemo from '../../decorators/WithMemo'
import { mergeStyles } from '../../utils/StyleUtils'

import styles from './styles'


const useStyles = createUseStyles(styles)

// eslint-disable-next-line complexity
const PaginatedFilteredResults = (props) => {
  const {
    className,
    filterBoxProps,
    results,
    emptyText,
    pagination,
    handleSubmit,
    children,
    classes: classesProp,
    filterTitle,
  } = props

  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesComp, classesProp])

  const [filterValues, setFilterValues] = useState(filterBoxProps.values || [])
  const [isFilterOpen, setFilterOpen] = useState(false)
  const [timerId, setTimerId] = useState(null)
  const [needsUpdate, setNeedsUpdate] = useState(false)

  useEffect(() => {
    setFilterOpen(false)
  }, [])

  useEffect(() => {
    setFilterOpen(false)
    setFilterValues(filterBoxProps.values)
  }, [filterBoxProps.values])


  const handleFilterChange = useCallback(({ name, value }) => {
    setFilterValues((before) => {
      const b = Array.isArray(before) && before?.filter((item) => item.name !== name)

      return [
        ...b || [],
        {
          name,
          value,
        },
      ]
    })
    setNeedsUpdate(true)
  }, [])

  const doSearch = useCallback(() => {
    if (timerId) {
      clearTimeout(timerId)
    }
    setTimerId(setTimeout(() => {
      handleSubmit({ filterValues })
    }, 500))
  }, [filterValues, handleSubmit, timerId])


  const handleToggleFiltering = useCallback(() => {
    setFilterOpen(!isFilterOpen)
  }, [isFilterOpen])


  const filterContainerRef = React.createRef()
  const filterSubmenuRef = React.createRef()

  const hasParent = useCallback((target, parent, currentRef) => {
    if (!target) {
      return false
    }
    if (target === parent || target.parentElement === currentRef) {
      return true
    }
    return hasParent(target.parentElement, parent, currentRef)
  }, [])

  const handleWindowClick = useCallback((e) => {
    if (isFilterOpen) {
      if (!hasParent(e.target, filterContainerRef.current, filterSubmenuRef.current)) {
        setFilterOpen(false)
      }
    }
  }, [filterContainerRef, filterSubmenuRef, hasParent, isFilterOpen])

  useEffect(() => {
    window.addEventListener('click', handleWindowClick)

    return () => {
      window.removeEventListener('click', handleWindowClick)
    }
  }, [handleWindowClick])

  useEffect(() => {
    if (needsUpdate) {
      setNeedsUpdate(false)
      doSearch()
    }
  }, [needsUpdate, doSearch])

  const ref = useRef()

  return (
    <div
      className={cx(classes.container, className)}
      ref={ref}
    >
      <div className={classes.filterTitle}>{filterTitle}</div>
      <div className={classes.filters}>
        {filterBoxProps && (
          <FilterBasicBox
            {...filterBoxProps}
            className={classes.filterBlock}
            isOpen={isFilterOpen}
            toggleSelect={handleToggleFiltering}
            values={filterValues}
            onChange={handleFilterChange}
            containerRef={filterContainerRef}
            submenuRef={filterSubmenuRef}
          />
        )}
      </div>

      <div className={classes.results}>
        {results.length >= 1 && children}
        {results.length === 0 && emptyText !== null && (
          <p
            className={classes.empty}
            dangerouslySetInnerHTML={{ __html: emptyText }}
          />
        )}
      </div>

      {results.length >= 1 && pagination && (
        <>
          <div className={classes.divider} />
          <Pagination {...pagination} />
        </>
      )}



    </div>
  )
}

PaginatedFilteredResults.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  filterBoxProps: PropTypes.shape(FilterBasicBox.propTypes),
  results: PropTypes.arrayOf(PropTypes.shape({})),
  emptyText: PropTypes.string,
  pagination: PropTypes.shape(Pagination.propTypes),
  text: PropTypes.string,
  handleSubmit: PropTypes.func,
  children: PropTypes.node,
  filterTitle: PropTypes.string,
}

PaginatedFilteredResults.defaultProps = {
  className: '',
  classes: null,
  filterBoxProps: null,
  results: [],
  emptyText: null,
  pagination: null,
  text: null,
  handleSubmit: () => undefined,
  children: null,
  filterTitle: '',
}

export default withMemo()(PaginatedFilteredResults)
