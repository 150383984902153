import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import OfferCard from '../OfferCard'
import withMemo from '../../decorators/WithMemo'
import OfferSmallCard from '../OfferSmallCard'
import { MarkdownText } from '../index'

import styles from './styles'


const useStyles = createUseStyles(styles)

const OfferFinishingOffers = (props) => {
  const classes = useStyles(props)
  const { className, title, cards } = props

  const renderCards = () => cards.map((card, index) => (
    <OfferSmallCard
      {...card}
      key={`offerSimilarOffers-${index}`}
      className={classes.card}
    />
  ))

  return !!cards && cards.length > 0
    && (
    <div className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        <MarkdownText
          className={classes.title}
          text={title}
          inline
        />
        <div className={classes.cards}>
          {renderCards()}
        </div>
      </div>
    </div>
    )
}

OfferFinishingOffers.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  cards: PropTypes.arrayOf(PropTypes.shape(OfferCard.propTypes)),
}

OfferFinishingOffers.defaultProps = {
  className: '',
  title: '',
  cards: [],
}

export default withMemo()(OfferFinishingOffers)
