import { colors, fonts } from '../../theme'


export default {
  container: {
  },
  wrapper: {
    display: 'flex',
  },
  avatarBox: {
    position: 'relative',
    width: '9.6rem',
    height: '9.6rem',
    marginRight: '2rem',
  },
  avatarArround: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  playBox: {
    position: 'absolute',
    bottom: '-7%',
    right: '-7%',
    width: '3.8rem',
    height: '3.8rem',
    borderRadius: '50%',
    background: colors.tangerine,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  avatar: {
    width: '9.6rem',
    height: '9.6rem',
    '& img': {
      borderRadius: '50%',
    },
  },
  content: {
    maxWidth: '18rem',
    marginTop: '-1rem',
  },
  contentText: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '1.7rem',
  },
  contentTextQuote: {
    marginRight: '0.5rem',
  },
  text: {
    margin: '0',
    color: colors.pureWhite,
    ...fonts.FiraSansMedium,
    fontSize: '1.8rem',
    lineHeight: '120%',
  },
  name: {
    color: colors.orangePeel,
    ...fonts.FiraSansBold,
    fontSize: '1.4rem',
    marginLeft: '1.8rem',
  },
  role: {
    color: colors.pureWhite,
    ...fonts.FiraSansRegular,
    fontSize: '1.2rem',
    lineHeight: '120%',
    marginLeft: '1.8rem',
  },
}
