import { colors, sizes, tablet } from '../../../../theme'


export default {
  container: {
    background: colors.darkBlue,
  },
  wrapper: {
    maxWidth: `${sizes.desktop / 10}rem`,
    margin: '0 auto',
    padding: '2rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    ...tablet({
      padding: '2rem 0',
    }),
  },
  video: {
    position: 'relative',
    bottom: '6rem',
    zIndex: 2,
  },
  cta: {
    width: 'max-content',
    alignSelf: 'center',
  },
}
