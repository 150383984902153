export default (props) => ({
  __typename: 'OffersBlock',
  ...props.offers_title && {
    title: props.offers_title,
  },
  ...props.offers_text && {
    text: props.offers_text,
  },
  ...props.offers_table_heading && {
    tableHeading: props.offers_table_heading,
  },
  ...props.offers_sections && {
    sections: props.offers_sections.map((section) => ({
      title: section.title,
      arguments: section.arguments.map((argument) => ({
        title: argument.title,
        type: argument.type,
      })),
    })),
  },
  ...props.offers_cta_label && {
    ctaLabel: props.offers_cta_label,
  },
  ...props.premium_price && {
    premiumPrice: props.premium_price,
  },
})
