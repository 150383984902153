export default (props, { pages }) => ({
  __typename: 'CountdownBlock',
  ...props.text && {
    text: props.text,
  },
  ...props.date && {
    date: props.date,
  },
  ...props.image && {
    image: props.image,
  },
  ...props.copyright && {
    copyright: props.copyright,
  },
  ...props.cta_link && {
    ctaLink: props.cta_link,
  },
  ...props.cta_title && {
    ctaTitle: props.cta_title,
  },
})
