import moment from 'moment'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import withMemo from '../../decorators/WithMemo'
import {
  buildQueryWithSlug,
  getFilterOptions,
  getPagination,
  parseQueryParams
} from '../../helpers/FilterHelpers'
import { routeParamSlug } from '../../helpers/UrlHelpers'
import { selectors as AppSelectors } from '../../redux/app/redux'
import { selectors as AuthSelectors } from '../../redux/auth/redux'
import routes, { Router } from '../../routes'
import { colors } from '../../theme'
import { iconsKeys } from '../Icon/Icon.assets'

import { postsPrefix } from './config'
import PostsResults from './index'


function PostsResultsWrapper(props) {
  const { t } = useTranslation()
  const route = useSelector(AppSelectors.route)
  const isConnected = useSelector(AuthSelectors.isConnected)
  const isPremium = useSelector(AuthSelectors.isActivePremium)
  const isOGCN = useSelector(AuthSelectors.isOGCN)
  const {
    filters,
    posts,
    pagination,
    filterValues,
    filterConfig,
  } = props

  const handleSubmit = useCallback((p, resetPage = false) => {
    const href = buildQueryWithSlug(route, filterConfig, pagination, p, resetPage)

    Router.pushRoute(href)
  }, [route, filterConfig, pagination])

  const params = {
    ...filters,
    ...parseQueryParams(route?.asPath, route?.slug),
  }

  const filterOptions = useMemo(() => getFilterOptions(t, filterConfig, postsPrefix), [filterConfig, t])

  // OGCN filter only visible for OGCN members
  const newFilterOptions = isOGCN
    ? filterOptions
    : [{ ...filterOptions[0], options: filterOptions[0].options.filter((o) => o.value !== 'ogcn') }]

  // Pagination params
  const paginationProps = useMemo(() => {
    const p = getPagination(pagination, route?.asPath, route?.query, params)

    return p
  }, [pagination, params, route])

  const data = {
    ...props,
    isConnected,
    isPremium,
    filterBoxProps: {
      placeholder: t('postsResults_filterPlaceholder'),
      filters: newFilterOptions,
      values: filterValues,
    },
    posts: posts.map((post) => {
      const publishDate = moment(post.publishedAt).toDate()

      return ({
        ...post,
        files: {
          ctaLabel: t('postCardSeeFile'),
          ...post.files,
        },
        typeLabel: t(`postType_${post.type}`),
        type: post.type,
        isOGCN: post.isOGCN,
        linkLabel: post.linkLabel ? post.linkLabel : t('postCardLink'),
        dateLabel: post.isPublished
          ? t('postCardDateLabel', { date: publishDate })
          : t('postCardDateLabelUnpublished'),
        shortDateLabel: post.isPublished
          ? t('postCardDateLabelShort', { date: publishDate })
          : t('postCardDateLabelUnpublished'),
        seeProfileLabel: t('postCardSeeProfileLabel'),
        seeProfileLink: Router.getRouteUrl(routes.profile, { slug: post?.user?.slug }),
        seeProfileRoute: routes.profile.name,
        seeProfileRouteParams: { slug: routeParamSlug(post?.user?.slug) },
      })
    }),
    handleSubmit,
    emptyPostsText: t('postsResults_emptyLabel'),
    pagination: paginationProps,
    pushPublishProps: {
      text: t('postsResults_pushPublishText'),
      ctaLabel: t('postsResults_pushPublishCta'),
      ctaLink: Router.getRouteUrl(routes.userPublishPost, {}),
      ctaRoute: routes.userPublishPost.name,
      ctaRouteParams: { },
      ctaProps: {
        icon: iconsKeys.SmallArrowRight,
        iconRight: true,
        colorIcon: colors.pureWhite,
        colorIconHover: colors.darkBlue,
      },
      isCard: false,
      hasModal: true,
      modalTitle: t('postsResults_publish_post_title_unauthenticated'),
      modalText: t('postsResults_publish_post_text_unauthenticated'),
      modalCta: t('postsResults_publish_post_cta_unauthenticated'),
    },
  }

  return (
    <PostsResults
      {...data}
      isCurrentOGCN={isOGCN}
    />
  )
}

PostsResultsWrapper.propTypes = PostsResults.propTypes

PostsResultsWrapper.defaultProps = PostsResults.defaultProps

export default withMemo()(PostsResultsWrapper)
