import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import Accordion from '../Accordion'

import styles from './styles'


const useStyles = createUseStyles(styles)

const AccordionList = (props) => {
  const classes = useStyles(props)
  const {
    className,
    title,
    questions,
  } = props


  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        <div className={classes.title}>
          {title}
        </div>
        <div className={classes.content}>
          {questions.map((q, i) => (
            <Accordion
              key={i}
              title={q.title}
              text={q.text}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

AccordionList.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  questions: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
  })),
}

AccordionList.defaultProps = {
  className: '',
  title: '',
  questions: [],
}

export default withMemo()(AccordionList)
