import MembershipEntry from '../../data/models/MembershipEntry'
import HowToEntry from '../../data/models/HowToEntry'
import Linkable from '../../data/models/Linkable'
import { LastOffersBlock } from '../transformers'
import EventModel from '../../data/models/Event'


export default (props, { pages }) => ({
  __typename: 'HomePageBlock',
  registerBanner: props.registerBanner,
  registerTitle: props.registerTitle,
  registerCtaLabel: props.registerCtaLabel,
  registerCtaLinkable: Linkable(props.registerCtaLinkable),
  registerCtaUrl: props.registerCtaUrl,
  membershipTitle: props.membershipTitle,
  membershipText: props.membershipText,
  membershipEntries: props.membershipEntries?.map(MembershipEntry),
  howToTitle: props.howToTitle,
  howToEntries: props?.howToEntries?.map(HowToEntry),
  eventsTitle: props?.eventsTitle,
  eventsEntries: props?.eventsEntries?.data?.map(EventModel),
  eventsCtaLabel: props.eventsCtaLabel,
  eventsCtaLinkable: Linkable(props.eventsCtaLinkable),
  eventsCtaUrl: props.eventsCtaUrl,
  offersTitle: props.offersTitle,
  offersText: props.offersText,
  offersCtaLabel: props.offersCtaLabel,
  offersCtaLinkable: Linkable(props.offersCtaLinkable),
  offersCtaUrl: props.offersCtaUrl,
  premiumPrice: props.premiumPrice,
  discount: props.discount,
  manifestLabel: props.manifestLabel,
  manifestLink: Linkable(props.manifestLink),
  lastOffers: props?.lastOffers && LastOffersBlock(props?.lastOffers, { pages }),
})
