import React from 'react'
import { useTranslation } from 'react-i18next'

import TopCompanies from './index'


function TopCompaniesWrapper(props) {
  const { t } = useTranslation()
  const { cards } = props

  const data = {
    ...props,
    cards: cards.map((card) => ({
      ...card,
      offersLabel: t('topCompanies_offersLabel'),
    })),
  }

  return <TopCompanies {...data} />
}


TopCompaniesWrapper.propTypes = TopCompanies.propTypes

TopCompaniesWrapper.defaultProps = TopCompanies.defaultProps

export default TopCompaniesWrapper
