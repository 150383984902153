import PropTypes from 'prop-types'

import { getCloudinaryUrl } from '../../helpers/CloudinaryHelpers'
import { getGoogleMapApiUrl, routeParamSlug } from '../../helpers/UrlHelpers'
import routes, { Router } from '../../routes'


const getEventLogo = (provider) => {
  switch (provider) {
  case 'business_place':
    return '/images/events/business_place_event.png'
  case 'cci':
    return '/images/events/cci_event.png'
  default:
    return null
  }
}

const Event = (props) => {
  if (!props) {
    return null
  }

  return {
    id: props.id,
    slug: props.slug,
    title: props.name,
    type: props.type,
    cover: props.background_image || 'defaults/event-banner',
    place: props.place,
    beginDate: props.begin_at,
    endDate: props.end_at,
    hasPage: props.has_page,
    route: routes.event.name,
    routeParams: { slug: routeParamSlug(props.full_slug) },
    href: Router.getRouteUrl(routes.event, { slug: props.full_slug }),
    link: props.link,
    logo: getEventLogo(props.provider),
    cost: props.cost,
    secondCost: props.second_cost,
    isFreeForPremium: props.is_free_for_premium,
    introduction: props.introduction,
    description: props.description,
    program: props.program,
    phone: props.phone,
    isOGCN: props.provider === 'ogcn',
    linkedEvents: props.linked_events?.map(Event) ?? [],
    ...props.location && ({
      address: {
        street: `${props.location.address}${props.location.address_2 ? `<br>${props.location.address_2}` : ''}`,
        zip: props.location.zip_code,
        city: props.location.city,
        link: getGoogleMapApiUrl(props.location.lat, props.location.long),
        image: getCloudinaryUrl(props.location.image),
      },
    }),
  }
}

Event.propTypes = PropTypes.shape({
  id: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
})

export default Event
