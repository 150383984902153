import { colors, fonts, tablet } from '../../../../../theme'


export default {
  container: {
    padding: '2rem',
    ...tablet({
      padding: '4rem 0',
    }),
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    ...tablet({
      flexDirection: (props) => (props.imgPosition === 'right' ? 'row-reverse' : 'row'),
    }),
  },
  content: {
    ...tablet({
      maxWidth: '36rem',
      margin: (props) => (props.imgPosition === 'right' ? '0 3rem 0 0' : '0 0 0 3rem'),
    }),
  },
  title: {
    '& p': {
      ...fonts.FiraSansBold,
      fontSize: '2.4rem',
      lineHeight: '120%',
      color: colors.nightRider,
      ...tablet({
        fontSize: '3.2rem',
      }),
      '& strong': {
        color: colors.darkBlue,
      },
    },
  },
  text: {
    '& p': {
      ...fonts.FiraSansRegular,
      fontSize: '1.4rem',
      lineHeight: '120%',
      color: colors.nightRider,
      ...tablet({
        fontSize: '1.6rem',
      }),
    },
  },
  img: {
  },
}
