import React, { useCallback, useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import Swiper from '../../utils/Swiper'
import TestimonyCard from '../TestimonyCard'
import { iconsKeys } from '../Icon/Icon.assets'
import Icon from '../Icon'
import { useWindowSize } from '../../utils/WindowUtils'
import { sizes } from '../../theme'
import CloudinarySound from '../CloudinarySound'
import VideoPlayerModal from '../VideoPlayerModal'

import styles from './styles'


const useStyles = createUseStyles(styles)

const TestimoniesBlock = (props) => {
  const classes = useStyles(props)
  const { className, title, testimonies } = props

  const { width } = useWindowSize()
  const [isMobile, setIsMobile] = useState(false)
  const [sound, setSound] = useState(null)
  const [video, setVideo] = useState(null)
  const [currentMediaIndex, setCurrentMediaIndex] = useState(null)

  useEffect(() => {
    setIsMobile(width < sizes.tablet)
  }, [width])


  useEffect(() => {
    const swiper = new Swiper(`.${classes.swiperContainer}`, {
      wrapperClass: classes.swiperWrapper,
      slideClass: classes.swiperSlide,
      grabCursor: true,
      loop: true,
      speed: 400,
      autoplay: {
        delay: 50000,
      },
      slidesPerView: isMobile ? 1 : 3,
      slidesPerGroup: 1,
      navigation: {
        nextEl: `.${classes.swiperNext}`,
        prevEl: `.${classes.swiperPrev}`,
      },
    })

    return () => {
      if (swiper && swiper.destroy) {
        swiper.destroy(true, true)
      }
    }
  }, [classes, isMobile])

  const resetMedia = () => {
    setVideo(null)
    setSound(null)
    setCurrentMediaIndex(null)
  }

  const handleMedia = useCallback((i) => {
    resetMedia()
    if (i !== currentMediaIndex) {
      const currentTestimony = testimonies[i]

      if (currentTestimony.mediaType === 'youtube') {
        setVideo(currentTestimony.media)
      } else {
        setSound(currentTestimony.media)
      }
      setCurrentMediaIndex(i)
    }
  }, [currentMediaIndex, testimonies])

  const renderCard = useCallback((testimony, i) => (
    <>
      <TestimonyCard
        {...testimony}
        currentIndex={i}
        handleMedia={handleMedia}
        isMediaPlaying={i === currentMediaIndex}
      />
    </>
  ), [currentMediaIndex, handleMedia])

  const renderCards = useMemo(() => testimonies.map((card, i) => (
    <div
      className={classes.swiperSlide}
      key={`testimony-${i}`}
      id={`testimonyCard-${i}`}
    >
      {renderCard(card, i)}
    </div>
  )), [classes.swiperSlide, renderCard, testimonies])

  if (!testimonies || !testimonies.length >= 1) {
    return null
  }

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        <h3 className={classes.title}>{title}</h3>
        <div className={classes.testimonies}>
          <Icon
            icon={iconsKeys.Chevron}
            className={classes.swiperPrev}
          />
          <div className={classes.swiperContainer}>
            <div className={classes.swiperWrapper}>
              {renderCards}
            </div>
          </div>
          <Icon
            icon={iconsKeys.Chevron}
            className={classes.swiperNext}
          />
        </div>
      </div>
      {sound && (
        <CloudinarySound
          id={sound}
          className={classes.sound}
          isPlaying={sound !== null}
        />
      )}
      {video && (
        <VideoPlayerModal
          videoProps={{ video }}
          isOpen={video !== null}
          onClose={resetMedia}
        />
      )}
    </div>
  )
}

TestimoniesBlock.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  testimonies: PropTypes.arrayOf(PropTypes.shape(TestimonyCard.PropTypes)),
}

TestimoniesBlock.defaultProps = {
  className: '',
  title: '',
  testimonies: [],
}

export default withMemo()(TestimoniesBlock)
