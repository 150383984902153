import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import { colors } from '../../theme'
import ActionButton from '../ActionButton'
import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'
import MarkdownText from '../MarkdownText'

import styles from './styles'


const useStyles = createUseStyles(styles)

const SubscriptionPlanTable = (props) => {
  const classes = useStyles(props)
  // eslint-disable-next-line max-len
  const { className, title, subtitle, typeTitle, typeFreemium, typePremium, ctaLabel, advantages, onTypeClick, pushMeeting } = props

  const handleTypeClick = (t) => {
    onTypeClick(t)
  }

  return (
    <div
      className={cx(classes.container, className, 'onBoardingTable')}
      id="subscriptionTable"
    >
      <div>
        <div className={classes.wrapper}>
          <div className={classes.title}>
            {title}
          </div>
          <div className={classes.subtitle}>
            {subtitle}
          </div>
          <div className={classes.divider} />
          <div className={classes.tableHeader}>
            <MarkdownText
              text={typeTitle}
              className={classes.typeTitle}
            />
            <div className={classes.typeSelector}>
              <div className={classes.typeSelectorItem}>
                <div className={classes.typeSelectorLabel}>
                  {typeFreemium}
                </div>
                <ActionButton
                  className={classes.cta}
                  onClick={() => handleTypeClick(typeFreemium)}
                  label={ctaLabel}
                  isOutlined
                />
              </div>
              <div className={classes.typeSelectorItem}>
                <div className={cx(classes.typeSelectorLabel, classes.premiumLabel)}>
                  {typePremium}
                </div>
                <ActionButton
                  className={classes.cta}
                  onClick={() => handleTypeClick(typePremium)}
                  label={ctaLabel}
                  isOutlined
                />
              </div>
            </div>
          </div>
          <div className={classes.table}>
            {advantages.map((advantage, index) => (
              <div
                className={classes.tablePart}
                key={index}
              >
                <div className={classes.tableTitle}>{advantage.title}</div>
                <div className={classes.tableRows}>
                  {advantage.rows.map((row, index2) => (
                    <React.Fragment key={index2}>
                      <div className={classes.divider} />
                      <div
                        className={classes.tableRow}
                      >
                        <div className={classes.tableRowLabel}>{row.label}</div>
                        <div className={classes.tableRowIcons}>
                          {row.hasFreemium && (
                            <Icon
                              className={classes.icon}
                              icon={iconsKeys.Check}
                              color={colors.darkBlue}
                            />
                          )}
                          {row.hasPremium && (
                            <Icon
                              className={classes.icon}
                              icon={iconsKeys.Check}
                              color={colors.darkBlue}
                            />
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className={classes.typeBottomSelector}>
            <div className={classes.typeSelectorItem}>
              <ActionButton
                className={classes.cta}
                onClick={() => handleTypeClick(typeFreemium)}
                label={ctaLabel}
                isOutlined
              />
            </div>
            <div className={classes.typeSelectorItem}>
              <ActionButton
                className={classes.cta}
                onClick={() => handleTypeClick(typePremium)}
                label={ctaLabel}
                isOutlined
              />
            </div>
          </div>
        </div>
        <div className={classes.pushMeeting}>
          <div className={classes.pushMeetingTitle}>
            {pushMeeting.title}
          </div>
          <a href={pushMeeting.link}>
            <ActionButton
              className={classes.pushMeetingCta}
              label={pushMeeting.ctaLabel}
            />
          </a>
        </div>
      </div>
    </div>
  )
}

SubscriptionPlanTable.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  typeTitle: PropTypes.string,
  typeFreemium: PropTypes.string,
  typePremium: PropTypes.string,
  ctaLabel: PropTypes.string,
  advantages: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    rows: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      hasPremium: PropTypes.bool,
      hasFreemium: PropTypes.bool,
    })),
  })),
  onTypeClick: PropTypes.func,
  pushMeeting: PropTypes.shape({
    title: PropTypes.string,
    ctaLabel: PropTypes.string,
    link: PropTypes.string,
  }),
}

SubscriptionPlanTable.defaultProps = {
  className: '',
  title: '',
  subtitle: '',
  typeTitle: '',
  typeFreemium: '',
  typePremium: '',
  ctaLabel: '',
  advantages: [],
  onTypeClick: () => null,
  pushMeeting: {
    title: '',
    ctaLabel: '',
    link: '',
  },
}

export default withMemo()(SubscriptionPlanTable)
