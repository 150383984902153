import React from 'react'
import { useSelector } from 'react-redux'

import { selectors as AuthSelectors } from '../../redux/auth/redux'

import OfferPublic from './index'


function OfferPublicWrapper(props) {
  const isConnected = useSelector(AuthSelectors.isConnected)
  const isPremium = useSelector(AuthSelectors.isActivePremium)

  const data = {
    ...props,
    isConnected,
  }

  if (isPremium) {
    return null
  }

  return <OfferPublic {...data} />
}


OfferPublicWrapper.propTypes = OfferPublic.propTypes

OfferPublicWrapper.defaultProps = OfferPublic.defaultProps

export default OfferPublicWrapper
