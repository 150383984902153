import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import CloudinaryImage from '../CloudinaryImage'
import ActionButton from '../ActionButton'
import { iconsKeys } from '../Icon/Icon.assets'
import { colors } from '../../theme'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const PushContent = (props) => {
  const classes = useStyles(props)
  const { className, backgroundImage, headerTitle, title, description, ctaLabel, ctaLink } = props

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        <h3 className={classes.headerTitle}>{headerTitle}</h3>
        <div className={classes.box}>
          <CloudinaryImage
            className={classes.backgroundImage}
            id={backgroundImage}
            lazyLoad
            options={{
              width: '960',
              height: '260',
              crop: 'fill',
            }}
          />
          <div className={classes.content}>
            <h4 className={classes.contentTitle}>{title}</h4>
            <p className={classes.contentDescription}>{description}</p>
            <ActionButton
              className={classes.contentCta}
              label={ctaLabel}
              route={ctaLink}
              isOutlined
              color="secondary"
              icon={iconsKeys.ArrowRight}
              colorIcon={colors.darkBlue}
              colorIconHover={colors.pureWhite}
              iconRight
            />
          </div>
        </div>
      </div>
    </div>
  )
}

PushContent.propTypes = {
  className: PropTypes.string,
  backgroundImage: PropTypes.string,
  headerTitle: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaLink: PropTypes.string,

}

PushContent.defaultProps = {
  className: null,
  backgroundImage: '',
  headerTitle: '',
  title: '',
  description: '',
  ctaLabel: '',
  ctaLink: '',
}

export default withMemo()(PushContent)
