import { colors, desktop, fonts, sizes, tablet } from '../../theme'


export default {
  container: {
    backgroundColor: colors.nightRider,
  },
  header: {
    marginTop: ({ isConnected }) => (isConnected ? '0rem' : '-3rem'),
    paddingTop: ({ isConnected }) => (isConnected ? '18rem' : '0rem'),
    ...tablet({
      marginTop: '2rem',
      paddingTop: ({ isConnected }) => (isConnected ? '10rem' : '0rem'),
    }),
    ...desktop({
      marginTop: 0,
    }),
  },
  title: {
    ...fonts.FiraSansBold,
    color: colors.pureWhite,
    fontSize: '2.4rem',
    lineHeight: '120%',
    textTransform: 'uppercase',
    padding: '0 2rem 3rem',
    textAlign: 'center',
  },
  filters: {
    margin: '0 1rem',
    display: 'flex',
    zIndex: 3,
    marginBottom: '3rem',
    flexDirection: 'row',
  },
  filterBlock: {
    //
  },
  content: {
    backgroundColor: colors.honeydew,
  },
  wrapper: {
    width: '100%',
    maxWidth: `${sizes.desktop / 10}rem`,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    padding: '3rem 0',
  },
  results: {
    display: 'flex',
    flexDirection: 'column',
    ...tablet({
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'stretch',
      justifyContent: 'center',
      marginLeft: '-1rem',
      marginRight: '-1rem',
    }),
  },
  empty: {
    ...fonts.FiraSansRegular,
    textAlign: 'center',
    padding: '2rem',
  },
  itemContainer: {
    margin: '2rem',
    marginTop: 0,
    ...tablet({
      width: 'calc(50% - 2rem)',
      marginLeft: '1rem',
      marginRight: '1rem',
      marginBottom: '2rem',
    }),
  },
  hidden: {
    display: 'flex',
  },
}
