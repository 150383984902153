export default {
  container: {},
  background: {},
  wrapper: {},
  content: {},
  video: {
    display: 'flex',
    width: '100%',
    backgroundColor: '#000',
  },
  close: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    left: 'auto',
    cursor: 'pointer',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '3.9rem',
  },
}
