import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'


export const key = 'page'


//
// Initial state
//
const initialState = {
  slug: null,
  data: null,
  loaded: false,
}

//
// Actions
//
export const actions = {
  setSlug: createAction('Page: setSlug', (slug) => ({ slug })),
  setData: createAction('Page: setData', (data) => ({ data })),
  setLoaded: createAction('Page: setLoaded', (loaded) => ({ loaded })),
}

//
// Reducer
//
export const reducer = createReducer({
  [actions.setSlug]: (state, { slug }) => Immutable.static.merge(state, { slug }),
  [actions.setData]: (state, { data }) => Immutable.static.merge(state, { data }),
  [actions.setLoaded]: (state, { loaded }) => Immutable.static.merge(state, { loaded }),
}, initialState)

//
// Selectors
//

const root = (state) => state[key]
const isLoaded = (state) => root(state).loaded
const slug = (state) => root(state).slug
const data = (state) => root(state).data

export const selectors = {
  isLoaded,
  slug,
  data,
}
