import React from 'react'

import withMemo from '../../decorators/WithMemo'

import TitleTextCTAPush from './index'


function TitleTextCTAPushWrapper(props) {
  const routeParams = {}

  const data = {
    ...props,
    ctaLink: props.ctaLink,
    routeParams: {
      ...props.routeParams,
      ...routeParams,
    },
  }

  return <TitleTextCTAPush {...data} />
}

TitleTextCTAPushWrapper.propTypes = TitleTextCTAPush.propTypes

TitleTextCTAPushWrapper.defaultProps = TitleTextCTAPush.defaultProps

export default withMemo()(TitleTextCTAPushWrapper)
