/* eslint-disable max-lines */
import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import EventCard from '../EventCard'
import FilterBox from '../FilterBox'
import MarkdownText from '../MarkdownText'
import Pagination from '../Pagination'
import SortBox from '../SortBox'

import styles from './styles'


const useStyles = createUseStyles(styles)

const EventsResults = (props) => {
  const {
    className,
    title,
    text,
    sortBoxProps,
    filterBoxProps,
    events,
    emptyEventsText,
    pagination,
    handleSubmit,
    pushPremium,
    isCurrentOGCN,
  } = props

  const [sortValue, setSortValue] = useState(sortBoxProps.value || '')
  const [isSortOpen, setSortOpen] = useState(false)
  const [filterValues, setFilterValues] = useState(filterBoxProps.values || [])
  const [isFilterOpen, setFilterOpen] = useState(false)
  const [timerId, setTimerId] = useState(null)
  const [needsUpdate, setNeedsUpdate] = useState(false)
  const classes = useStyles(props)

  const handleFilterChange = useCallback((name, value) => {
    setFilterValues((before) => [
      ...before.filter((item) => item.name !== name),
      {
        name,
        value,
      },
    ])
    setNeedsUpdate(true)
  }, [])

  const handleSortChange = useCallback(({ value }) => {
    setSortValue(value)
    setSortOpen(false)
    setNeedsUpdate(true)
  }, [])

  const doSearch = useCallback(() => {
    if (timerId) {
      clearTimeout(timerId)
    }
    setTimerId(setTimeout(() => {
      handleSubmit({
        sortValue,
        filterValues,
      })
    }, 500))
  }, [handleSubmit, sortValue, filterValues, timerId])

  const handleToggleSorting = () => {
    if (!isSortOpen) {
      setFilterOpen(false)
    }
    setSortOpen(!isSortOpen)
  }

  const handleToggleFiltering = () => {
    if (!isFilterOpen) {
      setSortOpen(false)
    }
    setFilterOpen(!isFilterOpen)
  }

  const renderEvents = () => events.map((event, i) => (
    <EventCard
      key={i}
      {...event}
      asCard
      className={classes.eventsListItemContainer}
      isCurrentOGCN={isCurrentOGCN}
    />
  ))

  const renderEmptyEvents = () => (
    <p
      className={classes.emptyEvents}
      dangerouslySetInnerHTML={{ __html: emptyEventsText }}
    />
  )

  const sortContainerRef = React.createRef()
  const sortUlRef = React.createRef()
  const filterContainerRef = React.createRef()
  const filterSubmenuRef = React.createRef()

  const hasParent = (target, parent, currentRef) => {
    if (!target) {
      return false
    }
    if (target === parent || target.parentElement === currentRef) {
      return true
    }
    return hasParent(target.parentElement, parent, currentRef)
  }

  const handleWindowClick = (e) => {
    if (isSortOpen) {
      if (!hasParent(e.target, sortContainerRef.current, sortUlRef.current)) {
        setSortOpen(false)
      }
    }
    if (isFilterOpen) {
      if (!hasParent(e.target, filterContainerRef.current, filterSubmenuRef.current)) {
        setFilterOpen(false)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleWindowClick)

    return () => {
      window.removeEventListener('click', handleWindowClick)
    }
  })

  useEffect(() => {
    if (needsUpdate) {
      setNeedsUpdate(false)
      doSearch()
    }
  }, [needsUpdate, doSearch])

  return (
    <div className={cx(classes.container, className)}>
      <FilterBox
        {...filterBoxProps}
        className={classes.filterBlock}
        isOpen={isFilterOpen}
        toggleSelect={() => handleToggleFiltering()}
        values={filterValues}
        onChange={({ name, value }) => handleFilterChange(name, value)}
        containerRef={filterContainerRef}
        submenuRef={filterSubmenuRef}
      />
      {pushPremium}
      <div className={classes.content}>

        {title !== null && (
          <MarkdownText
            text={title}
            className={classes.title}
          />
        )}
        {text !== null && (
          <MarkdownText
            text={text}
            className={classes.text}
          />
        )}

        <div className={classes.filters}>
          <SortBox
            {...sortBoxProps}
            className={classes.filterBlock}
            value={sortValue}
            isOpen={isSortOpen}
            onChange={handleSortChange}
            toggleSelect={() => handleToggleSorting()}
            containerRef={sortContainerRef}
            ulRef={sortUlRef}
          />
        </div>

        <div className={classes.results}>
          {events.length >= 1 && (
            <div className={classes.eventsList}>
              {renderEvents()}
            </div>
          )}
          {events.length === 0 && emptyEventsText !== null && renderEmptyEvents()}
        </div>

        {events.length >= 1 && (
          <Pagination {...pagination} />
        )}

      </div>
    </div>
  )
}

EventsResults.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  sortBoxProps: PropTypes.shape(SortBox.propTypes),
  filterBoxProps: PropTypes.shape(FilterBox.propTypes),
  events: PropTypes.arrayOf(PropTypes.shape(EventCard.propTypes)),
  emptyEventsText: PropTypes.string,
  pagination: PropTypes.shape(Pagination.propTypes),
  handleSubmit: PropTypes.func,
  order: PropTypes.string,
  pushPremium: PropTypes.any,
  isCurrentOGCN: PropTypes.bool,
}

EventsResults.defaultProps = {
  className: '',
  title: null,
  text: null,
  sortBoxProps: null,
  filterBoxProps: null,
  events: [],
  emptyEventsText: null,
  pagination: null,
  handleSubmit: () => undefined,
  order: '',
  pushPremium: null,
  isCurrentOGCN: false,
}

export default withMemo()(EventsResults)
