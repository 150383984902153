import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import ArticleCard from '../ArticleCard'
import MarkdownText from '../MarkdownText'
import ActionButton from '../ActionButton'

import styles from './styles'


const useStyles = createUseStyles(styles)

const ArticleBlock = (props) => {
  const classes = useStyles(props)
  const { className, title, articles, ctaLabel, ctaLink } = props

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        <MarkdownText
          className={classes.title}
          text={title}
          tag="h3"
        />
        <div className={classes.box}>
          <div className={classes.articles}>
            {articles.map((article, i) => (
              <ArticleCard
                key={i}
                className={classes.article}
                {...article}
              />
            ))}
          </div>
        </div>
        <ActionButton
          className={classes.cta}
          label={ctaLabel}
          link={ctaLink}
        />
      </div>
    </div>
  )
}

ArticleBlock.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  articles: PropTypes.arrayOf(PropTypes.shape(ArticleCard.PropTypes)),
  ctaLabel: PropTypes.string,
  ctaLink: PropTypes.string,
}

ArticleBlock.defaultProps = {
  className: '',
  title: '',
  articles: [],
  ctaLabel: '',
  ctaLink: '',
}

export default withMemo()(ArticleBlock)
