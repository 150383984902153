import { colors, fonts, sizes, tablet } from '../../theme'


export default {
  container: {
    background: colors.honeydew,
  },
  wrapper: {
    maxWidth: `${sizes.desktop / 10}rem`,
    margin: '0 auto',
    textAlign: 'center',
    padding: '2rem',
    ...tablet({
      padding: '2rem 0',
    }),
  },
  headerTitle: {
    ...fonts.FiraSansBold,
    fontSize: '2.4rem',
    color: colors.nightRider,
    margin: '2rem 0',
    ...tablet({
      fontSize: '3rem',
    }),
  },
  box: {
    position: 'relative',
  },
  backgroundImage: {
    '& img': {
      borderTopLeftRadius: '2rem',
      borderTopRightRadius: '2rem',
      ...tablet({
        borderRadius: '2rem',
      }),
    },
  },
  content: {
    textAlign: 'left',
    background: colors.pureWhite,
    borderBottomLeftRadius: '2rem',
    borderBottomRightRadius: '2rem',
    padding: '1.5rem',
    ...tablet({
      position: 'absolute',
      bottom: '2rem',
      left: '2rem',
      opacity: 0.95,
      borderRadius: '2rem',
    }),
  },
  contentTitle: {
    ...fonts.FiraSansBold,
    fontSize: '1.8rem',
    color: colors.darkBlue,
    margin: '0 0 1rem 0',
    ...tablet({
      fontSize: '2rem',
      maxWidth: '43rem',
    }),
  },
  contentDescription: {
    ...fonts.FiraSansRegular,
    fontSize: '1.5rem',
    color: colors.nightRider,
    margin: '0 0 2rem 0',
    ...tablet({
      fontSize: '1.4rem',
    }),
  },
  contentCta: {
    '&:hover': {
      border: `1px solid ${colors.darkBlue}`,
    },
  },
}
