import routes from '../../routes'


export default (props, { pages }) => ({
  __typename: 'RegisterPushCombo',
  ...props.cta_title && props.cta_title !== '' && {
    ctaLink: '', // pages[pageType.ON_BOARDING].link,
    ctaLabel: props.cta_title,
    ctaRoute: routes.page.name,
    ctaRouteParams: { slug: '' /* pages[pageType.ON_BOARDING].link */ },
  },
  ...props.profiles && props.profiles.length >= 1 && {
    cards: props.profiles.map((arg) => ({
      title: arg.title,
      image: arg.logo,
      text: arg.text,
    })),
  },
})
