export default (props) => ({
  __typename: 'TestimonyContainerBlock',
  ...props.title && {
    title: props.title,
  },
  ...props.testimonies && props.testimonies.length >= 1 && {
    testimonies: props.testimonies.map((arg) => ({
      avatar: arg.image,
      name: arg.name,
      text: arg.quote,
      role: arg.job,
      mediaType: arg.media_type,
      media: arg.media,
    })),
  },
})
