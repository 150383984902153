import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'


export const key = 'linkedin'

//
// Initial state
//
const initialState = {
  data: null,
}

//
// Actions
//
export const actions = {
  setData: createAction('Linkedin: setData', (data) => data),
}

//
// Reducer
//
export const reducer = createReducer({
  [actions.setData]: (state, data) => Immutable.merge(state, { data }),
}, initialState)

//
// Selectors
//
const root = (state) => state[key]
const data = (state) => root(state).data

export const selectors = {
  data,
}
