export default (props, { pages }) => ({
  __typename: 'ArgumentsBlock',
  ...props.cta_link && {
    ctaLink: props.cta_link,
  },
  ...props.cta_title && {
    ctaLabel: props.cta_title,
  },
  ...props.arguments && {
    argumentsList: props.arguments.map((arg) => ({
      __typename: 'Argument',
      ...arg.title && {
        title: arg.title,
      },
      ...arg.text && {
        text: arg.text,
      },
      ...arg.image && {
        img: arg.image,
      },
      ...arg.image_position && {
        imgPosition: arg.image_position,
      },
    })),
  },
})
