import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import withMemo from '../../decorators/WithMemo'
import { selectors as authSelectors } from '../../redux/auth/redux'
import { app } from '../../config'

import PushPremium from './index'


function PushPremiumWrapper({ isCard, title, text, ctaLabel }) {
  const { t } = useTranslation()
  const isPremium = useSelector(authSelectors.isActivePremium)


  const registerPushProps = {
    title: title || t('pushPremium_title'),
    text: text || t('pushPremium_text'),
    ctaLabel: ctaLabel || t('pushPremium_cta'),
    ctaRoute: app.REGISTER_URL,
    isCard,
  }

  if (!isPremium) {
    return null
  }

  return (
    <PushPremium
      {...registerPushProps}
    />
  )
}

PushPremiumWrapper.propTypes = {
  isCandidate: PropTypes.bool,
  isCard: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  ctaLabel: PropTypes.string,
}

PushPremiumWrapper.defaultProps = {
  isCandidate: false,
  isCard: false,
  title: '',
  text: '',
  ctaLabel: '',
}

export default withMemo()(PushPremiumWrapper)
