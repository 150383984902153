import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import MarkdownText from '../../../MarkdownText'
import withMemo from '../../../../decorators/WithMemo'
import SubscriptionPlanTable from '../../../SubscriptionPlanTable'
import { ActionButton } from '../../../index'
import Linkable from '../../../../data/models/Linkable'

import styles from './styles'


const useStyles = createUseStyles(styles)

const SubscriptionPlan = (props) => {
  const classes = useStyles(props)
  const {
    className,
    title,
    text,
    ctaLabel,
    ctaLinkable,
    ctaUrl,
    price,
    discount,
  } = props

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        <MarkdownText
          tag="h2"
          className={classes.title}
          text={title}
          inline
        />
        <MarkdownText
          className={classes.text}
          text={text}
          inline
        />
        <SubscriptionPlanTable
          price={price}
          discount={discount}
          className={classes.table}
        />
        <div className={classes.buttons}>
          <ActionButton
            className={classes.cta}
            label={ctaLabel}
            route={ctaLinkable?.route?.name}
            routeParams={ctaLinkable?.route?.params}
            link={ctaUrl}
          />
        </div>
      </div>
    </div>
  )
}

SubscriptionPlan.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  price: PropTypes.string,
  discount: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaUrl: PropTypes.string,
  ctaLinkable: Linkable.propTypes,
}

SubscriptionPlan.defaultProps = {
  className: '',
  title: '',
  price: '',
  discount: '',
  text: '',
  ctaLabel: '',
  ctaUrl: '',
  ctaLinkable: null,
}

export default withMemo()(SubscriptionPlan)
