import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { useTranslation } from 'react-i18next'

import OfferCard from '../OfferCard'
import withMemo from '../../decorators/WithMemo'
import OfferSmallCard from '../OfferSmallCard'
import { ActionButton, MarkdownText } from '../index'

import styles from './styles'


const useStyles = createUseStyles(styles)

const OfferLastOffers = (props) => {
  const classes = useStyles(props)
  const { className, title, text, cards, route } = props
  const { t } = useTranslation()

  const renderCards = () => cards.map((card, index) => (
    <OfferSmallCard
      {...card}
      key={`offerSimilarOffers-${index}`}
      className={classes.card}
    />
  ))

  return !!cards && cards.length > 0
    && (
    <div className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        <MarkdownText
          tag="h2"
          className={classes.title}
          text={title}
          inline
        />
        <MarkdownText
          className={classes.text}
          text={text}
          inline
        />
        <div className={classes.cards}>
          {renderCards()}
        </div>
        <ActionButton
          route={route?.name}
          routeParams={route?.params}
          label={t('lastOffers_cta_label')}
        />
      </div>
    </div>
    )
}

OfferLastOffers.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  cards: PropTypes.arrayOf(PropTypes.shape(OfferCard.propTypes)),
  route: PropTypes.shape({
    name: PropTypes.string,
    params: PropTypes.object,
  }),
}

OfferLastOffers.defaultProps = {
  className: '',
  title: '',
  text: '',
  cards: [],
  route: null,
}

export default withMemo()(OfferLastOffers)
