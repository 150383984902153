/* eslint-disable max-lines */
import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { Form, Formik } from 'formik'

import ActionButton from '../ActionButton'
import { validateAll } from '../../helpers/ValidationHelpers'
import { compileInitialValues, compileRules, formFields, defineField } from '../../helpers/FormHelpers'
import { Field } from '../form/Generator/Field'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const fields = [
  defineField({ name: 'email', validation: 'required|email', component: formFields.email }),
  defineField({ name: 'password', validation: 'required|password', component: formFields.Password }),
]
const rules = compileRules(fields)
const useStyles = createUseStyles(styles)

function LoginResetForm(props) {
  const {
    className,
    label,
    initialValues,
    fieldsProps,
    onSubmit,
    messages,
    submitErrors,
  } = props

  const classes = useStyles(props)

  //
  // Handlers
  //
  const handleValidate = (values) => validateAll(values, rules, messages)
  const handleSubmit = (values) => onSubmit(values)

  //
  // Renderers
  //
  const renderForm = (formikProps) => (
    <Form
      noValidate
      autoComplete="off"
      onSubmit={formikProps.handleSubmit}
    >
      <div className={classes.fields}>
        {
          fields
            .map((field, i) => (
              <div
                className={classes.field}
                key={i}
              >
                <Field
                  fieldConfig={field}
                  fieldsProps={fieldsProps}
                  formikProps={formikProps}
                />
              </div>
            ))
        }
      </div>
      {submitErrors && (
        <div className={classes.errors}>
          {submitErrors.map((error, i) => (
            <div
              className={classes.error}
              key={i}
            >
              {error.label}
            </div>
          ))}
        </div>
      )}

      <div className={classes.submitContainer}>
        <button type="submit">
          <ActionButton
            color="primary"
            label={label}
            className={classes.button}
          />
        </button>
      </div>
    </Form>
  )

  return (
    <div className={cx(classes.container, className)}>
      <Formik
        validate={handleValidate}
        onSubmit={handleSubmit}
        initialValues={compileInitialValues(fields, initialValues)}
      >
        {renderForm}
      </Formik>
    </div>
  )
}

LoginResetForm.styles = styles

LoginResetForm.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  fieldsProps: PropTypes.objectOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    help: PropTypes.string,
    placeholder: PropTypes.string,
  })),
  initialValues: PropTypes.objectOf(PropTypes.string),
  messages: PropTypes.objectOf(PropTypes.string),
  onSubmit: PropTypes.func,
  submitErrors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    field: PropTypes.string,
  })),
}

LoginResetForm.defaultProps = {
  className: '',
  label: '',
  fieldsProps: {},
  initialValues: {},
  messages: {},
  onSubmit: () => null,
  submitErrors: null,
}

export default withMemo()(LoginResetForm)
