export default (props) => ({
  __typename: 'PromiseContainer',
  ...props.text && {
    text: props.text,
  },
  ...props.services && props.services.length >= 1 && {
    promises: props.services.map((arg) => ({
      title: arg.title,
      image: arg.logo,
    })),
  },
})
