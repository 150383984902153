import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../../../decorators/WithMemo'
import { colors } from '../../../../theme'
import ActionButton from '../../../ActionButton'
import CloudinaryImage from '../../../CloudinaryImage'
import { imageTypes } from '../../../CloudinaryImage/types'
import { iconsKeys } from '../../../Icon/Icon.assets'
import MarkdownText from '../../../MarkdownText'
import types from '../../types'

import styles from './styles'


const useStyles = createUseStyles(styles)

function OnBoardingConfirm(props) {
  const {
    className,
    headerTitle,
    background,
    title,
    freemiumText,
    premiumText,
    type,
    buttonLabel,
    buttonTitle,
    buttonLink,
  } = props

  const classes = useStyles(props)

  return (
    <div className={cx(classes.container, className)}>
      <CloudinaryImage
        id={background}
        type={imageTypes.Background}
        className={classes.headerContainer}
      >
        <div className={classes.overlay} />
        <div className={classes.headerWrapper}>
          <div className={classes.headerTitle}>
            <h1 className={classes.headerTitleText}>
              {headerTitle}
            </h1>
          </div>
        </div>
      </CloudinaryImage>
      <div className={cx(classes.wrapper)}>
        <div className={classes.title}>
          <MarkdownText
            text={title}
            className={classes.titleText}
            tag="h2"
            inline
          />
        </div>
        <MarkdownText
          text={type === types.FREEMIUM ? freemiumText : premiumText}
          className={classes.text}
        />
        <div className={classes.nextContainer}>
          <ActionButton
            color="primary"
            isOutlined
            icon={iconsKeys.SmallArrowLeft}
            colorIcon={colors.primary}
            colorIconHover={colors.pureWhite}
            label={buttonLabel}
            link={buttonLink}
            title={buttonTitle}
            className={classes.button}
          />
        </div>
      </div>
    </div>
  )
}

OnBoardingConfirm.styles = styles

OnBoardingConfirm.propTypes = {
  className: PropTypes.string,
  headerTitle: PropTypes.string,
  background: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonTitle: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  freemiumText: PropTypes.string,
  premiumText: PropTypes.string,
}

OnBoardingConfirm.defaultProps = {
  className: '',
  headerTitle: '',
  background: '',
  buttonLabel: '',
  buttonLink: '',
  buttonTitle: '',
  type: '',
  title: '',
  freemiumText: '',
  premiumText: '',
}

export default withMemo()(OnBoardingConfirm)
