import React from 'react'
import { useSelector } from 'react-redux'

import { selectors as AuthSelectors } from '../../redux/auth/redux'

import PostPublic from './index'


function PostPublicWrapper(props) {
  const isPremium = useSelector(AuthSelectors.isActivePremium)
  const isConnected = useSelector(AuthSelectors.isConnected)

  const data = {
    ...props,
    isConnected,
  }

  if (isPremium) {
    return null
  }

  return <PostPublic {...data} />
}


PostPublicWrapper.propTypes = PostPublic.propTypes

PostPublicWrapper.defaultProps = PostPublic.defaultProps

export default PostPublicWrapper
