import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { getFieldTranslations } from '../../helpers/FormHelpers'
import { selectors as PageSelectors } from '../../pages/Page/redux'
import { selectors as AppSelectors } from '../../redux/app/redux'
import { actions as AuthActions, selectors as AuthSelectors } from '../../redux/auth/redux'
import LoginLayout from '../LoginLayout/LoginLayout'
import MarkdownText from '../MarkdownText'

import ManagerRegisterForm from './index'


function ManagerRegisterWrapper(props) {
  const { t } = useTranslation()

  const registerSelector = useSelector(AuthSelectors.manager)
  const submitErrors = registerSelector?.errors

  const route = useSelector(AppSelectors.route)
  const { email, first_name, last_name } = route?.query

  const title = useSelector(PageSelectors.data)?.title

  const dispatch = useDispatch()

  const handleSubmit = (values) => dispatch(AuthActions.manager(values))

  const loginProps = {
    label: t('manager_register_submit_label'),
    disclaimerLabel: t('manager_register_submit_disclaimer'),
    fieldsProps: {
      companyName: getFieldTranslations(t, 'manager_register_company_name'),
      companyNumber: getFieldTranslations(t, 'manager_register_company_number'),
      firstName: getFieldTranslations(t, 'manager_register_first_name'),
      lastName: getFieldTranslations(t, 'manager_register_last_name'),
      role: getFieldTranslations(t, 'manager_register_role'),
      email: getFieldTranslations(t, 'manager_register_email'),
      password: getFieldTranslations(t, 'manager_register_password'),
      password_confirmation: getFieldTranslations(t, 'manager_register_password_confirmation'),
      phone: getFieldTranslations(t, 'manager_register_phone'),
      cgu: {
        label: <MarkdownText
          text={t('manager_register_conditions_label')}
          inline
        />,
        help: t('manager_register_conditions_help'),
      },
      optin: getFieldTranslations(t, 'manager_register_optin', ['label', 'help']),
    },
    messages: {
      required: t('form_validation_required'),
      checked: t('form_validation_required'),
      email: t('form_validation_email'),
      password: t('form_validation_password'),
      phone: t('form_validation_phone'),
    },
    initialValues: {
      email,
      firstName: first_name,
      lastName: last_name,
    },
  }

  return (
    <LoginLayout
      title={title}
      mainContent={(
        <ManagerRegisterForm
          {...loginProps}
          onSubmit={handleSubmit}
          submitErrors={submitErrors}
        />
      )}
    />

  )
}

ManagerRegisterWrapper.propTypes = {}

ManagerRegisterWrapper.defaultProps = {}

export default ManagerRegisterWrapper
