import routes, { Router } from '../../routes'
import { routeParamSlug } from '../../helpers/UrlHelpers'

import Domain from './Domain'


const CompanyCardModel = (props) => (props && {
  id: props.id,
  name: props.name,
  domain: Domain(props?.domain),
  city: props.city,
  logo: props.logo,
  ...props.pictures && props.pictures.length >= 1 && {
    cover: props.pictures.sort((a, b) => ((a.order > b.order) ? 1 : -1)).reduce((acc, obj) => {
      if (obj.is_main) {
        return obj.image
      }
      return acc
    }, ''),
  },
  link: Router.getRouteUrl(routes.company, { slug: props.full_slug }),
  route: routes.company.name,
  routeParams: { slug: routeParamSlug(props.full_slug) },
  isPremium: props.is_premium,
  hasCertifications: props.hasCertifications,
  isAmbassador: props.is_ambassador,
  is_ogcn: props.is_ogcn,
})

export default CompanyCardModel
