/* eslint-disable max-lines */
import cx from 'classnames'
import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import { compileInitialValues, compileRules, defineField, formFields } from '../../helpers/FormHelpers'
import { locationType } from '../../graphql/enums/locationType'
import { validateAll } from '../../helpers/ValidationHelpers'
import { colors } from '../../theme'
import { parseSiret } from '../../utils/FormUtils'
import ActionButton from '../ActionButton'
import { Field } from '../form/Generator/Field'
import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'

import styles from './styles'


const useStyles = createUseStyles(styles)

function RegisterForm(props) {
  const {
    id,
    className,
    label,
    initialValues,
    fieldsProps,
    onSubmit,
    type,
    disclaimerLabel,
    messages,
    isManager,
    errors: backendErrors,
  } = props

  const classes = useStyles(props)

  const emailProps = isManager && {
    endOrnament: <Icon
      icon={iconsKeys.Lock}
      color={colors.darkBlue}
    />,
    readOnly: true,
  }

  const [currentLocation, setCurrentLocation] = useState(locationType.FRANCE)

  const fields = [
    !isManager && defineField({ name: 'companyName', validation: 'required' }),
    !isManager && defineField({ name: 'location',
      validation: ['required'],
      component: formFields.Select,
      props: {
        inputProps: {
          onChange: (selectedOption) => {
            setCurrentLocation(selectedOption.value)
          },
        },
      } }),
    !isManager && currentLocation === locationType.FRANCE && defineField({ name: 'companyNumber', validation: ['required', 'siret'], mask: parseSiret }),
    !isManager && currentLocation === locationType.MONACO && defineField({ name: 'companyMonacoNumber', validation: ['required'] }),
    !isManager && currentLocation === locationType.LUXEMBOURG && defineField({ name: 'companyTvaEuropeNumber', validation: ['required'] }),
    !isManager && defineField({ name: 'firstName', validation: 'required' }),
    !isManager && defineField({ name: 'lastName', validation: 'required' }),
    // defineField({ name: 'role', validation: 'required', manager: true }),
    defineField({ name: 'email', validation: 'required|email', props: emailProps }),
    defineField({ name: 'password', validation: ['required', 'password'].concat(isManager ? ['confirmed'] : []), component: formFields.Password }),
    isManager && defineField({ name: 'password_confirmation', validation: 'required|password', component: formFields.Password }),
    defineField({ name: 'cgu', type: 'checkbox', validation: ['required', 'checked'], component: formFields.Checkbox }),
    defineField({ name: 'optin', type: 'checkbox', component: formFields.Checkbox }),
  ].filter((v) => !!v)
  const rules = compileRules(fields)

  //
  // Handlers
  //
  const handleValidate = (values) => validateAll(values, rules, messages)
  const handleSubmit = (values) => onSubmit({ ...values, type })

  //
  // Renderers
  //
  const renderForm = (formikProps) => (
    <Form
      noValidate
      autoComplete="off"
      onSubmit={formikProps.handleSubmit}
    >
      <div className={classes.fields}>
        {
          fields
            .filter((field) => field.type !== 'checkbox')
            .map((field, i) => (
              <div
                className={cx(classes.field, (!isManager && field.name !== 'companyNumber' && field.name !== 'companyMonacoNumber' && field.name !== 'companyTvaEuropeNumber') && classes.fieldNotManager)}
                key={i}
              >
                <Field
                  fieldConfig={field}
                  fieldsProps={fieldsProps}
                  formikProps={formikProps}
                />
              </div>
            ))
        }
        <div className={cx(classes.field, !isManager && classes.fieldNotManager)}>
          {
            fields
              .filter((field) => field.type === 'checkbox')
              .map((field, i) => (
                <React.Fragment key={i}>
                  <Field
                    fieldConfig={field}
                    fieldsProps={{ ...fieldsProps, className: classes.checkboxContainer }}
                    formikProps={formikProps}
                  />
                </React.Fragment>
              ))
          }
        </div>
        {disclaimerLabel && (
        <div className={classes.disclaimer}>
          {disclaimerLabel}
        </div>
        )}
      </div>

      {backendErrors && (
        <div className={classes.errors}>
          {backendErrors.map((error, i) => (
            <div
              className={classes.error}
              key={i}
            >
              {error.label}
            </div>
          ))}
        </div>
      )}

      <div className={classes.submitContainer}>
        <button type="submit">
          <ActionButton
            id={id}
            color="primary"
            label={label}
            className={classes.button}
          />
        </button>
      </div>
    </Form>
  )

  return (
    <div className={cx(classes.container, className)}>
      <Formik
        validate={handleValidate}
        onSubmit={handleSubmit}
        initialValues={compileInitialValues(fields, initialValues)}
      >
        {renderForm}
      </Formik>
    </div>
  )
}

RegisterForm.styles = styles

RegisterForm.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  fieldsProps: PropTypes.objectOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    help: PropTypes.string,
    placeholder: PropTypes.string,
  })),
  disclaimerLabel: PropTypes.string,
  initialValues: PropTypes.objectOf(PropTypes.string),
  messages: PropTypes.objectOf(PropTypes.string),
  onSubmit: PropTypes.func,
  errors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    field: PropTypes.string,
  })),
  isManager: PropTypes.bool,
}

RegisterForm.defaultProps = {
  id: '',
  className: '',
  label: '',
  type: '',
  fieldsProps: {},
  disclaimerLabel: null,
  initialValues: {},
  messages: {},
  onSubmit: () => null,
  errors: null,
  isManager: false,
}

export default withMemo()(RegisterForm)
