import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { actions as AuthActions, selectors as AuthSelectors } from '../../redux/auth/redux'
import { pageType, selectors as AppSelectors } from '../../redux/app/redux'
import { selectors as PageSelectors } from '../../pages/Page/redux'
import LoginLayout from '../LoginLayout/LoginLayout'
import RegisterPushWrapper from '../RegisterPush/wrapper'
import { getFieldTranslations } from '../../helpers/FormHelpers'
import withMemo from '../../decorators/WithMemo'
import routes from '../../routes'

import LoginForgot from './index'


function LoginForgotWrapper(props) {
  const { t } = useTranslation()

  const pageSelector = useSelector(AppSelectors.page)
  const loginLink = pageSelector(pageType.LOGIN).link
  const forgotSelector = useSelector(AuthSelectors.forgot)
  const submitErrors = forgotSelector?.errors
  const submitSuccess = forgotSelector?.success

  const title = useSelector(PageSelectors.data)?.title

  const dispatch = useDispatch()

  const handleSubmit = (values) => dispatch(AuthActions.forgot(values))

  const { isCandidate } = props

  const forgotProps = {
    label: t('forgot_submit_label'),
    fieldsProps: {
      email: getFieldTranslations(t, 'forgot_email'),
    },
    messages: {
      required: t('form_validation_required'),
      email: t('form_validation_email'),
    },
    backLabel: t('forgot_back_label'),
    backLink: loginLink,
    backRoute: routes.page.name,
    backRouteParams: { slug: loginLink },
    confirmTitle: t('forgot_confirm_title'),
    confirmText: t('forgot_confirm_text'),
  }

  return (
    <LoginLayout
      title={title}
      mainContent={(
        <LoginForgot
          {...forgotProps}
          onSubmit={handleSubmit}
          submitErrors={submitErrors}
          submitSuccess={submitSuccess}
        />
      )}
      sideContent={(
        <RegisterPushWrapper
          isCandidate={isCandidate}
          isCard
        />
      )}
    />

  )
}

LoginForgotWrapper.propTypes = {
  isCandidate: PropTypes.bool,
}

LoginForgotWrapper.defaultProps = {
  isCandidate: false,
}

export default withMemo()(LoginForgotWrapper)
