import { colors, fonts } from '../../theme'


export default {
  container: {
    width: '100%',
    backgroundColor: colors.pureWhite,
    borderRadius: '2rem',
  },
  wrapper: {
    padding: '3rem',
  },
  title: {
    ...fonts.FiraSansBold,
    fontSize: '2.4rem',
    lineHeight: '120%',
    color: colors.tangerine,
    marginBottom: '3rem',
  },
  description: {
    marginBottom: '3rem',
    '& p': {
      ...fonts.FiraSansRegular,
      fontSize: '1.6rem',
      lineHeight: '120%',
      color: colors.nightRider,
    },
  },
  priceBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '3rem',
  },
  fullPrice: {
    ...fonts.FiraSansRegular,
    fontSize: '1.6rem',
    color: colors.darkBlue,
    textDecoration: 'line-through',
    marginRight: '1rem',
  },
  price: {
    '& p': {
      ...fonts.FiraSansRegular,
      fontSize: '1.6rem',
      color: colors.darkBlue,
      padding: 0,
      '& strong': {
        ...fonts.FiraSansBold,
        fontSize: '3.2rem',
      },
    },
  },
  cta: {
    marginBottom: '3rem',
  },
  priceNote: {
    ...fonts.FiraSansRegular,
    fontSize: '1.2rem',
    color: colors.nightRider,
    marginBottom: '3rem',
  },
  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: colors.gainsboro,
    marginBottom: '3rem',
  },
  advantages: {
    '& div': {
      marginBottom: '1rem',
    },
  },
  advantagesNote: {
    ...fonts.FiraSansBold,
    fontSize: '1.4rem',
    color: colors.nightRider,
    marginBottom: '3rem',
  },
  advantage: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    width: '3.2rem',
    height: '3.2rem',
    margin: '0 1rem 0 0',
    '& svg': {
      width: '3.2rem',
      height: '3.2rem',
    },
  },
  advantageText: {
    display: 'flex',
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    color: colors.nightRider,
    marginBottom: '0 !important',
  },
}
