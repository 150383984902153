import { colors, fonts } from '../../theme'


export default ({
  container: {
    position: 'relative',
    maxWidth: '25rem',
    width: '100%',
  },
  placeholder: {
    backgroundColor: ({ isOpen }) => (isOpen ? colors.darkBlue : colors.pureWhite),
    borderRadius: '2rem',
    borderBottomLeftRadius: ({ isOpen }) => isOpen && 0,
    borderBottomRightRadius: ({ isOpen }) => isOpen && 0,
    border: `.1rem solid ${colors.darkBlue}`,
    cursor: 'pointer',
    padding: '1.1rem 3rem 1rem 1.5rem',
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    textAlign: 'left',
    width: '25rem',
    color: ({ isOpen }) => (isOpen ? colors.pureWhite : colors.darkBlue),
    '&:after': {
      transition: 'all .2s ease',
      transform: ({ isOpen }) => (isOpen ? 'rotate(180deg)' : ''),
      transformOrigin: 'center center',
      content: '""',
      position: 'absolute',
      borderStyle: 'solid',
      borderWidth: '6px 5px 0 5px',
      borderColor: ({ isOpen }) => (
        isOpen
          ? `${colors.pureWhite} transparent transparent transparent`
          : `${colors.darkBlue} transparent transparent transparent`
      ),
      borderRadius: 3,
      top: 0,
      right: '5%',
      bottom: 0,
      width: 10,
      height: 6,
      margin: 'auto',
    },
  },
  submenu: {
    backgroundColor: colors.pureWhite,
    position: 'absolute',
    top: '4rem',
    left: 0,
    width: '100%',
    border: `.1rem solid ${colors.darkBlue}`,
    borderTop: 'none',
    borderBottomLeftRadius: '2rem',
    borderBottomRightRadius: '2rem',
    overflow: 'hidden',
    padding: '2rem 1.5rem 0 1.5rem',
    zIndex: 1,
  },
  filter: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '2rem',
  },
  label: {
    ...fonts.FiraSansBold,
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    color: colors.nightRider,
    textTransform: 'uppercase',
    marginBottom: '1.5rem',
  },
})
