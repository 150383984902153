import OfferCard from '../../data/models/OfferCard'
import { pageType } from '../../redux/app/redux'
import routes from '../../routes'


export default ({ title, text, offers, __typename }, { pages }) => ({
  __typename,
  title,
  text,
  cards: offers?.map(OfferCard),
  route: {
    name: routes.page.name,
    params: {
      slug: pages[pageType.ON_BOARDING]?.link,
    },
  },
})
