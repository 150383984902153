import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import GroupCheckbox from '../form/fields/CheckboxGroup'
import RadioGroup from '../form/fields/RadioGroup'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const FilterBox = (props) => {
  const classes = useStyles(props)
  const {
    className,
    placeholder,
    filters,
    values,
    onChange,
    isOpen,
    toggleSelect,
    containerRef,
    submenuRef,
  } = props

  const renderFilters = () => filters.map((filter, i) => {
    const FilterContainer = filter?.type === 'checkbox' || filter?.isMulti ? GroupCheckbox : RadioGroup
    const currentValue = values.filter((item) => item.name === filter.name)

    if (filter.visible === false) {
      return null
    }
    return (
      <div
        key={i}
        className={classes.filter}
      >
        <div
          className={classes.label}
          dangerouslySetInnerHTML={{ __html: filter.label }}
        />
        <FilterContainer
          {...filter}
          value={currentValue.length >= 1 ? currentValue[0].value : []}
          onChange={onChange}
        />
      </div>
    )
  })

  return (
    <div
      className={cx(classes.container, className)}
      ref={containerRef}
    >
      <div
        className={classes.placeholder}
        dangerouslySetInnerHTML={{ __html: placeholder }}
        onClick={toggleSelect}
        role="presentation"
      />
      {isOpen && (
        <div
          className={classes.submenu}
          ref={submenuRef}
        >
          {renderFilters()}
        </div>
      )}
    </div>
  )
}

FilterBox.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.oneOf(['checkbox', 'radio']),
    isMulti: PropTypes.bool,
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })),
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  })),
  values: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  })),
  onChange: PropTypes.func,
  isOpen: PropTypes.bool,
  toggleSelect: PropTypes.func,
  containerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any,
    }),
  ]),
  submenuRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any,
    }),
  ]),
}

FilterBox.defaultProps = {
  className: '',
  filters: [],
  values: [],
  onChange: null,
  isOpen: false,
  toggleSelect: () => undefined,
  containerRef: null,
  submenuRef: null,
}

export default withMemo()(FilterBox)
