import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import ArticleCard from '../ArticleCard'
import MarkdownText from '../MarkdownText'
import Pagination from '../Pagination'
import withMemo from '../../decorators/WithMemo'
import FilterBox from '../FilterBox'
import PaginatedFilteredResults from '../PaginatedFilteredResults'

import styles from './styles'


const useStyles = createUseStyles(styles)

// eslint-disable-next-line complexity
const ArticleFragment = (props) => {
  // eslint-disable-next-line max-len
  const { className, text, articles, emptyArticlesText, pagination, filterBoxProps, handleSubmit, filterTitle, isLinkedArticles } = props

  const classes = useStyles(props)

  const renderArticles = () => articles.map((article, i) => (
    <ArticleCard
      key={i}
      {...article}
      className={
        cx(
          classes.articleCard,
          i === 0 && article.category.theme === 'FEATURED' && classes.featured
        )
      }
    />
  ))

  const renderEmptyArticles = () => (
    <p
      className={classes.emptyArticles}
      dangerouslySetInnerHTML={{ __html: emptyArticlesText }}
    />
  )

  return (
    <div className={cx(classes.container, className)}>

      {text !== null && (
        <MarkdownText
          text={text}
          className={classes.text}
        />
      )}

      {!isLinkedArticles && (
        <PaginatedFilteredResults
          pagination={pagination}
          emptyText={emptyArticlesText}
          filterBoxProps={filterBoxProps}
          handleSubmit={handleSubmit}
          classes={classes}
          results={articles}
          filterTitle={filterTitle}
        >
          <div className={classes.articles}>
            {articles.length >= 1 && (
              <div className={classes.articlesList}>
                {renderArticles()}
              </div>
            )}
            {articles.length === 0 && emptyArticlesText !== null && renderEmptyArticles()}
          </div>
        </PaginatedFilteredResults>
      )}

      {isLinkedArticles && (
        <div className={classes.articles}>
          {articles.length >= 1 && (
            <div className={classes.articlesList}>
              {renderArticles()}
            </div>
          )}
          {articles.length === 0 && emptyArticlesText !== null && renderEmptyArticles()}
        </div>
      )}


    </div>
  )
}

ArticleFragment.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  articles: PropTypes.arrayOf(PropTypes.shape(ArticleCard.propTypes)),
  emptyArticlesText: PropTypes.string,
  pagination: PropTypes.shape(Pagination.propTypes),
  filterBoxProps: PropTypes.shape(FilterBox.propTypes),
  handleSubmit: PropTypes.func,
  filterTitle: PropTypes.string,
  isLinkedArticles: PropTypes.bool,
}

ArticleFragment.defaultProps = {
  className: '',
  text: null,
  articles: [],
  emptyArticlesText: null,
  pagination: null,
  filterBoxProps: null,
  handleSubmit: () => undefined,
  filterTitle: '',
  isLinkedArticles: false,
}

export default withMemo()(ArticleFragment)
