import routes, { Router } from '../../routes'
import { routeParamSlug } from '../../helpers/UrlHelpers'

import CompanyCardModel from './CompanyCardModel'


const ProfileCardModel = (props) => (props && {
  id: props.id,
  firstName: props.first_name,
  lastName: props.last_name,
  fullName: `${props?.first_name} ${props?.last_name?.toUpperCase()}`,
  shortName: `${props?.first_name} ${props?.last_name?.toUpperCase()?.charAt(0)}.`,
  avatar: props.avatar,
  companyFunction: props?.companyFunction,
  company: CompanyCardModel(props?.company),
  link: Router.getRouteUrl(routes.profile, { slug: props.slug }),
  route: routes.profile.name,
  routeParams: { slug: routeParamSlug(props.slug) },
})

export default ProfileCardModel
