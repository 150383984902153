import { colors, desktop, fonts, fullDesktop, sizes, tablet } from '../../theme'


export default {
  container: {
    background: colors.frenchBlue,
  },
  wrapper: {
    maxWidth: `${sizes.fullDesktop / 10}rem`,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '4rem 2rem',
    ...desktop({
      maxWidth: `${sizes.desktop / 10}rem`,
    }),
    ...fullDesktop({
      maxWidth: `${sizes.fullDesktop / 10}rem`,
    }),
    ...tablet({
      padding: '4rem 0',
    }),
  },
  title: {
    ...fonts.FiraSansBold,
    color: colors.pureWhite,
    margin: '0 0 4rem 0',
    fontSize: '2rem',
    ...tablet({
      fontSize: '3rem',
    }),
  },
  testimonies: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  swiperContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    listStyle: 'none',
    zIndex: 1,
  },
  swiperWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    zIndex: 1,
    display: 'flex',
    alignItems: 'flex-start',
    transitionProperty: 'transform',
    boxSizing: 'content-box',
    transform: 'translate3d(0px, 0, 0)',
    marginBottom: ['2rem', '!important'],
  },
  divider: {
    width: '1px',
    height: '100%',
    background: colors.icebergBlue,
    margin: '0 6rem 0 1rem',
  },
  swiperSlide: {
    flexShrink: 0,
    width: '100%',
    height: '18rem',
    position: 'relative',
    transitionProperty: 'transform',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  swiperNavigation: {
    width: '3rem',
    height: '3rem',
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 1rem 2rem 1rem',
    borderRadius: '50%',
    padding: '0.5rem',
    background: colors.icebergBlue,
    cursor: 'pointer',
    outline: 0,
    '& svg': {
      width: '1.5rem',
      height: '1.5rem',
    },
    '& path': {
      fill: colors.pureWhite,
    },
    ...desktop({
      display: 'flex',
    }),
  },
  swiperPrev: {
    extend: 'swiperNavigation',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%) rotate(180deg)',
    ...tablet({
      left: '-5%',
    }),
    ...fullDesktop({
      left: '-7%',
    }),
  },
  swiperNext: {
    extend: 'swiperNavigation',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    ...tablet({
      right: '-5%',
    }),
    ...fullDesktop({
      right: '-7%',
    }),
  },
}
