import { colors, fonts, sizes, tablet } from '../../theme'


export default {
  container: {
    width: '100%',
    maxWidth: `${sizes.desktop / 10}rem`,
    margin: '0 auto',
    padding: '0 2rem',
    ...tablet({
      padding: 0,
    }),
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'auto',
    maxWidth: '100%',
  },
  title: {
    '& p': {
      ...fonts.FiraSansBold,
      fontSize: '2rem',
      lineHeight: '120%',
      color: colors.nightRider,
      textAlign: 'center',
      ...tablet({
        fontSize: '3.2rem',
      }),
      '& strong': {
        color: colors.darkBlue,
      },
      ...tablet({
        fontSize: '3rem',
      }),
    },
  },
  articles: {
    display: 'flex',
    overflowX: 'scroll',
    alignSelf: 'flex-start',
    '& > a:not(:last-child)': {
      marginRight: '2rem',
    },
    ...tablet({
      display: 'flex',
      flexDirection: 'row',
      overflow: 'auto',
    }),
  },
  article: {
    display: 'flex',
    width: '30rem',
    ...tablet({
    }),
  },
  cta: {
    marginTop: '4rem',
  },
}
