export const certificationType = {
  CERTIFICATION_TYPE_RSE: 'CERTIFICATION_TYPE_RSE',
  CERTIFICATION_TYPE_ENVIRONMENTAL: 'CERTIFICATION_TYPE_ENVIRONMENTAL',
  CERTIFICATION_TYPE_SOCIAL: 'CERTIFICATION_TYPE_SOCIAL',
  CERTIFICATION_TYPE_PURCHASING: 'CERTIFICATION_TYPE_PURCHASING',
  CERTIFICATION_TYPE_CUSTOMER_RELATIONS: 'CERTIFICATION_TYPE_CUSTOMER_RELATIONS',
  CERTIFICATION_TYPE_DOMAIN: 'CERTIFICATION_TYPE_DOMAIN',
  CERTIFICATION_TYPE_CUSTOM: 'CERTIFICATION_TYPE_CUSTOM',
}

export const certificationTypeArray = [
  certificationType.CERTIFICATION_TYPE_RSE,
  certificationType.CERTIFICATION_TYPE_ENVIRONMENTAL,
  certificationType.CERTIFICATION_TYPE_SOCIAL,
  certificationType.CERTIFICATION_TYPE_PURCHASING,
  certificationType.CERTIFICATION_TYPE_CUSTOMER_RELATIONS,
  certificationType.CERTIFICATION_TYPE_DOMAIN,
  certificationType.CERTIFICATION_TYPE_CUSTOM,
]

export const certificationTypeOptions = (t) => certificationTypeArray.map((value) => ({
  label: t(`certificationType_${value}`),
  value,
}))
