import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { getCloudinaryInstance, getCloudinarySoundUrl } from '../../helpers/CloudinaryHelpers'
import withMemo from '../../decorators/WithMemo'



const CloundinarySound = (props) => {
  const { id, className, isPlaying } = props
  const [cl] = useState(getCloudinaryInstance())
  const audio = useRef(null)

  useEffect(() => {
    cl.responsive()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isPlaying && audio.current) {
      audio.current.play()
    } else {
      audio.current.pause()
    }
  })

  const getUrl = (opts = {}) => getCloudinarySoundUrl(id, { ...opts })


  if (!id) {
    return null
  }

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <audio
      id="playAudio"
      ref={audio}
      className={className}
    >
      <source src={getUrl()} />
    </audio>
  )
}

CloundinarySound.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  isPlaying: PropTypes.bool,
}

CloundinarySound.defaultProps = {
  id: null,
  className: '',
  isPlaying: false,
}


export default withMemo()(CloundinarySound)
