import { colors, fonts, sizes, tablet } from '../../../../theme'


export default {
  container: {
    position: 'relative',
    padding: '5rem 0',
    ...tablet({
      padding: '14rem 0 2rem',
      marginBottom: '-14rem',
    }),
  },
  background: {
    display: 'none',
    ...tablet({
      display: 'flex',
      position: 'absolute',
      overflow: 'hidden',
      height: 'auto',
      top: 0,
      bottom: '14rem',
      '& img': {
        maxWidth: 'none',
        maxHeight: 'none',
      },
    }),
  },
  title: {
    ...fonts.FiraSansBold,
    fontSize: '3rem',
    lineHeight: '120%',
    marginBottom: '1rem',
    color: colors.darkBlue,
    maxWidth: `${sizes.desktop / 10}rem`,
    padding: '1rem 2rem',
    margin: '0 auto',
    position: 'relative',
    ...tablet({
      color: colors.pureWhite,
    }),
  },
  wrapper: {
    maxWidth: `${sizes.desktop / 10}rem`,
    padding: '3rem 2rem',
    margin: '0 auto',
    position: 'relative',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    ...tablet({
      borderRadius: '2rem',
      background: colors.pureWhite,
      boxShadow: '0px 5px 30px rgba(84, 84, 84, 0.1)',
      paddingBottom: '4rem',
    }),
  },
  entries: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '0',
    ...tablet({
      flexDirection: 'row',
      margin: '0 -2rem',
    }),
  },
  entry: {
    ...fonts.FiraSansRegular,
    fontSize: '2rem',
    lineHeight: '120%',
    marginBottom: '3rem',
    color: colors.darkBlue,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '2rem',
    alignItems: 'center',
    width: '100%',
  },

}
