import React, { useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import ActionButton from '../ActionButton'
import PublicTitleTextContainer from '../PublicTitleTextContainer'
import MarkdownText from '../MarkdownText'
import { app } from '../../config'

import styles from './styles'


const useStyles = createUseStyles(styles)

const EventPublic = (props) => {
  const {
    className,
    isConnected,
    title,
    introduction,
    ctaLink,
    ctaLabel,
    textBeforeTitleTexts,
    titleTextContainer,
    textAfterTitleTexts,
  } = props

  const [showingMore, setShowingMore] = useState(false)
  const classes = useStyles({ ...props, showingMore })

  const showLess = () => setShowingMore(false)
  const showMore = () => setShowingMore(true)

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.containerWhite}>
        <div className={classes.subcontainer}>
          <MarkdownText
            className={classes.title}
            text={title}
          />
          <MarkdownText
            className={classes.text}
            text={introduction}
          />
          {ctaLabel && ctaLink && (
            <ActionButton
              className={classes.cta}
              label={ctaLabel}
              link={isConnected ? app.REGISTER_URL : ctaLink}
            />
          )}
          {titleTextContainer && (
            <>
              <MarkdownText
                text={textBeforeTitleTexts}
              />
              {showingMore && (
                <>
                  <PublicTitleTextContainer
                    className={classes.titleTextContainer}
                    titleTexts={titleTextContainer.titleTexts}
                    greyBackground
                    textBlue
                  />
                  <MarkdownText
                    text={textAfterTitleTexts}
                  />
                </>
              )}
              <div className={classes.showMoreContainer}>
                <ActionButton
                  className={classes.showMore}
                  label={showingMore ? 'Voir moins' : 'Voir plus'}
                  onClick={showingMore ? showLess : showMore}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

EventPublic.propTypes = {
  className: PropTypes.string,
  isConnected: PropTypes.bool,
  title: PropTypes.string.isRequired,
  introduction: PropTypes.string,
  ctaLink: PropTypes.string,
  ctaLabel: PropTypes.string,
  textBeforeTitleTexts: PropTypes.string,
  titleTextContainer: PropTypes.object,
  textAfterTitleTexts: PropTypes.string,
}

EventPublic.defaultProps = {
  className: '',
  isConnected: false,
  introduction: null,
  ctaLink: null,
  ctaLabel: null,
  textBeforeTitleTexts: '',
  titleTextContainer: {},
  textAfterTitleTexts: '',
}

export default withMemo()(EventPublic)
