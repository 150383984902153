import routes from '../../routes'
import { routeParamSlug } from '../../helpers/UrlHelpers'


export default (props) => ({
  __typename: 'TitleTextCTAPushWrapper',
  ...props.linkable && (props.linkable?.__typename === 'Page' ? {
    route: routes.page.name,
    routeParams: {
      slug: routeParamSlug(props.linkable.full_slug),
    },
  } : {
    route: props.linkable?.key,
  }),
  ...props.cta_title && props.url && props.url !== '' && {
    ctaLink: props.url,
  },
  ...props.cta_title && props.linkable?.full_slug && {
    ctaLink: props.linkable.full_slug,
  },
  ctaLabel: props.cta_title,
  ...props.title && {
    title: props.title,
  },
  ...props.text && {
    text: props.text,
  },
  image: '/images/logo.png',
})
