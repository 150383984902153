import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import MarkdownText from '../../../../MarkdownText'
import CloudinaryImage from '../../../../CloudinaryImage'
import withMemo from '../../../../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const Argument = (props) => {
  const classes = useStyles(props)
  const { className, title, text, img, imgPosition } = props

  return (
    <div className={cx(classes.container, className)}>
      <div
        className={classes.wrapper}
        imgPosition={imgPosition}
      >
        <CloudinaryImage
          className={classes.img}
          id={img}
          lazyLoad
          options={{
            width: '569',
            height: '380',
            crop: 'fill',
          }}
        />
        <div className={classes.content}>
          <MarkdownText
            className={classes.title}
            text={title}
            tag="h3"
          />
          <MarkdownText
            className={classes.text}
            text={text}
          />
        </div>
      </div>
    </div>
  )
}

Argument.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  img: PropTypes.string,
  imgPosition: PropTypes.string,
}

Argument.defaultProps = {
  className: '',
  title: '',
  text: '',
  img: '',
  imgPosition: '',
}

export default withMemo()(Argument)
