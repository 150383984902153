import { pageType } from '../../redux/app/redux'
import routes, { Router } from '../../routes'
import { routeParamSlug } from '../../helpers/UrlHelpers'


export default (props, { pages }) => ({
  __typename: 'TopCompaniesWrapper',
  ...props.title && {
    title: props.title,
  },
  ...props.cta_title && props.cta_title !== '' && {
    ctaLink: pages[pageType.DIRECTORY].link,
    ctaLabel: props.cta_title,
    ctaRoute: routes.page.name,
    ctaRouteParams: { slug: pages[pageType.DIRECTORY].link },
  },
  ...props.topCompanies && props.topCompanies.data && props.topCompanies.data.length >= 1 && {
    cards: props.topCompanies.data.map((company) => ({
      link: Router.getRouteUrl(routes.company, { slug: company.full_slug }),
      route: routes.company.name,
      routeParams: { slug: routeParamSlug(company.full_slug) },
      logo: company.logo,
      title: company.name,
      offers: company.offers_count,
      location: company.location?.city || '',
    })),
  },
})
