import { colors, fonts, sizes, tablet } from '../../../../theme'


export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    zIndex: 1,
    background: colors.honeydew,
  },
  background: {
    ...tablet({
      position: 'absolute',
      zIndex: 0,
      overflow: 'hidden',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    }),
  },
  backgroundContent: {
    ...tablet({
      position: 'absolute',
      background: colors.darkBlue,
      width: '120%',
      top: '-3rem',
      bottom: '-3rem',
      left: '-10%',
      right: '-10%',
      margin: 'auto',
      transform: 'rotate(-1.4deg)',
    }),
  },
  wrapper: {
    maxWidth: `${sizes.desktop / 10}rem`,
    borderRadius: '2rem',
    position: 'relative',
    padding: '2rem',
    ...tablet({
      padding: '4rem 8rem',
      margin: '-6rem -2rem',
      background: colors.pureWhite,
    }),
  },
  title: {
    ...fonts.FiraSansBold,
    margin: '0',
    fontSize: '3rem',
    lineHeight: '120%',
    marginBottom: '1rem',
    color: colors.darkBlue,
    textAlign: 'left',
  },
  entries: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0',
    ...tablet({
      flexDirection: 'row',
      margin: '0 -2rem',
    }),
  },
  entry: {
    ...fonts.FiraSansRegular,
    fontSize: '2rem',
    lineHeight: '120%',
    marginBottom: '3rem',
    color: colors.darkBlue,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '2rem',
    alignItems: 'center',
  },
  entryLogo: {
    maxWidth: '11rem',
    '& > img': {
      width: '100%',
    },
  },
  entryText: {
    ...fonts.FiraSansRegular,
    fontSize: '1.8rem',
    lineHeight: '120%',
    color: colors.nightRider,
    marginTop: '1rem',
    textAlign: 'center',
    width: '100%',
  },
}
