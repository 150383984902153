import { colors, desktop, fonts } from '../../theme'


export default {
  container: {
    padding: '0 1.5rem 4rem',
    ...desktop({
      maxWidth: '40rem',
      width: '100%',
    }),
  },
  fields: {
    height: '100%',
    width: '100%',
    alignItems: 'left',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  field: {
    padding: '2rem 0',
    width: '100%',
  },
  checkbox: {
    padding: '1rem 0',
  },
  errors: {
    textAlign: 'center',
  },
  error: {
    color: colors.error,
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    padding: '0.5rem 0',
  },
  forgotContainer: {
    paddingBottom: '1rem',
    width: '100%',
  },
  forgot: {
    ...fonts.FiraSansRegular,
    fontSize: '1.2rem',
    color: colors.darkOrange,
  },
  submitContainer: {
    textAlign: 'center',
    paddingTop: '2rem',
    ...desktop({
      textAlign: 'left',
    }),
  },
  button: {
    marginRight: '2rem',
  },
  confirmContainer: {
    paddingTop: '4rem',
    color: colors.darkBlue,
    fontSize: '1.5rem',
  },
  confirmTitle: {
    ...fonts.FiraSansBold,
  },
  confirmText: {
    ...fonts.FiraSansRegular,
  },
}
