import Article from '../../data/models/Article'


export default (props, { pages }) => ({
  __typename: 'ArticleTransverseContainerBlock',
  ...props.title && {
    title: props.title,
  },
  ...props.cta_link && {
    ctaLink: props.cta_link,
  },
  ...props.cta_title && {
    ctaLabel: props.cta_title,
  },
  ...props.articles && {
    articles: props.articles.map((article) => Article(article)),
  },
})
