import React, { useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'
import { colors } from '../../theme'

import styles from './styles'


const useStyles = createUseStyles(styles)

const Accordion = (props) => {
  const classes = useStyles(props)
  const {
    className,
    title,
    text,
  } = props
  const [isOpen, setOpen] = useState(false)


  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
        <div
          className={classes.header}
          onClick={() => setOpen(!isOpen)}
          role="button"
        >
          <div className={classes.title}>
            {title}
          </div>
          <Icon
            className={cx(classes.icon, isOpen && classes.open)}
            icon={iconsKeys.Chevron}
            color={colors.darkBlue}
          />
        </div>
        <div className={cx(classes.textBox, isOpen && classes.textOpen)}>
          <div className={classes.text}>
            {text}
          </div>
        </div>
      </div>
    </div>
  )
}

Accordion.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
}

Accordion.defaultProps = {
  className: '',
  title: '',
  text: '',
}

export default withMemo()(Accordion)
