import Event from '../../data/models/Event'
import routes from '../../routes'


export default (
  { filters, eventsByFilters, pushPremium },
  { sortConfig, filterConfig, sortValue, filterValues }
) => ({
  __typename: 'EventsResultsWrapper',
  filters,
  sortValue,
  filterValues,
  sortConfig,
  filterConfig,
  ...eventsByFilters.data && eventsByFilters.data.length >= 1 && {
    events: eventsByFilters.data.map((event) => Event(event)),
    ...eventsByFilters.paginatorInfo && {
      pagination: {
        currentPage: eventsByFilters.paginatorInfo.currentPage,
        totalPage: eventsByFilters.paginatorInfo.lastPage,
        total: eventsByFilters.paginatorInfo.total,
        route: routes.pagePaginated.name,
        routeBase: routes.page.name,
      },
    },
  },
  pushPremium,
})
