import { colors, fonts, sizes, tablet } from '../../../../theme'


export default {
  container: {
    maxWidth: `${sizes.desktop / 10}rem`,
    margin: '2rem auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    marginTop: '3rem',
    marginBottom: '3rem',
    '& p': {
      ...fonts.FiraSansBold,
      fontSize: '2.6rem',
      lineHeight: '120%',
      color: colors.nightRider,
      textAlign: 'center',
      ...tablet({
        fontSize: '3.1rem',
      }),
      '& strong': {
        color: colors.darkBlue,
      },
    },
  },
}
