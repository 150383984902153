export const directoryType = {
  COMPANY: 'COMPANY',
  PROFILE: 'PROFILE',
}

export const directoryTypeArray = [
  directoryType.COMPANY,
  directoryType.PROFILE,
]

export const directoryTypeOptions = (t) => directoryTypeArray.map((value) => ({
  label: t(`directoryType_${value}`),
  value,
  id: value,
}))
