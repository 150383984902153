import { sizes } from '../../theme'


export default {
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  wrapper: {
    maxWidth: `${sizes.maxDesktop / 10}rem`,
  },
  link: {
  },
  image: {
  },
}
