import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { getFieldTranslations } from '../../helpers/FormHelpers'
import { pageType, selectors as AppSelectors } from '../../redux/app/redux'
import { actions as AuthActions, selectors as AuthSelectors } from '../../redux/auth/redux'
import routes from '../../routes'
import LoginLayout from '../LoginLayout/LoginLayout'
import RegisterPushWrapper from '../RegisterPush/wrapper'

import LoginForm from './index'


function LoginFormWrapper(props) {
  const { t } = useTranslation()

  const pageSelector = useSelector(AppSelectors.page)
  const forgotLink = pageSelector(pageType.FORGOT).link
  const registerSelector = useSelector(AuthSelectors.login)
  const submitErrors = registerSelector?.errors

  const title = null // useSelector(PageSelectors.data)?.title

  const dispatch = useDispatch()

  const handleSubmit = (values) => dispatch(AuthActions.login(values))

  const { isCandidate } = props

  const loginProps = {
    label: t('login_submit_label'),
    fieldsProps: {
      email: getFieldTranslations(t, 'login_email'),
      password: getFieldTranslations(t, 'login_password'),
    },
    messages: {
      required: t('form_validation_required'),
      email: t('form_validation_email'),
      password: t('form_validation_password'),
    },
    forgotLabel: t('login_forgot_label'),
    forgotLink,
    forgotRoute: routes.page.name,
    forgotRouteParams: { slug: forgotLink },
  }

  return (
    <LoginLayout
      title={title}
      mainContent={(
        <LoginForm
          {...loginProps}
          onSubmit={handleSubmit}
          submitErrors={submitErrors}
        />
      )}
      sideContent={(
        <RegisterPushWrapper
          isCandidate={isCandidate}
          isCard
        />
      )}
    />

  )
}

LoginFormWrapper.propTypes = {
  isCandidate: PropTypes.bool,
}

LoginFormWrapper.defaultProps = {
  isCandidate: false,
}

export default LoginFormWrapper
