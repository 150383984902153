import { colors, desktop, fonts, tablet } from '../../../../theme'
import { fontWeights } from '../../../../utils/FontUtils'
import { minHeight } from '../../styles'


export default {
  container: {
    width: '100%',
    height: '100%',
    ...minHeight,
    backgroundColor: colors.aliceBlue,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    minHeight: '32rem',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center 25%',
    position: 'relative',
  },
  overlay: {
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    maxHeight: '100%',
  },
  headerWrapper: {
    display: 'flex',
    flex: 1,
    zIndex: 3,
    width: '100%',
    maxWidth: '94rem',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    margin: '0 auto',
    padding: '2rem 1.5rem 8rem',
    ...desktop({
      padding: '2rem 1.5rem 3rem',
    }),
  },
  headerButton: {
    marginBottom: '2rem',
  },
  headerTitle: {
    marginBottom: '2rem',
  },
  headerTitleText: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '2.4rem',
    lineHeight: '2.6rem',
    textAlign: 'center',
    color: colors.pureWhite,
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    ...tablet({
      maxWidth: '43rem',
      textAlign: 'left',
      fontSize: '3rem',
      lineHeight: '120%',
    }),
  },
  wrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    ...minHeight,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    ...desktop({
      maxWidth: 800,
      margin: 'auto',
    }),
  },
  topSpacer: {
    marginBottom: '2rem',
  },
  title: {
    color: colors.darkBlue,
    display: 'flex',
    alignItems: 'center',
    marginLeft: '-1rem',
    position: 'relative',
    ...tablet({
      marginLeft: '1rem',
    }),
  },
  titleText: {
    ...fonts.FiraSansRegular,
    color: colors.darkBlue,
    fontSize: '2.4rem',
    paddingLeft: '1rem',
  },
  text: {
    ...fonts.FiraSansRegular,
    color: colors.grey,
    fontSize: '1.5rem',
    padding: '0 2rem 2rem 2rem',
  },
  nextContainer: {
    paddingTop: '1.5rem',
    paddingBottom: '5rem',
    paddingLeft: '2rem',
  },
  button: {},
}
