/* eslint-disable max-lines */
import React, { useCallback } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import types from '../../types'
import MarkdownText from '../../../MarkdownText'
import RegisterForm from '../../../RegisterForm'
import withMemo from '../../../../decorators/WithMemo'
import { safeCall } from '../../../../helpers/React'
import CloudinaryImage from '../../../CloudinaryImage'
import { imageTypes } from '../../../CloudinaryImage/types'
import { iconsKeys } from '../../../Icon/Icon.assets'
import { colors } from '../../../../theme'
import ActionButton from '../../../ActionButton'

import styles from './styles'


const useStyles = createUseStyles(styles)

function OnBoardingForm(props) {
  const {
    className,
    headerTitle,
    background,
    previousLabel,
    label,
    disclaimerLabel,
    freemiumTitle,
    premiumTitle,
    freemiumText,
    premiumText,
    initialValues,
    fieldsProps,
    onNext,
    onPrevious,
    type,
    messages,
    errors,
    linkedInConnectPushProps,
  } = props

  const classes = useStyles(props)

  const handleSubmit = useCallback((values) => {
    safeCall(onNext, { ...values, type })
  }, [onNext, type])

  //
  // Renderers
  //
  return (
    <div className={cx(classes.container, className)}>
      <CloudinaryImage
        id={background}
        type={imageTypes.Background}
        className={classes.headerContainer}
      >
        <div className={classes.overlay} />
        <div className={classes.headerWrapper}>
          <ActionButton
            label={previousLabel}
            onClick={onPrevious}
            color="white"
            icon={iconsKeys.SmallArrowLeft}
            colorIcon={colors.pureWhite}
            colorIconHover={colors.darkBlue}
            className={classes.headerButton}
            isOutlined
          />
          <div className={classes.headerTitle}>
            <h1 className={classes.headerTitleText}>
              {headerTitle}
            </h1>
          </div>
        </div>
      </CloudinaryImage>
      <div className={cx(classes.wrapper)}>
        <div className={classes.topSpacer} />
        <div className={classes.title}>
          <MarkdownText
            text={type === types.FREEMIUM ? freemiumTitle : premiumTitle}
            className={classes.titleText}
            tag="h2"
            inline
          />
        </div>
        <MarkdownText
          text={type === types.FREEMIUM ? freemiumText : premiumText}
          className={classes.text}
        />
        <RegisterForm
          id={type === types.FREEMIUM ? 'freemium-step-form' : 'premium-step-form'}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          messages={messages}
          disclaimerLabel={disclaimerLabel}
          fieldsProps={fieldsProps}
          errors={errors}
          label={label}
          type={type}
          linkedinProps={linkedInConnectPushProps}
        />
      </div>
    </div>
  )
}

OnBoardingForm.styles = styles

OnBoardingForm.propTypes = {
  className: PropTypes.string,
  headerTitle: PropTypes.string,
  background: PropTypes.string,
  previousLabel: PropTypes.string,
  label: PropTypes.string,
  disclaimerLabel: PropTypes.string,
  type: PropTypes.string,
  freemiumTitle: PropTypes.string,
  premiumTitle: PropTypes.string,
  freemiumText: PropTypes.string,
  premiumText: PropTypes.string,
  fieldsProps: PropTypes.objectOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    help: PropTypes.string,
    placeholder: PropTypes.string,
  })),
  initialValues: PropTypes.objectOf(PropTypes.string),
  messages: PropTypes.objectOf(PropTypes.string),
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  errors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    field: PropTypes.string,
  })),
  linkedInConnectPushProps: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.string)])
  ),
}

OnBoardingForm.defaultProps = {
  className: '',
  headerTitle: '',
  background: '',
  previousLabel: '',
  label: '',
  disclaimerLabel: '',
  type: '',
  freemiumTitle: '',
  premiumTitle: '',
  freemiumText: '',
  premiumText: '',
  fieldsProps: RegisterForm.defaultProps.fieldsProps,
  initialValues: RegisterForm.defaultProps.initialValues,
  messages: {},
  onNext: () => null,
  onPrevious: () => null,
  errors: null,
  linkedInConnectPushProps: null,
}

export default withMemo()(OnBoardingForm)
