import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import CloudinaryImage from '../CloudinaryImage'
import { iconsKeys } from '../Icon/Icon.assets'
import Icon from '../Icon'


import styles from './styles'


const useStyles = createUseStyles(styles)

const TestimonyCard = (props) => {
  const classes = useStyles(props)
  const { className, text, name, role, avatar, currentIndex, handleMedia, isMediaPlaying } = props

  const onMediaClick = () => {
    handleMedia(currentIndex)
  }

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        <div className={classes.avatarBox}>
          <Icon
            className={classes.avatarArround}
            icon={iconsKeys.AvatarSun}
          />
          {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
          <div
            className={classes.playBox}
            onClick={onMediaClick}
            role="button"
          >
            <Icon
              className={classes.playIcon}
              icon={isMediaPlaying ? iconsKeys.AudioPause : iconsKeys.AudioPlay}
            />
          </div>
          <CloudinaryImage
            className={classes.avatar}
            id={avatar}
            lazyLoad
            options={{
              crop: 'fill',
            }}
            covered
          />
        </div>
        <div className={classes.content}>
          <div className={classes.contentText}>
            <Icon
              className={classes.contentTextQuote}
              icon={iconsKeys.Quote}
            />
            <h3 className={classes.text}>{text}</h3>
          </div>
          <p className={classes.name}>{name}</p>
          <p className={classes.role}>{role}</p>
        </div>
      </div>
    </div>
  )
}

TestimonyCard.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  name: PropTypes.string,
  role: PropTypes.string,
  avatar: PropTypes.string,
  currentIndex: PropTypes.number,
  handleMedia: PropTypes.func,
  isMediaPlaying: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  mediaType: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  media: PropTypes.string,
}

TestimonyCard.defaultProps = {
  className: '',
  text: '',
  name: '',
  role: '',
  avatar: '',
  currentIndex: null,
  handleMedia: null,
  isMediaPlaying: false,
  mediaType: '',
  media: '',
}

export default withMemo()(TestimonyCard)
