import React, { useCallback, useEffect } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import VideoPlayer from '../VideoPlayer'
import Icon from '../Icon'
import Modal from '../Modal'
import { iconsKeys } from '../Icon/Icon.assets'

import styles from './styles'


const useStyles = createUseStyles(styles)

const VideoPlayerModal = (props) => {
  const classes = useStyles(props)
  const { className, videoProps, onClose, allowCookies, isOpen } = props

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      onClose()
    }
  }, [onClose])

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [escFunction])

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      classNames={cx(classes, className)}
    >
      <VideoPlayer
        {...videoProps}
        autoplay
        allowCookies={allowCookies}
      />
      <Icon
        icon={iconsKeys.Close}
        classname={classes.close}
        onClick={onClose}
      />
    </Modal>
  )
}

VideoPlayerModal.propTypes = {
  className: PropTypes.string,
  videoProps: PropTypes.shape(VideoPlayer.propTypes),
  onClose: PropTypes.func,
  allowCookies: PropTypes.bool,
  isOpen: PropTypes.bool,
}

VideoPlayerModal.defaultProps = {
  className: '',
  videoProps: {},
  onClose: () => null,
  allowCookies: false,
  isOpen: false,
}

export default withMemo()(VideoPlayerModal)
