import { colors, fonts } from '../../theme'


export default {
  container: {
    width: '100%',
  },
  wrapper: {
    background: colors.pureWhite,
    border: `1px solid ${colors.silver}`,
    borderRadius: '1rem',
    padding: '2rem',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    alignItems: 'center',
  },
  title: {
    ...fonts.FiraSansBold,
    fontSize: '1.8rem',
    color: colors.nightRider,
  },
  icon: {
    width: '1.4rem',
    height: '1.4rem',
    transform: 'rotate(90deg)',
    transition: 'all ease-in-out 0.5s',
  },
  open: {
    transform: 'rotate(270deg)',
  },
  textBox: {
    maxHeight: '0',
    overflow: 'hidden',
    transition: 'max-height ease-in-out 0.5s',
  },
  textOpen: {
    maxHeight: '15rem',
  },
  text: {
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    color: colors.nightRider,
    marginTop: '1rem',
  },
}
