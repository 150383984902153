export const offerSource = {
  ALL: 'ALL',
  CCI: 'CCI',
}

export const offerSourceArray = [
  offerSource.ALL,
  offerSource.CCI,
]

export const offerSourceOptions = (t) => offerSourceArray.map((value) => ({
  label: t(`offerSource_${value}`),
  value,
  id: value,
}))
