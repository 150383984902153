import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import withMemo from '../../decorators/WithMemo'
import types from '../OnBoarding/types'
import { actions as AppActions } from '../../redux/app/redux'

import SubscriptionPlanTable from '.'


const SubscriptionPlanTableWrapper = (props) => {
  const { className, title, text, tableHeading, sections, ctaLabel, show } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()

  //
  // This component is kinda connected to the different step of the onBoarding component.
  // We need to pass the current step / type to the onBoarding component.
  //
  const handleTypeClick = (type) => {
    dispatch(AppActions.setOnBoardingStep(1))
    dispatch(AppActions.setOnBoardingType(type))
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const data = {
    className,
    title,
    subtitle: text,
    typeTitle: tableHeading,
    typeFreemium: types.FREEMIUM,
    typePremium: types.PREMIUM,
    ctaLabel: t('subscription_cta_label_choose'),
    advantages: sections.map((offer) => ({
      title: offer.title,
      rows: offer.arguments.map((argument) => ({
        label: argument.title,
        hasPremium: JSON.parse(argument.type).premium,
        hasFreemium: JSON.parse(argument.type).freemium,
      })),
    })),
    onTypeClick: handleTypeClick,
    pushMeeting: {
      title: t('subscription_cta_label_push_meeting'),
      link: t('homepage_video_cta_link'),
      ctaLabel,
    },
  }

  // This component is on the register page, just to provide props to the Onboarding component. It should not be displayed in other context.
  if (!show) {
    return null
  }

  return (
    <SubscriptionPlanTable
      {...data}
    />
  )
}

SubscriptionPlanTableWrapper.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  tableHeading: PropTypes.string,
  sections: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    arguments: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      type: PropTypes.string,
    })),
  })),
  ctaLabel: PropTypes.string,
  premiumPrice: PropTypes.string,
  onTypeClick: PropTypes.func,
  show: PropTypes.bool,
}

SubscriptionPlanTableWrapper.defaultProps = {
  className: '',
  title: '',
  text: '',
  tableHeading: '',
  sections: [],
  ctaLabel: '',
  premiumPrice: '',
  onTypeClick: () => null,
  show: false,
}

export default withMemo()(SubscriptionPlanTableWrapper)
