import { eventTypeConfig } from '../../graphql/enums/eventType'
import { filterDateTypeConfig } from '../../graphql/enums/filterDateType'


export const eventsPrefix = 'eventsResults'

export const eventsFiltersConfig = [
  {
    type: 'checkbox',
    isMulti: true,
    isPath: true,
    name: 'types',
    slug: 'type',
    prefix: 'eventType',
    options: eventTypeConfig,
  },
  {
    type: 'checkbox',
    isPath: true,
    name: 'date',
    slug: 'date',
    prefix: 'filterDateType',
    options: filterDateTypeConfig,
  },
]

export const eventsSortConfig = [
  {
    name: 'date',
    slug: 'par-date',
    field: 'begin_at',
    order: 'ASC',
  },
  {
    name: 'location',
    slug: 'par-lieu',
    field: 'place',
    order: 'ASC',
  },
]
