/* eslint-disable max-lines */
import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { createUseStyles } from 'react-jss'

import { selectors as AuthSelectors } from '../../redux/auth/redux'
import withMemo from '../../decorators/WithMemo'
import FilterBox from '../FilterBox'
import { OfferCard } from '../index'
import MarkdownText from '../MarkdownText'
import Pagination from '../Pagination'
import PushMessage from '../PushMessage'
import PushPremium from '../PushPremium'
import SortBox from '../SortBox'

import styles from './styles'


const useStyles = createUseStyles(styles)

// eslint-disable-next-line complexity
const OffersResults = (props) => {
  const {
    className,
    title,
    sortBoxProps,
    filterBoxProps,
    entries,
    emptyText,
    pagination,
    handleSubmit,
    pushPublishProps,
    isCurrentOGCN,
  } = props

  const isConnected = useSelector(AuthSelectors.isConnected)

  const [sortValue, setSortValue] = useState(sortBoxProps.value || '')
  const [isSortOpen, setSortOpen] = useState(false)
  const [filterValues, setFilterValues] = useState(filterBoxProps.values || [])
  const [isFilterOpen, setFilterOpen] = useState(false)
  const [timerId, setTimerId] = useState(null)
  const [needsUpdate, setNeedsUpdate] = useState(false)
  const classes = useStyles({ ...props, isConnected })

  const handleFilterChange = useCallback((name, value) => {
    setFilterValues((before) => [
      ...before.filter((item) => item.name !== name),
      {
        name,
        value,
      },
    ])
    setNeedsUpdate(true)
  }, [])

  useEffect(() => {
    setFilterValues(filterBoxProps.values)
  }, [filterBoxProps.values])

  const handleSortChange = useCallback(({ value }) => {
    setSortValue(value)
    setSortOpen(false)
    setNeedsUpdate(true)
  }, [])

  const doSearch = useCallback(() => {
    if (timerId) {
      clearTimeout(timerId)
    }
    setTimerId(setTimeout(() => {
      handleSubmit({
        sortValue,
        filterValues,
      })
    }, 500))
  }, [handleSubmit, sortValue, filterValues, timerId])

  const handleToggleSorting = () => {
    if (!isSortOpen) {
      setFilterOpen(false)
    }
    setSortOpen(!isSortOpen)
  }

  const handleToggleFiltering = () => {
    if (!isFilterOpen) {
      setSortOpen(false)
    }
    setFilterOpen(!isFilterOpen)
  }

  const renderEntries = useMemo(() => entries?.map((entry, i) => (
    <OfferCard
      key={i}
      {...entry}
      className={classes.itemContainer}
      isCurrentOGCN={isCurrentOGCN}
    />
  )), [classes.itemContainer, entries, isCurrentOGCN])

  const renderEmpty = useMemo(() => (
    <p
      className={classes.empty}
      dangerouslySetInnerHTML={{ __html: emptyText }}
    />
  ), [classes.empty, emptyText])

  const sortContainerRef = React.createRef()
  const sortUlRef = React.createRef()
  const filterContainerRef = React.createRef()
  const filterSubmenuRef = React.createRef()

  const hasParent = (target, parent, currentRef) => {
    if (!target) {
      return false
    }
    if (target === parent || target.parentElement === currentRef) {
      return true
    }
    return hasParent(target.parentElement, parent, currentRef)
  }

  const handleWindowClick = (e) => {
    if (isSortOpen) {
      if (!hasParent(e.target, sortContainerRef.current, sortUlRef.current)) {
        setSortOpen(false)
      }
    }
    if (isFilterOpen) {
      if (!hasParent(e.target, filterContainerRef.current, filterSubmenuRef.current)) {
        setFilterOpen(false)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleWindowClick)

    return () => {
      window.removeEventListener('click', handleWindowClick)
    }
  })

  useEffect(() => {
    if (needsUpdate) {
      setNeedsUpdate(false)
      doSearch()
    }
  }, [needsUpdate, doSearch])

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.header}>
        {isConnected && title && (
          <MarkdownText
            text={title}
            className={classes.title}
            inline
          />
        )}
        <FilterBox
          {...filterBoxProps}
          className={classes.filterBlock}
          isOpen={isFilterOpen}
          toggleSelect={() => handleToggleFiltering()}
          values={filterValues}
          onChange={({ name, value }) => handleFilterChange(name, value)}
          containerRef={filterContainerRef}
          submenuRef={filterSubmenuRef}
        />
      </div>
      <div className={classes.content}>
        <div className={classes.wrapper}>
          <div className={classes.filters}>
            <SortBox
              {...sortBoxProps}
              className={classes.filterBlock}
              value={sortValue}
              isOpen={isSortOpen}
              onChange={handleSortChange}
              toggleSelect={() => handleToggleSorting()}
              containerRef={sortContainerRef}
              ulRef={sortUlRef}
            />
          </div>

          <div className={classes.results}>
            {entries?.length > 0 ? renderEntries : renderEmpty}
          </div>

          {entries?.length > 0 && (
            <Pagination {...pagination} />
          )}
        </div>

        {Boolean(pushPublishProps) && (
          <PushPremium
            {...pushPublishProps}
          />
        )}
      </div>
    </div>
  )
}

OffersResults.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  sortBoxProps: PropTypes.shape(SortBox.propTypes),
  filterBoxProps: PropTypes.shape(FilterBox.propTypes),
  entries: PropTypes.arrayOf(PropTypes.shape(OfferCard.propTypes)),
  emptyText: PropTypes.string,
  pagination: PropTypes.shape(Pagination.propTypes),
  handleSubmit: PropTypes.func,
  order: PropTypes.string,
  pushPublishProps: PropTypes.shape(PushMessage.propTypes),
  isCurrentOGCN: PropTypes.bool,
}

OffersResults.defaultProps = {
  className: '',
  title: null,
  sortBoxProps: null,
  filterBoxProps: null,
  entries: [],
  emptyText: null,
  pagination: null,
  handleSubmit: () => undefined,
  order: '',
  pushPublishProps: null,
  isCurrentOGCN: false,
}

export default withMemo()(OffersResults)
