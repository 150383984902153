import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import Linkable from '../../../../data/models/Linkable'
import MembershipEntry from '../../../../data/models/MembershipEntry'
import withMemo from '../../../../decorators/WithMemo'
import ActionButton from '../../../ActionButton'
import CloudinaryImage from '../../../CloudinaryImage'
import MarkdownText from '../../../MarkdownText'

import styles from './styles'


const useStyles = createUseStyles(styles)

const Membership = (props) => {
  const classes = useStyles(props)
  const {
    className,
    title,
    text,
    entries,
    ctaLabel,
    ctaLinkable,
  } = props

  return (
    <div className={cx(classes.container, className)}>
      <CloudinaryImage
        covered
        id="Homepage/background-membership"
        className={classes.background}
      />
      <div className={classes.wrapper}>
        <MarkdownText
          tag="h2"
          className={classes.title}
          text={title}
          inline
        />
        <MarkdownText
          className={classes.text}
          text={text}
          inline
        />
        <div className={classes.entries}>
          {
            entries?.map((entry, key) => (
              <div
                key={key}
                className={classes.entry}
              >
                <MarkdownText
                  tag="h3"
                  className={classes.entryTitle}
                  text={entry.title}
                  inline
                />
                <div className={classes.separator} />
                <MarkdownText
                  className={classes.entryText}
                  text={entry.text}
                  inline
                />
              </div>
            ))
          }
        </div>

        {ctaLabel && ctaLinkable && (
          <ActionButton
            label={ctaLabel}
            route={ctaLinkable?.route?.name}
            routeParams={ctaLinkable?.route?.params}
            link={ctaLinkable?.link}
            className={classes.cta}
            color="primary"
          />
        )}
      </div>
    </div>
  )
}

Membership.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  entries: PropTypes.arrayOf(MembershipEntry.propTypes),
  ctaLabel: PropTypes.string,
  ctaLinkable: Linkable.propTypes,
}

Membership.defaultProps = {
  className: '',
  title: '',
  text: '',
  entries: null,
  ctaLabel: null,
  ctaLinkable: null,
}

export default withMemo()(Membership)
