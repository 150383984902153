import PropTypes from 'prop-types'


const HowToEntry = (props) => (props && {
  logo: props.logo,
  text: props.text,
})

HowToEntry.propTypes = PropTypes.shape({
  logo: PropTypes.string,
  text: PropTypes.string,
})

export default HowToEntry
