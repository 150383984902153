import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import A from '../A'
import CloudinaryImage from '../CloudinaryImage'
import withMemo from '../../decorators/WithMemo'
import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'

import styles from './styles'


const defaultLogo = 'defaults/avatar'
const useStyles = createUseStyles(styles)

const ProfileCard = (props) => {
  const classes = useStyles(props)
  const { className, fullName, avatar, link, route, routeParams, company, companyFunction, isCurrentOGCN } = props

  return (
    <A
      href={link}
      route={route}
      routeParams={routeParams}
      className={cx(classes.container, className)}
      title={fullName}
    >
      <div className={classes.wrapper}>
        <CloudinaryImage
          id={avatar || defaultLogo}
          options={{
            width: '80',
            height: '70',
            crop: 'lpad',
          }}
          lazyLoad
          className={classes.logo}
        />
        <div className={classes.content}>
          {fullName && (
            <h2 className={classes.name}>{fullName}</h2>
          )}
          {companyFunction?.name && (
            <div className={classes.companyFunction}>{companyFunction?.name}</div>
          )}
          {company?.name && (
            <div className={classes.company}>{company?.name}</div>
          )}
          {isCurrentOGCN && company?.is_ogcn && (
            <div className={classes.boxOgcn}>
              <Icon
                icon={iconsKeys.OgcnLight}
                className={classes.iconOgcn}
              />
            </div>
          )}
        </div>
      </div>
    </A>
  )
}

ProfileCard.propTypes = {
  className: PropTypes.string,
  fullName: PropTypes.string,
  avatar: PropTypes.string,
  companyFunction: PropTypes.shape({
    name: PropTypes.string,
  }),
  company: PropTypes.shape({
    name: PropTypes.string,
  }),
  link: PropTypes.string,
  route: PropTypes.string,
  routeParams: PropTypes.shape({
    slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
  isCurrentOGCN: PropTypes.bool,
}

ProfileCard.defaultProps = {
  className: '',
  fullName: null,
  avatar: null,
  companyFunction: null,
  company: null,
  link: null,
  route: null,
  routeParams: null,
  isCurrentOGCN: false,
}

export default withMemo()(ProfileCard)
