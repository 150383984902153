import PropTypes from 'prop-types'

import FileLibrary from '../../components/FileLibrary'

import FileEntry from './FileEntry'


const Post = (props) => {
  if (!props) {
    return null
  }

  return {
    id: props.id,
    slug: props.slug,
    type: props.type,
    title: props.title,
    description: props.description,
    image: props.picture,
    files: {
      files: props.files.map(FileEntry),
    },
    link: props.link,
    linkLabel: props.link_label,
    publishedAt: props.published_at,
    isPublished: props.is_published,
    isOGCN: props.is_ogcn,
    user: props.user,
  }
}

Post.propTypes = PropTypes.shape({
  id: PropTypes.string,
  slug: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  files: PropTypes.shape(FileLibrary.propTypes),
  link: PropTypes.string,
  linkLabel: PropTypes.string,
  publishedAt: PropTypes.string,
  isPublished: PropTypes.bool,
  isOGCN: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.string,
    slug: PropTypes.string,
    avatar: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    company: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
      logo: PropTypes.string,
    }),
  }),
})

export default Post
