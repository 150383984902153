import PropTypes from 'prop-types'


const MembershipEntry = (props) => (props && {
  title: props.title,
  text: props.text,
})

MembershipEntry.propTypes = PropTypes.shape({
  title: PropTypes.string,
  text: PropTypes.string,
})

export default MembershipEntry
