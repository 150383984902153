export const turnoverType = {
  TURNOVER_LESS_THAN_10K: 'TURNOVER_LESS_THAN_10K',
  TURNOVER_BETWEEN_10K_AND_50K: 'TURNOVER_BETWEEN_10K_AND_50K',
  TURNOVER_BETWEEN_50K_AND_100K: 'TURNOVER_BETWEEN_50K_AND_100K',
  TURNOVER_BETWEEN_100K_AND_500K: 'TURNOVER_BETWEEN_100K_AND_500K',
  TURNOVER_BETWEEN_500K_AND_1M: 'TURNOVER_BETWEEN_500K_AND_1M',
  TURNOVER_MORE_THAN_1M: 'TURNOVER_MORE_THAN_1M',
}

export const turnoverTypeArray = [
  turnoverType.TURNOVER_LESS_THAN_10K,
  turnoverType.TURNOVER_BETWEEN_10K_AND_50K,
  turnoverType.TURNOVER_BETWEEN_50K_AND_100K,
  turnoverType.TURNOVER_BETWEEN_100K_AND_500K,
  turnoverType.TURNOVER_BETWEEN_500K_AND_1M,
  turnoverType.TURNOVER_MORE_THAN_1M,
]

const filterTurnoverTypeSlugs = {
  [turnoverType.TURNOVER_LESS_THAN_10K]: 'turnover_less_than_10k',
  [turnoverType.TURNOVER_BETWEEN_10K_AND_50K]: 'turnover_between_10k_and_50k',
  [turnoverType.TURNOVER_BETWEEN_50K_AND_100K]: 'turnover_between_50k_and_100k',
  [turnoverType.TURNOVER_BETWEEN_100K_AND_500K]: 'turnover_between_100k_and_500k',
  [turnoverType.TURNOVER_BETWEEN_500K_AND_1M]: 'turnover_between_500k_and_1m',
  [turnoverType.TURNOVER_MORE_THAN_1M]: 'turnover_more_than_1m',
}

export const filterTurnoverTypeConfig = turnoverTypeArray.map((value) => ({
  key: `turnoverType_${value}`,
  id: value,
  value: filterTurnoverTypeSlugs[value],
}))


export const turnoverTypeOptions = (t) => turnoverTypeArray.map((value) => ({
  label: t(`turnoverType_${value}`),
  value,
}))
