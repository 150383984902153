import routes from '../../routes'
import { directoryType } from '../../graphql/enums/DirectoryType'
import CompanyCardModel from '../../data/models/CompanyCardModel'
import ProfileCardModel from '../../data/models/ProfileCardModel'


export default ({
  companiesTitle,
  companiesSubtitle,
  companyFilters,
  companiesByFilters,
  profilesTitle,
  profilesSubtitle,
  profilesFilters,
  profilesByFilters,
}, { sortValue, filterValues, sortConfig, filterConfig, directoryType: type }) => ({
  __typename: 'DirectoryBlock',
  filters: type === directoryType.COMPANY ? companyFilters : profilesFilters,
  title: type === directoryType.COMPANY ? companiesTitle : profilesTitle,
  subtitle: type === directoryType.COMPANY ? companiesSubtitle : profilesSubtitle,
  type,
  sortValue,
  filterValues,
  sortConfig,
  filterConfig,
  // Companies
  ...companiesByFilters?.data && companiesByFilters.data.length >= 1 && {
    companies: companiesByFilters.data?.map(CompanyCardModel),
    ...companiesByFilters.paginatorInfo && {
      pagination: {
        currentPage: companiesByFilters.paginatorInfo.currentPage,
        totalPage: companiesByFilters.paginatorInfo.lastPage,
        total: companiesByFilters.paginatorInfo.total,
        route: routes.pagePaginated.name,
        routeBase: routes.page.name,
      },
    },
  },
  ...profilesByFilters?.data && profilesByFilters.data.length >= 1 && {
    profiles: profilesByFilters.data?.map(ProfileCardModel),
    ...profilesByFilters.paginatorInfo && {
      pagination: {
        currentPage: profilesByFilters.paginatorInfo.currentPage,
        totalPage: profilesByFilters.paginatorInfo.lastPage,
        total: profilesByFilters.paginatorInfo.total,
        route: routes.pagePaginated.name,
        routeBase: routes.page.name,
      },
    },
  },
})
