import RelationCard from '../../data/models/RelationCard'
import routes from '../../routes'


export default ({ filters, relationMatchByFilters }, { filterConfig, filterValues }) => ({
  __typename: 'RelationMatchListBlock',
  filters,
  filterValues,
  filterConfig,
  ...relationMatchByFilters?.data && relationMatchByFilters?.data.length >= 1 && {
    relations: relationMatchByFilters.data.map(RelationCard),
    ...relationMatchByFilters.paginatorInfo && {
      pagination: {
        currentPage: relationMatchByFilters.paginatorInfo.currentPage,
        totalPage: relationMatchByFilters.paginatorInfo.lastPage,
        total: relationMatchByFilters.paginatorInfo.total,
        route: routes.pagePaginated.name,
        routeBase: routes.page.name,
      },
    },
  },
})
