import OfferCard from '../../data/models/OfferCard'
import routes from '../../routes'


export default ({ text, filters, offersByFilters }, { sortConfig, filterConfig, sortValue, filterValues }) => ({
  __typename: 'OffersResultsWrapper',
  ...text && {
    text,
  },
  filters,
  sortValue,
  filterValues,
  sortConfig,
  filterConfig,
  ...offersByFilters.data && offersByFilters.data.length >= 1 && {
    offers: offersByFilters.data.map(OfferCard),
    ...offersByFilters.paginatorInfo && {
      pagination: {
        currentPage: offersByFilters.paginatorInfo.currentPage,
        totalPage: offersByFilters.paginatorInfo.lastPage,
        total: offersByFilters.paginatorInfo.total,
        route: routes.pagePaginated.name,
        routeBase: routes.page.name,
      },
    },
  },
})
