import React from 'react'
import { useTranslation } from 'react-i18next'

import withMemo from '../../decorators/WithMemo'

import HighlightedEvent from './index'


function HighlightedEventWrapper({ highlightedEvent }) {
  const { t } = useTranslation()

  const data = {
    highlightedEvent: {
      ...highlightedEvent,
      ctaLabel: t('eventCardHighlight_knowMore'),
      highlightLabel: t('eventCardHighlight_label'),
    },
  }

  return (
    <HighlightedEvent
      {...data}
    />
  )
}

HighlightedEventWrapper.propTypes = HighlightedEvent.propTypes

HighlightedEventWrapper.defaultProps = HighlightedEvent.defaultProps

export default withMemo()(HighlightedEventWrapper)
