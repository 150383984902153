import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import MarkdownText from '../../../MarkdownText'
import ActionButton from '../../../ActionButton'
import withMemo from '../../../../decorators/WithMemo'

import styles from './styles'
import Argument from './Argument'


const useStyles = createUseStyles(styles)


const Arguments = (props) => {
  const classes = useStyles(props)
  const { className, argumentsList, ctaLink, ctaLabel } = props

  return (
    <div className={cx(classes.container, className)}>
      {argumentsList.map((argument, index) => (
        <Argument
          {...argument}
          key={index}
        />
      ))}
      <MarkdownText
        className={classes.text}
        text="Ne choisissez pas entre <strong>efficacité business</strong> et <strong>valeurs solidaires</strong>, développez une relation de confiance avec des partenaires locaux  ! "
        tag="h3"
      />
      <ActionButton
        className={classes.cta}
        label={ctaLabel}
        route={ctaLink}
      />
    </div>
  )
}

Arguments.propTypes = {
  className: PropTypes.string,
  argumentsList: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    img: PropTypes.string,
    imgPosition: PropTypes.string,
  })),
  ctaLink: PropTypes.string,
  ctaLabel: PropTypes.string,
}

Arguments.defaultProps = {
  className: null,
  argumentsList: [],
  ctaLink: null,
  ctaLabel: null,
}

export default withMemo()(Arguments)

