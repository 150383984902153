import { sizes, fonts, desktop, colors } from '../../theme'


export default {
  container: {
    width: '100%',
    maxWidth: `${sizes.desktop / 10}rem`,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    padding: '3rem 0',
  },
  text: {
    ...fonts.FiraSansRegular,
    color: colors.nightRider,
    fontSize: '1.6rem',
    lineHeight: '2.6rem',
    marginBottom: '2rem',
    padding: '0 2rem',
  },
  articles: {
    padding: 0,
  },
  emptyArticles: {
    ...fonts.FiraSansRegular,
    textAlign: 'center',
    padding: '2rem',
  },
  articlesList: {
    padding: '0 1rem',
    display: 'flex',
    flexFlow: 'row wrap',
  },
  articleCard: {
    margin: '0 1rem',
    marginBottom: '1.5rem',
    minWidth: '28rem',
    flex: 1,
    ...desktop({
      flex: 0,
      minWidth: '30rem',
    }),
  },
  featured: {
    minWidth: 'calc(100% - 2rem)',
    marginBottom: '2.5rem',
  },
  hidden: {
    display: 'flex',
  },
}
