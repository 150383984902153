import i18n from '../../i18n'


export default (props) => ({
  __typename: 'VideoBlock',
  ...props.video && {
    video: props.video,
  },
  mail: i18n.t('homepage_video_cta_link'),
  ctaLabel: i18n.t('homepage_video_ctaLabel'),
})
