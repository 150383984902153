import { filterCompanyTypeConfig } from '../../graphql/enums/filterCompanyType'
import { directoryTypeOptions } from '../../graphql/enums/DirectoryType'
import { filterTurnoverTypeConfig } from '../../graphql/enums/turnoverType'


/*
 pour entreprises :
 Le type d'adhérent Freemium/Premium/Ambassadeur (les ambassadeurs sont définis par la CCI)
 Le secteur d'activité
 Le domaine d'activité en fonction du secteur (cf liste)
 Le label environnemental : Lorsqu'un label RSE est renseigné
*/
/*
entreprises :
  Par ordre alphabétique
  Lieu (ordre alphabétique)
  Date d'inscription
 */
// COMPANY
export const companyPrefix = 'companiesResults'
export const companyFiltersConfig = (certifications, sectors, domains, t) => [
  {
    visible: false,
    isPath: true,
    name: 'type',
    slug: 'type',
    prefix: 'filterType',
    options: directoryTypeOptions(t),
  },
  {
    type: 'checkbox',
    isMulti: true,
    isPath: true,
    name: 'types',
    slug: 'company-type',
    prefix: 'filterCompanyType',
    options: filterCompanyTypeConfig,
  },
  {
    type: 'radio',
    isMulti: false,
    isPath: true,
    name: 'sector',
    slug: 'sector',
    prefix: 'filterSector',
    options: sectors?.map((certif) => ({
      label: certif.name,
      value: certif.id,
      name: certif.id,
      id: certif.id,
    })),
  },
  ...domains ? (
    [{
      type: 'checkbox',
      isMulti: true,
      isPath: true,
      name: 'domains',
      slug: 'domains',
      prefix: 'filterDomains',
      options: domains?.map((d) => ({
        label: d.name,
        value: d.id,
        name: d.id,
        id: d.id,
      })),
    }]) : [],
  {
    type: 'checkbox',
    isMulti: true,
    isPath: true,
    name: 'certifications',
    slug: 'certifications',
    prefix: 'filterCertificationsType',
    options: certifications?.map((certif) => ({
      label: certif.name,
      value: certif.id,
      name: certif.id,
      id: certif.id,
    })),
  },
  {
    type: 'checkbox',
    isMulti: true,
    isPath: true,
    name: 'turnovers',
    slug: 'turnovers',
    prefix: 'filterTurnovers',
    options: filterTurnoverTypeConfig,
  },
]
export const companySortConfig = [
  {
    name: 'pertinence',
    slug: 'par-pertinence',
    field: 'pertinence',
    order: 'DESC',
  },
  {
    name: 'name',
    slug: 'par-nom',
    field: 'name',
    order: 'ASC',
  },
  {
    name: 'location',
    slug: 'par-lieu',
    field: 'city',
    order: 'ASC',
  },
  {
    name: 'date',
    slug: 'par-date',
    field: 'created_at',
    order: 'DESC',
  },
]

// PROFILE
export const profilePrefix = 'profilesResults'
export const profileFiltersConfig = (companyFunctions, t) => [
  {
    visible: false,
    isPath: true,
    name: 'type',
    slug: 'type',
    prefix: 'filterType',
    options: directoryTypeOptions(t),
  },
  {
    type: 'checkbox',
    isMulti: true,
    isPath: true,
    name: 'company_functions',
    slug: 'role',
    prefix: 'filterProfileType',
    options: companyFunctions?.map((func) => ({
      label: func.name,
      value: func.id,
      name: func.id,
      id: func.id,
    })),
  },
]

export const profileSortConfig = [
  {
    name: 'name',
    slug: 'par-nom',
    field: 'last_name',
    order: 'ASC',
  },
  {
    name: 'date',
    slug: 'par-date',
    field: 'created_at',
    order: 'DESC',
  },
]



/*
pour membres :
Fonction dans l'entreprise (Cf ci-dessous, défini en BO)
  Direction Générale
  Achats
  Commercial
  marketing/communication
  Juridique
  Finance / Comptabilité
  Informatique
  Ressources Humaines / Formation
  Technique / Logistique / production
  Etude / Recherche / Développement
  Qualité / Sécurité / Environnement
 */
/*
membres :
  Par ordre alphabétique
  Date d'inscription
 */
