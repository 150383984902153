import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import MarkdownText from '../MarkdownText'

import styles from './styles'


const useStyles = createUseStyles(styles)

const PublicTitleTextContainer = (props) => {
  const {
    className,
    titleTexts,
    inline,
    greyBackground,
    textBlue,
  } = props

  const hasMultipleChildren = titleTexts.length > 1
  const classes = useStyles({ ...props, hasMultipleChildren: hasMultipleChildren && !inline, greyBackground, textBlue })

  return (
    <div className={cx(classes.container, className)}>
      {titleTexts.map((titleText, index) => (
        <div
          key={index}
          className={classes.titleTextContainer}
        >
          {titleText.title && (
            <p className={classes.title}>{titleText.title}</p>
          )}
          <MarkdownText
            text={titleText.text}
            className={classes.text}
          />
        </div>
      ))}
    </div>
  )
}

PublicTitleTextContainer.propTypes = {
  className: PropTypes.string,
  titleTexts: PropTypes.array,
  inline: PropTypes.bool,
  greyBackground: PropTypes.bool,
  textBlue: PropTypes.bool,
}

PublicTitleTextContainer.defaultProps = {
  className: '',
  titleTexts: [],
  inline: false,
  greyBackground: false,
  textBlue: false,
}

export default withMemo()(PublicTitleTextContainer)
