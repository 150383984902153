import PropTypes from 'prop-types'
import React from 'react'

import withMemo from '../../decorators/WithMemo'

import Countdown from './index'


function CountdownWrapper({ text, date, image, copyright, ctaLink, ctaTitle }) {
  const parsedDate = new Date(date)
  const countdownProps = {
    text,
    date: parsedDate.toString() === 'Invalid date' ? new Date() : parsedDate,
    image,
    copyright,
    legalsLabel: ctaTitle,
    legalsLink: ctaLink,
  }

  return (
    <Countdown
      {...countdownProps}
    />
  )
}

CountdownWrapper.propTypes = {
  text: PropTypes.string,
  date: PropTypes.string,
  image: PropTypes.string,
  copyright: PropTypes.string,
  ctaLink: PropTypes.string,
  ctaTitle: PropTypes.string,
}

CountdownWrapper.defaultProps = {
  text: '',
  date: '',
  image: '',
  copyright: '',
  ctaLink: '',
  ctaTitle: '',
}

export default withMemo()(CountdownWrapper)
