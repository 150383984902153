/* eslint-disable max-lines */
import { colors, desktop, fonts, sizes, svgGlobal, tablet } from '../../theme'


export default {
  container: {
    color: colors.nightRider,
    zIndex: 1,
    padding: '0 2rem',
    ...desktop({
      padding: '0',
    }),
  },
  wrapper: {
    zIndex: 100,
    backgroundColor: colors.pureWhite,
    maxWidth: `${sizes.desktop / 10}rem`,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: '1rem',
    padding: '2rem 1rem',
    ...tablet({
      padding: '2rem',
      borderRadius: '2rem',
    }),
  },
  title: {
    ...fonts.FiraSansBold,
    fontSize: '2rem',
    color: colors.tangerine,
    marginBottom: '1rem',
    ...tablet({
      fontSize: '2.4rem',
    }),
  },
  subtitle: {
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    color: colors.nightRider,
    marginBottom: '1rem',
    ...tablet({
      fontSize: '1.5rem',
    }),
  },
  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: colors.gainsboro,
    marginBottom: '1rem',
  },
  tableHeader: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: '2rem',
  },
  typeTitle: {
    '& p': {
      ...fonts.FiraSansRegular,
      fontSize: '1.8rem',
      color: colors.darkBlue,
      lineHeight: '120%',
      textTransform: 'uppercase',
      ...tablet({
        fontSize: '3.2rem',
      }),
    },
  },
  typeSelector: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '3rem',
  },
  typeSelectorItem: {
    marginRight: '2rem',
  },
  typeSelectorLabel: {
    ...fonts.FiraSansBold,
    fontSize: '1.4rem',
    color: colors.nightRider,
    textTransform: 'uppercase',
    marginBottom: '1rem',
    ...tablet({
      fontSize: '2rem',
    }),
  },
  premiumLabel: {
    color: colors.darkBlue,
  },
  cta: {},
  table: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
  },
  tablePart: {
    width: '100%',
    '&:not(:last-child)': {
      marginBottom: '3rem',
    },
  },
  tableTitle: {
    ...fonts.FiraSansBold,
    fontSize: '1.6rem',
    color: colors.tangerine,
    marginBottom: '1rem',
    ...tablet({
      fontSize: '2rem',
    }),
  },
  tableRows: {
  },
  tableRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 0 1rem 0',
  },
  tableRowLabel: {
    ...fonts.FiraSansBold,
    fontSize: '1.2rem',
    color: colors.nightRider,
    ...tablet({
      fontSize: '1.4rem',
    }),
  },
  tableRowIcons: {
    ...tablet({
      width: '20rem',
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      '& span:first-child': {
        marginRight: '3.5rem',
      },
    }),
  },
  icon: {
    ...svgGlobal(colors.brightNavyBlue),
    width: '3.2rem',
    height: '3.2rem',
    marginRight: '1rem',
    '& svg': {
      width: '3.2rem',
      height: '3.2rem',
    },
    ...tablet({
      width: '4.8rem',
      height: '4.8rem',
      '& svg': {
        width: '4.8rem',
        height: '4.8rem',
      },
    }),
  },
  typeBottomSelector: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  pushMeeting: {
    width: '100%',
    textAlign: 'center',
  },
  pushMeetingTitle: {
    ...fonts.FiraSansBold,
    fontSize: '2rem',
    color: colors.tangerine,
    marginTop: '2rem',
    marginBottom: '3rem',
    ...tablet({
      fontSize: '2.4rem',
    }),
  },
  pushMeetingCta: {
    marginBottom: '5rem',
  },
}
