import { postTypeConfig } from '../../graphql/enums/postType'


export const postsPrefix = 'postsResults'

export const postsFiltersConfig = [
  {
    type: 'checkbox',
    isMulti: true,
    isPath: true,
    name: 'types',
    slug: 'type',
    prefix: 'postType',
    options: postTypeConfig,
  },
]
