export const articleType = {
  ARTICLE_THEME_BRIEF: 'ARTICLE_THEME_BRIEF',
  ARTICLE_THEME_FURTHER: 'ARTICLE_TYPE_FURTHER',
  ARTICLE_THEME_TERRITORY: 'ARTICLE_TYPE_TERRITORY',
  ARTICLE_THEME_PORTRAIT: 'ARTICLE_TYPE_PORTRAIT',
}

export const articleTypeArray = [
  articleType.ARTICLE_THEME_BRIEF,
  articleType.ARTICLE_THEME_FURTHER,
  articleType.ARTICLE_THEME_TERRITORY,
  articleType.ARTICLE_THEME_PORTRAIT,
]

export const articleTypeSlugs = {
  [articleType.ARTICLE_THEME_BRIEF]: 'en-bref',
  [articleType.ARTICLE_THEME_FURTHER]: 'aller-plus-loin',
  [articleType.ARTICLE_THEME_TERRITORY]: 'actu-territoire',
  [articleType.ARTICLE_THEME_PORTRAIT]: 'portrait',
}

export const articleTypeConfig = articleTypeArray.map((value) => ({
  key: `articleTheme_${value}`,
  id: value,
  value: articleTypeSlugs[value],
}))

export const articleTypeOptions = (t) => articleTypeArray.map((value) => ({
  label: t(`articleTheme_${value}`),
  value,
}))

export const ArticleTypes = {
  ARTICLE_THEME_BRIEF: 'BREF',
  ARTICLE_THEME_FURTHER: 'IMAGE',
  ARTICLE_THEME_TERRITORY: 'IMAGE',
  ARTICLE_THEME_PORTRAIT: 'PORTRAIT',
}
