/* eslint-disable max-lines */
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import withMemo from '../../decorators/WithMemo'
import {
  getFilterOptions,
  getPagination,
  getSortOptions,
  parseQueryParams,
  buildQueryWithSlug
} from '../../helpers/FilterHelpers'
import { selectors as AppSelectors } from '../../redux/app/redux'
import { selectors as authSelectors } from '../../redux/auth/redux'
import routes, { Router } from '../../routes'
import { colors } from '../../theme'
import { iconsKeys } from '../Icon/Icon.assets'
import PushPremiumWrapper from '../PushPremium/wrapper'

import { offersPrefix } from './config'
import OffersResults from './index'


function OffersResultsWrapper(props) {
  const { t } = useTranslation()
  const route = useSelector(AppSelectors.route)
  const isPremium = useSelector(authSelectors.isActivePremium)
  const isOGCN = useSelector(authSelectors.isOGCN)
  const {
    filters,
    offers, pagination,
    pushPremium, sortValue, filterValues, sortConfig,
    filterConfig,
  } = props

  const handleSubmit = useCallback((p, resetPage = false) => {
    const href = buildQueryWithSlug(route, filterConfig, pagination, p, resetPage)

    Router.pushRoute(href)
  }, [route, filterConfig, pagination])

  const params = {
    ...filters,
    ...parseQueryParams(route?.asPath, route?.slug),
  }

  const filterOptions = useMemo(() => getFilterOptions(t, filterConfig, offersPrefix), [filterConfig, t])

  const newFilterOptions = isOGCN
    ? filterOptions
    : [{ ...filterOptions[0], options: filterOptions[0].options.filter((o) => o.value !== 'ogcn') },
      filterOptions[1],
      filterOptions[2],
      filterOptions[3],
      filterOptions[4],
    ]

  const sortOptions = useMemo(() => getSortOptions(t, sortConfig, offersPrefix), [sortConfig, t])

  // Pagination params
  const paginationProps = useMemo(() => {
    const p = getPagination(pagination, route?.asPath, route?.query, params)

    return p
  }, [pagination, params, route])

  const data = {
    ...props,
    title: props.text,
    sortBoxProps: {
      placeholder: t('offersResults_sortPlaceholder'),
      options: sortOptions,
      value: sortValue,
    },
    filterBoxProps: {
      placeholder: t('offersResults_filterPlaceholder'),
      filters: newFilterOptions,
      values: filterValues,
    },
    entries: offers?.map((offer) => ({
      ...offer,
      ctaLabel: t('offerCardKnowMore'),
    })),
    handleSubmit,
    emptyText: t('offersResults_emptyLabel'),
    pagination: paginationProps,
    pushPublishProps: {
      text: t('offersResults_pushPublishText'),
      ctaLabel: t('offersResults_pushPublishCta'),
      ctaLink: Router.getRouteUrl(routes.userPublishOffer, {}),
      ctaRoute: routes.userPublishOffer.name,
      ctaRouteParams: { },
      ctaProps: {
        icon: iconsKeys.SmallArrowRight,
        iconRight: true,
        colorIcon: colors.pureWhite,
        colorIconHover: colors.darkBlue,
      },
      isCard: false,
      hasModal: true,
      modalTitle: t('offersResults_pushPublish_modal_title'),
      modalText: t('offersResults_pushPublish_modal_text'),
      modalCta: t('offersResults_pushPublish_modal_cta'),
    },
  }

  return (
    <OffersResults
      {...data}
      pushPremium={!isPremium && pushPremium && <PushPremiumWrapper />}
      isCurrentOGCN={isOGCN}
    />
  )
}

OffersResultsWrapper.propTypes = OffersResults.propTypes

OffersResultsWrapper.defaultProps = OffersResults.defaultProps

export default withMemo()(OffersResultsWrapper)
