export default (props) => ({
  __typename: 'Partners',
  ...props.title && {
    title: props.title,
  },
  ...props.partners && props.partners.data && props.partners.data.length >= 1 && {
    cards: props.partners.data.map((partner) => ({
      id: partner.id,
      image: partner.logo,
      link: partner.url,
    })),
  },
})
