import { sizes, fonts, desktop, colors, tablet } from '../../theme'


export default {
  container: {
    backgroundColor: colors.nightRider,
  },
  header: {
    paddingTop: ({ isConnected }) => (isConnected ? '18rem' : '3rem'),
    ...tablet({
      paddingTop: ({ isConnected }) => (isConnected ? '11rem' : '3rem'),
    }),
    ...desktop({
      marginTop: 0,
    }),
  },
  title: {
    ...fonts.FiraSansRegular,
    color: colors.pureWhite,
    fontSize: '2.4rem',
    lineHeight: '120%',
    textTransform: 'uppercase',
    margin: '0',
    padding: '0 2rem 3rem',
    textAlign: 'center',
  },
  subtitle: {
    ...fonts.FiraSansRegular,
    color: colors.pureWhite,
    fontSize: '1.5rem',
    lineHeight: '120%',
    padding: '0 2rem 3rem',
    textAlign: 'center',
  },
  filterBlock: {
  },
  content: {
    backgroundColor: colors.honeydew,
  },
  wrapper: {
    width: '100%',
    maxWidth: `${sizes.desktop / 10}rem`,
    margin: '0 auto',
    display: ({ containerNoFlex }) => (containerNoFlex ? 'block' : 'flex'),
    flexDirection: 'column',
    padding: '3rem 0',
  },
  wrapperNoFlex: {
    width: '100%',
    maxWidth: `${sizes.desktop / 10}rem`,
    margin: '0 auto',
    padding: '3rem 0',
  },
  filters: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0 1rem',
    marginBottom: '3rem',
    zIndex: 3,
  },
  results: {
    padding: 0,
  },
  noResults: {
    ...fonts.FiraSansRegular,
    textAlign: 'center',
    padding: '2rem',
  },
  list: {
    padding: '0 1rem',
    display: 'flex',
    flexFlow: 'row wrap',
  },
  card: {
    margin: '0 1rem',
    marginBottom: '2rem',
    minWidth: '28rem',
    flex: 1,
    ...desktop({
      flex: 0,
      minWidth: '30rem',
    }),
  },
  hidden: {
    display: 'flex',
  },
  register: {
    margin: '0 1rem',
  },
}
