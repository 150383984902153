import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import MarkdownText from '../MarkdownText'

import styles from './styles'


const useStyles = createUseStyles(styles)

const LoginLayout = (props) => {
  const classes = useStyles(props)
  const {
    className,
    mainContent,
    sideContent,
    title,
  } = props

  return (
    <div className={cx(classes.container, className)}>
      {Boolean(title) && (
        <div className={cx(classes.background)} />
      )}
      <div className={cx(classes.wrapper)}>
        {Boolean(title) && (
          <MarkdownText
            text={title}
            className={classes.title}
            tag="h1"
            inline
          />
        )}
        <div className={classes.content}>
          <div className={cx(classes.main, !sideContent && classes.solo)}>
            {mainContent}
          </div>
          {sideContent && (
            <div className={classes.side}>
              {sideContent}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

LoginLayout.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  mainContent: PropTypes.node.isRequired,
  sideContent: PropTypes.node,
}

LoginLayout.defaultProps = {
  className: '',
  title: '',
  sideContent: null,
}

export default LoginLayout
