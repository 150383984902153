import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { actions as AuthActions, selectors as AuthSelectors } from '../../redux/auth/redux'
import { selectors as PageSelectors } from '../../pages/Page/redux'
import LoginLayout from '../LoginLayout/LoginLayout'
import { getFieldTranslations } from '../../helpers/FormHelpers'

import LoginResetForm from './index'


function LoginResetWrapper(props) {
  const { t } = useTranslation()

  const registerSelector = useSelector(AuthSelectors.reset)
  const submitErrors = registerSelector?.errors

  const title = useSelector(PageSelectors.data)?.title

  const dispatch = useDispatch()

  const handleSubmit = (values) => dispatch(AuthActions.reset(values))

  const loginProps = {
    label: t('reset_submit_label'),
    fieldsProps: {
      email: getFieldTranslations(t, 'reset_email'),
      password: getFieldTranslations(t, 'reset_password'),
    },
    messages: {
      required: t('form_validation_required'),
      email: t('form_validation_email'),
      password: t('form_validation_password'),
    },
  }

  return (
    <LoginLayout
      title={title}
      mainContent={(
        <LoginResetForm
          {...loginProps}
          onSubmit={handleSubmit}
          submitErrors={submitErrors}
        />
      )}
    />

  )
}

LoginResetWrapper.propTypes = {}

LoginResetWrapper.defaultProps = {}

export default LoginResetWrapper
