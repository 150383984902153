import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import types from '../OnBoarding/types'
import RegisterForm from '../RegisterForm'

import styles from './styles'


const useStyles = createUseStyles(styles)

function ManagerRegisterForm(props) {
  const {
    className,
    label,
    disclaimerLabel,
    initialValues,
    fieldsProps,
    onSubmit,
    messages,
    errors,
    submitErrors,
  } = props

  let allErrors = []

  if (Array.isArray(errors)) {
    allErrors = allErrors.concat(errors)
  }

  if (Array.isArray(submitErrors)) {
    allErrors = allErrors.concat(submitErrors)
  }

  const classes = useStyles(props)

  return (
    <div className={cx(classes.container, className)}>
      <div className={cx(classes.background)} />
      <div className={cx(classes.wrapper)}>
        <RegisterForm
          onSubmit={onSubmit}
          initialValues={initialValues}
          messages={messages}
          fieldsProps={fieldsProps}
          errors={allErrors}
          label={label}
          disclaimerLabel={disclaimerLabel}
          type={types.PREMIUM}
          isManager
        />
      </div>
    </div>
  )
}

ManagerRegisterForm.styles = styles

ManagerRegisterForm.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  disclaimerLabel: PropTypes.string,
  fieldsProps: RegisterForm.propTypes.fieldsProps,
  initialValues: RegisterForm.propTypes.initialValues,
  messages: RegisterForm.propTypes.messages,
  errors: RegisterForm.propTypes.errors,
  submitErrors: RegisterForm.propTypes.errors,
  onSubmit: PropTypes.func,
}

ManagerRegisterForm.defaultProps = {
  className: '',
  label: '',
  disclaimerLabel: '',
  fieldsProps: RegisterForm.defaultProps.fieldsProps,
  initialValues: RegisterForm.defaultProps.initialValues,
  messages: RegisterForm.defaultProps.messages,
  errors: RegisterForm.defaultProps.errors,
  submitErrors: null,
  onSubmit: () => null,
}

export default withMemo()(ManagerRegisterForm)
