import PropTypes from 'prop-types'


const RelationCard = (props) => ({
  id: props.id,
  avatar: props.avatar || '/images/default-avatar.png',
  name: `${props.first_name} ${props.last_name}`,
  companyFunction: props.companyFunction,
  company: props.company,
  matchingDomains: props.domains,
  companyDomain: props.companyDomain,
  percentage: props.percentage || 0,
})

RelationCard.propTypes = PropTypes.shape({
  id: PropTypes.string,
  avatar: PropTypes.string,
  name: PropTypes.string,
  companyFunction: PropTypes.string,
  company: PropTypes.string,
  matchingDomains: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      sector: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    })
  ),
  companyDomain: PropTypes.shape({
    text: PropTypes.string,
    icon: PropTypes.string,
    color: PropTypes.string,
  }),
  contactButton: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.string,
    iconRight: PropTypes.bool,
  }),
  percentage: PropTypes.number,
})

export default RelationCard
