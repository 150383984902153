export default (props) => ({
  __typename: 'PushContent',
  ...props.background_image && {
    backgroundImage: props.background_image,
  },
  ...props.header_title && {
    headerTitle: props.header_title,
  },
  ...props.title && {
    title: props.title,
  },
  ...props.description && {
    description: props.description,
  },
  ...props.cta_title && {
    ctaLabel: props.cta_title,
  },
  ...props.cta_link && {
    ctaLink: props.cta_link,
  },
})
