import PropTypes from 'prop-types'

import routes, { Router } from '../../routes'
import i18n from '../../i18n'
import { ArticleTypes } from '../../graphql/enums/articleType'


const Article = (props) => {
  if (!props) {
    return null
  }

  return {
    title: props.title,
    description: props.description,
    image: props.picture,
    link: Router.getRouteUrl(routes.article, { slug: props.slug }),
    isHighlighted: props.is_highlighted,
    labelButton: props.category.theme === 'ARTICLE_THEME_PORTRAIT' ? i18n.t('articleCard_readMore_interview') : i18n.t('articleCard_readMore_article'),
    category: {
      name: props.category.name,
      theme: props.is_highlighted ? 'FEATURED' : ArticleTypes[props.category.theme],
      bgColor: props.category.bgColor,
    },
    featuredLabel: i18n.t('article_HighlightedLabel'),
  }
}

Article.propTypes = PropTypes.shape({
  id: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string,
  category: PropTypes.shape({
    theme: PropTypes.string,
    name: PropTypes.string,
    bg_color: PropTypes.string,
  }),
})

export default Article
