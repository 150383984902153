import { colors, fonts, tablet } from '../../theme'


export default {
  container: {},
  fields: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    ...tablet({
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    }),
  },
  field: {
    padding: '2rem',
    width: '100%',
  },
  fieldNotManager: {
    ...tablet({
      width: '50%',
    }),
  },
  checkboxContainer: {
    width: '100%',
    marginBottom: '1rem',
  },
  errors: {
    textAlign: 'center',
    padding: '1.5rem',
  },
  error: {
    color: colors.error,
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    padding: '0.5rem 0',
  },
  submitContainer: {
    textAlign: 'center',
    paddingTop: '1.5rem',
  },
  button: {},
  linkedinConnect: {
    margin: '2rem 0',
    backgroundColor: colors.pureWhite,
  },
  disclaimer: {
    textAlign: 'justify',
    color: colors.nightRider,
    ...fonts.FiraSansRegular,
    fontSize: '1.2rem',
    padding: '0.5rem 0',
    margin: '0.2rem 4.5rem 2rem',
  },
}
