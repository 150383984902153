import { colors, fonts, sizes, tablet } from '../../theme'


export default {
  container: {
    backgroundColor: colors.honeydew,
    padding: '0 2rem',
    ...tablet({
      padding: 0,
    }),
  },
  wrapper: {
    maxWidth: `${sizes.desktop / 10}rem`,
    margin: '0 auto',
    padding: '3rem 0',
  },
  title: {
    ...fonts.FiraSansBold,
    fontSize: '2.4rem',
    color: colors.darkBlue,
    marginBottom: '3rem',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      marginBottom: '1rem',
    },
  },
}
