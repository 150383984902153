import React from 'react'
import { useSelector } from 'react-redux'

import { selectors as AuthSelectors } from '../../redux/auth/redux'

import EventPublic from './index'


function EventPublicWrapper(props) {
  const isPremium = useSelector(AuthSelectors.isActivePremium)
  const isConnected = useSelector(AuthSelectors.isConnected)

  const data = {
    ...props,
    isConnected,
  }

  if (isPremium) {
    return null
  }

  return <EventPublic {...data} />
}

EventPublicWrapper.propTypes = EventPublic.propTypes

EventPublicWrapper.defaultProps = EventPublic.defaultProps

export default EventPublicWrapper
