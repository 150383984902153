import PropTypes from 'prop-types'
import React from 'react'

import withMemo from '../../decorators/WithMemo'
import EventCardHighlight from '../EventCardHighlight'


const HighlightedEvent = (props) => {
  const { highlightedEvent } = props

  return (
    <EventCardHighlight
      {...highlightedEvent}
    />
  )
}

HighlightedEvent.propTypes = {
  className: PropTypes.string,
  highlightedEvent: PropTypes.shape(EventCardHighlight.propTypes),
}

HighlightedEvent.defaultProps = {
  className: '',
  highlightedEvent: null,
}

export default withMemo()(HighlightedEvent)
