import React, { useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import ActionButton from '../ActionButton'
import PublicTitleTextContainer from '../PublicTitleTextContainer'
import MarkdownText from '../MarkdownText'
import { app } from '../../config'

import styles from './styles'


const useStyles = createUseStyles(styles)

const OfferPublic = (props) => {
  const {
    className,
    title,
    introductionBeforeBlue,
    introductionBlue,
    introductionAfterBlue,
    premium_title,
    premium_text,
    ctaLink,
    ctaLabel,
    titleTextContainers,
    isConnected,
  } = props

  const [showingMore, setShowingMore] = useState(false)
  const classes = useStyles({ ...props, showingMore })

  const showLess = () => setShowingMore(false)
  const showMore = () => setShowingMore(true)

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.containerWhite}>
        <div className={classes.subcontainer}>
          <MarkdownText
            className={classes.title}
            text={title}
          />
          {introductionBeforeBlue && (
            <MarkdownText
              className={classes.text}
              text={introductionBeforeBlue}
            />
          )}
          {introductionBlue && (
            <MarkdownText
              className={classes.textBlue}
              text={introductionBlue}
            />
          )}
          {introductionAfterBlue && (
            <MarkdownText
              className={classes.text}
              text={introductionAfterBlue}
            />
          )}
        </div>
      </div>
      <div className={classes.containerGrey}>
        <div className={classes.subcontainer}>
          <h1 className={classes.premiumTitle}>{premium_title}</h1>
          {premium_text && (
            <p className={classes.text}>{premium_text}</p>
          )}
          {ctaLabel && ctaLink && (
            <ActionButton
              className={classes.cta}
              label={ctaLabel}
              link={isConnected ? app.REGISTER_URL : `${ctaLink}?type=premium`}
            />
          )}
          {titleTextContainers && titleTextContainers.length && (
            <>
              {showingMore && titleTextContainers.map((titleTextContainer, index) => (
                <PublicTitleTextContainer
                  className={classes.titleTextContainer}
                  titleTexts={titleTextContainer.titleTexts}
                  key={index}
                />
              ))}
              <div className={classes.showMoreContainer}>
                <ActionButton
                  className={classes.showMore}
                  label={showingMore ? 'Voir moins' : 'Voir plus'}
                  onClick={showingMore ? showLess : showMore}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

OfferPublic.propTypes = {
  className: PropTypes.string,
  isConnected: PropTypes.bool,
  title: PropTypes.string.isRequired,
  introductionBeforeBlue: PropTypes.string,
  introductionBlue: PropTypes.string,
  introductionAfterBlue: PropTypes.string,
  premium_title: PropTypes.string.isRequired,
  premium_text: PropTypes.string,
  ctaLink: PropTypes.string,
  ctaLabel: PropTypes.string,
  titleTextContainers: PropTypes.array,
}

OfferPublic.defaultProps = {
  className: '',
  isConnected: false,
  introductionBeforeBlue: null,
  introductionBlue: null,
  introductionAfterBlue: null,
  premium_text: null,
  ctaLink: null,
  ctaLabel: null,
  titleTextContainers: [],
}

export default withMemo()(OfferPublic)
