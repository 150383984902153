export const eventType = {
  EVENT_EXHIBITION: 'EVENT_EXHIBITION',
  EVENT_CONFERENCE: 'EVENT_CONFERENCE',
  EVENT_NETWORKING: 'EVENT_NETWORKING',
  EVENT_BUSINESS_CONVENTION: 'EVENT_BUSINESS_CONVENTION',
  EVENT_BUSINESS_PLACE: 'EVENT_BUSINESS_PLACE',
  EVENT_OGCN: 'EVENT_OGCN',
  EVENT_FORMATION: 'EVENT_FORMATION',
}

export const eventTypeArray = [
  eventType.EVENT_EXHIBITION,
  eventType.EVENT_CONFERENCE,
  eventType.EVENT_NETWORKING,
  eventType.EVENT_BUSINESS_CONVENTION,
  eventType.EVENT_BUSINESS_PLACE,
  eventType.EVENT_OGCN,
  eventType.EVENT_FORMATION,
]

export const eventTypeSlugs = {
  [eventType.EVENT_EXHIBITION]: 'exhibition',
  [eventType.EVENT_CONFERENCE]: 'conference',
  [eventType.EVENT_NETWORKING]: 'networking',
  [eventType.EVENT_BUSINESS_CONVENTION]: 'business-convention',
  [eventType.EVENT_BUSINESS_PLACE]: 'business-place',
  [eventType.EVENT_OGCN]: 'ogcn',
  [eventType.EVENT_FORMATION]: 'formation',
}

export const eventTypeConfig = eventTypeArray.map((value) => ({
  key: `eventType_${value}`,
  id: value,
  value: eventTypeSlugs[value],
}))

export const eventTypeOptions = (t) => eventTypeArray.map((value) => ({
  label: t(`eventType_${value}`),
  value,
}))
