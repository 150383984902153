import { routeParamSlug } from '../../helpers/UrlHelpers'
import routes from '../../routes'
import i18n from '../../i18n'


export default (props) => ({
  __typename: 'RelationHeroBlock',
  ...props.title && {
    title: props.title,
  },
  ...props.text && {
    text: props.text,
  },
  ...props.img && {
    image: props.img,
  },
  ...props.routable && (props.routable?.__typename === 'Page' ? {
    cta: {
      label: i18n.t('push_relation_search_cta_label'),
      route: routes.page.name,
      routeParams: {
        slug: routeParamSlug(props.routable.full_slug),
      },
    } } : {
    route: routes.home.name,
  }),
})
