import { fonts, colors, desktop } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    paddingTop: '0 !important',
    lineHeight: '2.7rem',
  },
  containerWhite: {
    background: colors.pureWhite,
  },
  subcontainer: {
    maxWidth: '98rem',
    margin: '0 2rem',
    paddingTop: '4rem',
    paddingBottom: '4rem',
    ...desktop({
      margin: '0 auto',
    }),
  },
  title: {
    '& > p': {
      fontFamily: fonts.FiraSansBold.fontFamily,
      fontWeight: fontWeights.bold,
      fontSize: '2.4rem',
      lineHeight: '2.6rem',
      color: colors.darkBlue,
      marginBottom: '4rem',
      '& strong': {
        color: colors.primary,
      },
    },
  },
  text: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontSize: '1.5rem',
    marginBottom: '1rem',
  },
  cta: {
    marginBottom: '3rem',
  },
  titleTextContainer: {
    marginTop: '2rem',
  },
  showMoreContainer: {
    textAlign: 'left',
    marginTop: '2rem',
  },
  showMore: {
    fontWeight: fontWeights.bold,
    position: 'relative',
    background: 'none',
    borderRadius: '2rem',
    border: `.1rem solid ${colors.darkBlue}`,
    padding: '1.1rem 4rem 1rem 1.5rem',
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    textAlign: 'left',
    color: colors.darkBlue,
    '&:hover': {
      background: 'none',
      border: `.1rem solid ${colors.darkBlue}`,
    },
    '&:after': {
      transition: 'all .2s ease',
      transform: ({ showingMore }) => (showingMore ? 'rotate(180deg)' : ''),
      transformOrigin: 'center center',
      content: '""',
      position: 'absolute',
      borderStyle: 'solid',
      borderWidth: '6px 5px 0 5px',
      borderColor: `${colors.darkBlue} transparent transparent transparent`,
      borderRadius: 3,
      top: 0,
      right: '1.8rem',
      bottom: 0,
      width: 10,
      height: 6,
      margin: 'auto',
    },
  },
}
