import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import A from '../A'
import withMemo from '../../decorators/WithMemo'
import CloudinaryImage from '../CloudinaryImage'
import { mergeStyles } from '../../utils/StyleUtils'

import styles from './styles'


const useStyles = createUseStyles(styles)

const ImageBlock = (props) => {
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, props.classes), [classesComp, props.classes])

  const { image, image_link, route, routeParams } = props

  const { t } = useTranslation()

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <A
          href={image_link}
          route={route}
          routeParams={routeParams}
          target={image_link ? '_blank' : null}
          className={classes.link}
        >
          <CloudinaryImage
            id={image}
            className={classes.image}
            alt={t('SEO_image') + image_link}
          />
        </A>
      </div>
    </div>
  )
}

ImageBlock.propTypes = {
  image: PropTypes.string,
  route: PropTypes.string,
  routeParams: PropTypes.shape({
    slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
  image_link: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
}

ImageBlock.defaultProps = {
  image: null,
  route: null,
  routeParams: null,
  image_link: null,
  classes: null,
}

export default withMemo()(ImageBlock)
