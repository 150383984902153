import { colors, desktop, fonts, sizes, tablet } from '../../../../theme'
import { minHeight } from '../../styles'


export default {
  container: {
    width: '100%',
    height: '100%',
    ...minHeight,
    backgroundColor: colors.aliceBlue,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  box: {
    position: 'relative',
    width: '100%',
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 0,
    width: '100%',
    height: '100%',
  },
  backgroundEntreprise: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    transition: 'opacity 0.5s ease',
    maxHeight: '100vh',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #333333 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))',
    zIndex: 2,
    maxHeight: '100vh',
  },
  backgroundColored: {
    position: 'absolute',
    top: 'calc(100vh - 6.4rem)',
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    backgroundColor: colors.nightRider,
    zIndex: 0,
  },
  wrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    ...minHeight,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    padding: '0 2rem',
    ...desktop({
      maxWidth: `${sizes.desktop / 10}rem`,
      margin: 'auto',
      padding: 0,
    }),
  },
  title: {
    ...fonts.FiraSansRegular,
    color: colors.pureWhite,
    fontSize: '2.4rem',
    lineHeight: '120%',
    textAlign: 'center',
    textTransform: 'uppercase',
    margin: '3rem 0 1rem 0',
    ...tablet({
      ...fonts.FiraSansRegular,
      fontSize: '3.2rem',
    }),
  },
  subtitle: {
    ...fonts.FiraSansRegular,
    color: colors.pureWhite,
    fontSize: '1.5rem',
    lineHeight: '100%',
    textAlign: 'center',
    ...tablet({
      ...fonts.FiraSansRegular,
      fontSize: '1.8rem',
    }),
  },
  blocks: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '3rem 0 0 0',
    ...tablet({
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: '17rem 0 0 0',
    }),
  },
  subscriptionCards: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '3rem',
    '& > div': {
      marginBottom: '2rem',
    },
    ...tablet({
      flexDirection: 'row',
      '& > div': {
        marginBottom: '0',
      },
      '& > div:first-child': {
        marginRight: '1rem',
      },
      '& > div:last-child': {
        marginLeft: '1rem',
      },
    }),
  },
  subscriptionTable: {
    marginBottom: '2rem',
    zIndex: 1,
  },
  pushRegisterPremiumContainer: {
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '4rem',
  },
  pushRegisterPremiumWrapper: {
    backgroundColor: colors.pureWhite,
    padding: '2rem',
    borderRadius: '2rem',
  },
  pushRegisterPremiumTitle: {
    ...fonts.FiraSansBold,
    fontSize: '2rem',
    color: colors.tangerine,
    marginBottom: '3rem',
    ...tablet({
      fontSize: '2.4rem',
    }),
  },
}
