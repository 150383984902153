import { colors, fonts, tablet } from '../../theme'


export default {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: colors.pureWhite,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '94rem',
    alignItems: 'flex-start',
    textAlign: 'left',
    padding: '4rem 2rem',
  },
  title: {
    ...fonts.FiraSansBold,
    fontSize: '2rem',
    lineHeight: '2.6rem',
    margin: '2rem 0 5rem',
    width: '100%',
    color: colors.nightRider,
    '& strong': {
      color: colors.darkBlue,
    },
  },
  cards: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    ...tablet({
      display: 'grid',
      gridGap: '3.5rem',
      gridTemplateColumns: '29rem 29rem 29rem',
    }),
  },
  card: {
    marginBottom: '2rem',
    width: '100%',
    boxShadow: '0px 5px 30px rgba(84, 84, 84, 0.1)',
  },
}
