import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../../../decorators/WithMemo'
import ActionButton from '../../../ActionButton'
import CloudinaryImage from '../../../CloudinaryImage'
import MarkdownText from '../../../MarkdownText'
import SubscriptionCard from '../../../SubscriptionCard'
import SubscriptionPlanTableWrapper from '../../../SubscriptionPlanTable/wrapper'

import styles from './styles'


const useStyles = createUseStyles(styles)

function OnBoardingIntro(props) {
  const {
    className,
    title,
    subtitle,
    background,
    onNext,
    subscriptionCardFreemium,
    subscriptionCardPremium,
    onBoardingTableProps,
    isConnected,
    pushRegisterPremium,
  } = props

  const classes = useStyles({ ...props })

  const handleNextClick = (t) => {
    onNext(t)
  }

  const renderBackground = (
    <div className={cx(classes.background)}>
      <div className={classes.backgroundEntreprise}>
        <CloudinaryImage
          id={background}
          covered
          lazyLoad
        />
      </div>
      <div className={classes.overlay} />
      <div className={classes.backgroundColored} />
    </div>
  )

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.box}>
        {renderBackground}
        <div className={cx(classes.wrapper)}>
          <MarkdownText
            text={title}
            className={classes.title}
            tag="h1"
            inline
          />
          <MarkdownText
            className={classes.subtitle}
            text={subtitle}
            inline
            tag="span"
          />
          <div className={classes.blocks}>
            {!isConnected && (
              <div className={classes.subscriptionCards}>
                {subscriptionCardFreemium && (
                  <SubscriptionCard
                    {...subscriptionCardFreemium}
                    onTypeClick={handleNextClick}
                  />
                )}
                {subscriptionCardPremium && (
                  <SubscriptionCard
                    {...subscriptionCardPremium}
                    onTypeClick={handleNextClick}
                  />
                )}
              </div>
            )}
            {onBoardingTableProps && (
              <SubscriptionPlanTableWrapper
                {...onBoardingTableProps}
                show
              />
            )}
            <div className={classes.pushRegisterPremiumContainer}>
              <div className={classes.pushRegisterPremiumWrapper}>
                <div className={classes.pushRegisterPremiumTitle}>
                  {pushRegisterPremium.title}
                </div>
                <ActionButton
                  label={pushRegisterPremium.ctaLabel}
                  route={pushRegisterPremium.ctaRoute}
                />
              </div>
            </div>
            {onBoardingTableProps && (
              <SubscriptionPlanTableWrapper
                {...onBoardingTableProps}
                show
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

OnBoardingIntro.styles = styles

OnBoardingIntro.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  background: PropTypes.string,
  subtitle: PropTypes.string,
  type: PropTypes.string,
  onNext: PropTypes.func,
  subscriptionCardFreemium: PropTypes.shape(SubscriptionCard.PropTypes),
  subscriptionCardPremium: PropTypes.shape(SubscriptionCard.PropTypes),
  onBoardingTableProps: PropTypes.shape(SubscriptionPlanTableWrapper.propTypes),
  isConnected: PropTypes.bool,
  pushRegisterPremium: PropTypes.shape({
    title: PropTypes.string,
    ctaRoute: PropTypes.string,
    ctaLabel: PropTypes.string,
  }),
}

OnBoardingIntro.defaultProps = {
  className: '',
  title: '',
  background: '',
  subtitle: '',
  type: '',
  onNext: () => null,
  subscriptionCardFreemium: null,
  subscriptionCardPremium: null,
  onBoardingTableProps: null,
  isConnected: false,
  pushRegisterPremium: null,
}

export default withMemo()(OnBoardingIntro)
