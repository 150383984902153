import { fonts, colors, desktop } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    width: '100%',
    backgroundColor: ({ greyBackground }) => (greyBackground ? colors.honeydew : colors.pureWhite),
    display: 'flex',
    borderRadius: '2rem',
    flexDirection: 'row',
    flexWrap: 'wrap',
    overflow: 'hidden',
    padding: '2rem',
    margin: '2rem 0',
  },
  titleTextContainer: {
    textAlign: 'left',
    padding: '1rem',
    width: '100%',
    ...desktop({
      width: ({ hasMultipleChildren }) => (hasMultipleChildren ? '50%' : '100%'),
    }),
    color: ({ textBlue }) => (textBlue ? `${colors.secondary} !important` : colors.nightRider),
    '& p': {
      color: ({ textBlue }) => (textBlue ? `${colors.secondary} !important` : colors.nightRider),
    },
  },
  title: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '2rem',
    textTransform: 'uppercase',
    color: `${colors.darkBlue} !important`,
    paddingBottom: '2rem',
  },
  text: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontSize: '1.5rem',
    '& p': {
      paddingBottom: 0,
    },
  },
}
