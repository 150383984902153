import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { pageType, selectors as AppSelectors } from '../../redux/app/redux'
import withMemo from '../../decorators/WithMemo'
import routes from '../../routes'

import RegisterPush from './index'


function RegisterPushWrapper({ isCandidate, isCard, title, text, ctaLabel }) {
  const { t } = useTranslation()

  const pageSelector = useSelector(AppSelectors.page)
  const registerLink = pageSelector(pageType.ON_BOARDING).link

  const registerPushProps = {
    title: title || t('register_push_title'),
    text: text || isCandidate ? t('register_push_text_candidate') : t('register_push_text_company'),
    ctaLabel: ctaLabel || t('register_push_label'),
    ctaLink: registerLink,
    ctaRoute: routes.page.name,
    ctaRouteParams: { slug: registerLink },
    isCard,
  }

  return (
    <RegisterPush
      {...registerPushProps}
    />
  )
}

RegisterPushWrapper.propTypes = {
  isCandidate: PropTypes.bool,
  isCard: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  ctaLabel: PropTypes.string,
}

RegisterPushWrapper.defaultProps = {
  isCandidate: false,
  isCard: false,
  title: '',
  text: '',
  ctaLabel: '',
}

export default withMemo()(RegisterPushWrapper)
