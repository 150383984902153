import React, { useCallback, useEffect, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { useSelector, useDispatch } from 'react-redux'

import withMemo from '../../decorators/WithMemo'
import { selectors as AppSelectors, actions as AppActions } from '../../redux/app/redux'

import styles from './styles'
import types from './types'
import OnBoardingIntro from './steps/OnBoardingIntro'
import OnBoardingForm from './steps/OnBoardingForm'
import OnBoardingConfirm from './steps/OnBoardingConfirm'


const useStyles = createUseStyles(styles)

// eslint-disable-next-line complexity
function OnBoarding(props) {
  const classes = useStyles(props)
  const {
    className,
    introProps,
    headerProps,
    formProps,
    confirmProps,
    onSubmit,
    submitErrors,
    submitSuccess,
  } = props

  const dispatch = useDispatch()
  const { query } = useSelector(AppSelectors.route)

  const stepSelector = useSelector(AppSelectors.onBoardingStep)
  const typeSelector = useSelector(AppSelectors.onBoardingType)

  const selectPremium = query?.type === 'premium'

  const [type, setType] = useState(types.PREMIUM)
  const [step, setStep] = useState(selectPremium ? 1 : 0)

  const changeStep = useCallback((newStep) => {
    setStep(newStep)
    dispatch(AppActions.setOnBoardingStep(newStep))
  }, [dispatch])

  const nextStep = useCallback(() => changeStep(step + 1), [changeStep, step])
  const previousStep = useCallback(() => changeStep(step - 1), [changeStep, step])

  //
  // Handlers
  //
  const handleIntroComplete = (t) => {
    setType(t)
    dispatch(AppActions.setOnBoardingType(t))
    nextStep()
  }

  const handleSubmit = (values) => {
    onSubmit(values)
  }

  //
  // Effects
  //
  useEffect(() => {
    if (submitSuccess && step === 1) {
      nextStep()
    }
  }, [nextStep, step, submitSuccess])

  useEffect(() => {
    if (type === types.PREMIUM && formProps?.linkedInConnectPushProps?.linkedInData) {
      setStep(2)
    }
  }, [formProps, type])

  const linkedInData = type === types.PREMIUM ? formProps?.linkedInConnectPushProps?.linkedInData || {} : {}

  //
  // Those selectors are used to get the step / type from the subscription table composant because it's
  // a block that is different from the onBoarding component.
  //
  useEffect(() => {
    setStep(stepSelector)
    setType(typeSelector)
  }, [stepSelector, typeSelector])

  useEffect(() => () => {
    dispatch(AppActions.setOnBoardingStep(0))
  }, [dispatch, submitSuccess])

  //
  // Subscription Table is a block that can also be on Homepage, it must only be on component OnBoardingIntro
  // so we need to make sure that it is not visible on other step
  //
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const table = document.getElementById('subscriptionTable')
      const tables = document.getElementsByClassName('onBoardingTable')

      if (tables[1]) {
        tables[1].style.display = 'none'
      }

      if (table) {
        if (step === 0) {
          table.style.display = 'block'
        } else if (step === 1) {
          table.style.display = 'none'
        } else if (step === 2) {
          table.style.display = 'none'
        }
      }
    }
  })

  return (
    <div className={cx(classes.container, className)}>
      <div
        className={cx(classes.wrapper)}
      >
        {step === 0 && (
          <OnBoardingIntro
            {...introProps}
            type={type}
            onNext={handleIntroComplete}
            className={classes.step}
          />
        )}
        {step === 1 && (
          <OnBoardingForm
            {...formProps}
            {...headerProps}
            type={type}
            errors={submitErrors}
            onNext={handleSubmit}
            onPrevious={previousStep}
            className={classes.step}
            initialValues={linkedInData}
          />
        )}
        {step === 2 && (
          <OnBoardingConfirm
            {...confirmProps}
            {...headerProps}
            type={type}
            className={classes.step}
          />
        )}
      </div>
    </div>
  )
}

OnBoarding.styles = styles

OnBoarding.propTypes = {
  className: PropTypes.string,
  introProps: PropTypes.shape(OnBoardingIntro.propTypes),
  formProps: PropTypes.shape(OnBoardingForm.propTypes),
  headerProps: PropTypes.shape({
    headerTitle: PropTypes.string,
    background: PropTypes.string,
  }),
  confirmProps: PropTypes.shape(OnBoardingConfirm.propTypes),
  onSubmit: PropTypes.func,
  submitErrors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    field: PropTypes.string,
  })),
  submitSuccess: PropTypes.bool,
}

OnBoarding.defaultProps = {
  className: '',
  introProps: OnBoardingIntro.defaultProps,
  headerProps: null,
  formProps: OnBoardingForm.defaultProps,
  confirmProps: OnBoardingConfirm.defaultProps,
  onSubmit: () => null,
  submitErrors: null,
  submitSuccess: false,
}

export default withMemo()(OnBoarding)
