export const locationType = {
  FRANCE: 'FRANCE',
  MONACO: 'MONACO',
  LUXEMBOURG: 'LUXEMBOURG',
}

export const locationTypeArray = [
  locationType.FRANCE,
  locationType.MONACO,
  locationType.LUXEMBOURG,
]

export const locationTypeOptions = (t) => locationTypeArray.map((value) => ({
  label: t(`locationType_${value}`),
  value,
}))
