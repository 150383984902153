import { pageType } from '../../redux/app/redux'
import routes from '../../routes'


export default (props, { pages }) => ({
  __typename: 'RegisterPush',
  ...props.title && {
    title: props.title,
  },
  ...props.text && {
    text: props.text,
  },
  ...props.cta_title && props.cta_title !== '' && {
    ctaLink: pages[pageType.ON_BOARDING].link,
    ctaLabel: props.cta_title,
    ctaRoute: routes.page.name,
    ctaRouteParams: { slug: pages[pageType.ON_BOARDING].link },
  },
})
