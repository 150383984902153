import Color from 'color'

import { fonts, colors, transition, tablet } from '../../theme'


export default {
  container: {
    borderRadius: '2rem',
    border: `.1rem solid ${colors.honeydew}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    ...transition('all'),
    transition: 'all 0.2s ease 0s',
    '&:hover': {
      backgroundColor: colors.honeydew,
      transform: 'translateY(-2px)',
      boxShadow: 'rgba(84, 84, 84, 0.2) 0px 5px 30px',
      '& $btnIcon': {
        marginLeft: '1.2rem',
        opacity: 0.8,
      },
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'end',
    padding: '2rem',
    width: '100%',
    height: '100%',
    background: colors.honeydew,
  },
  typeLabel: {
    position: 'relative',
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    color: colors.frenchBlue,
    letterSpacing: '.1rem',
    textTransform: 'uppercase',
    marginBottom: '2.4rem',
    '&:not($noBorder):after': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: '-1.2rem',
      width: '2.5rem',
      height: '.2rem',
      backgroundColor: colors.spanishOrange,
    },
    '&$colorWhite:after': {
      backgroundColor: colors.spanishOrange,
    },
  },
  title: {
    ...fonts.FiraSansMedium,
    fontSize: '1.8rem',
    lineHeight: '2.2rem',
    color: colors.spanishOrange,
    marginBottom: '1.2rem',
  },
  description: {
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    lineHeight: '2rem',
    color: colors.gray2,
  },
  btn: {
    ...fonts.FiraSansBold,
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    color: colors.darkBlue,
    marginTop: '2rem',
    textTransform: 'uppercase',
  },
  btnIcon: {
    marginLeft: '.8rem',
    ...transition('all'),
  },
  colorOrange: {
    color: colors.spanishOrange,
  },
  colorWhite: {
    color: colors.pureWhite,
  },
  cover: {
    display: 'flex',
    justifyContent: 'flex-start',
    backgroundPosition: 'top center',
  },
  featuredLabel: {
    ...fonts.FiraSansBold,
    fontSize: '1rem',
    lineHeight: '1.2rem',
    color: colors.darkOrange,
    textTransform: 'uppercase',
    padding: '.5rem 1.3rem',
    borderRadius: '.3rem',
    backgroundColor: colors.aliceBlue,
    marginBottom: '1.3rem',
  },
  noBorder: {
  },
  isPortrait: {
    paddingTop: '20rem',
    backgroundColor: (props) => Color(props.category.bgColor || colors.frenchBlue).fade(0.2).string(),
  },
  isFeatured: {
    height: 'auto',
    marginTop: '23rem',
    backgroundColor: Color(colors.darkBlue).fade(0.1).string(),
    ...tablet({
      marginTop: 0,
      width: '30rem',
      padding: '4rem 2rem',
      minHeight: '41.5rem',
    }),
  },
}
