import React from 'react'
import { useSelector } from 'react-redux'

import { selectors as AuthSelectors } from '../../redux/auth/redux'

import DirectoryPublic from './index'


function DirectoryPublicWrapper(props) {
  const isPremium = useSelector(AuthSelectors.isActivePremium)
  const isConnected = useSelector(AuthSelectors.isConnected)

  const data = {
    ...props,
    isConnected,
  }

  if (isPremium) {
    return null
  }

  return <DirectoryPublic {...data} />
}

DirectoryPublicWrapper.propTypes = DirectoryPublic.propTypes

DirectoryPublicWrapper.defaultProps = DirectoryPublic.defaultProps

export default DirectoryPublicWrapper
