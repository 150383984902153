export const filterCompanyType = {
  FREEMIUM: 'FREEMIUM',
  PREMIUM: 'PREMIUM',
  AMBASSADOR: 'AMBASSADOR',
  OGCN: 'OGCN',
}

export const filterCompanyTypeArray = [
  filterCompanyType.FREEMIUM,
  filterCompanyType.PREMIUM,
  filterCompanyType.AMBASSADOR,
  filterCompanyType.OGCN,
]

const filterCompanyTypeSlugs = {
  [filterCompanyType.FREEMIUM]: 'standard',
  [filterCompanyType.PREMIUM]: 'premium',
  [filterCompanyType.AMBASSADOR]: 'ambassador',
  [filterCompanyType.OGCN]: 'ogcn',
}

export const filterCompanyTypeConfig = filterCompanyTypeArray.map((value) => ({
  key: `filterCompanyType_${value}`,
  id: value,
  value: filterCompanyTypeSlugs[value],
}))

export const filterCompanyTypeOptions = (t) => filterCompanyTypeArray.map((value) => ({
  label: t(`filterCompanyType_${value}`),
  value,
}))
