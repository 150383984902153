import Post from '../../data/models/Post'
import routes from '../../routes'


export default (
  { filters, postsByFilters },
  { filterConfig, filterValues }
) => ({
  __typename: 'PostsResultsWrapper',
  filters,
  filterValues,
  filterConfig,
  ...postsByFilters.data && postsByFilters.data.length >= 1 && {
    posts: postsByFilters.data.map((post) => Post(post)),
    ...postsByFilters.paginatorInfo && {
      pagination: {
        currentPage: postsByFilters.paginatorInfo.currentPage,
        totalPage: postsByFilters.paginatorInfo.lastPage,
        total: postsByFilters.paginatorInfo.total,
        route: routes.pagePaginated.name,
        routeBase: routes.page.name,
      },
    },
  },
})
