import { colors, desktop } from '../../theme'


export const minHeight = {
  minHeight: 'calc(100vh - 6.4rem)',
  ...desktop({
    minHeight: 'calc(100vh - 10rem)',
  }),
}

export default {
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: colors.aliceBlue,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    position: 'relative',
    ...minHeight,
  },
  wrapper: {
    width: '100%',
  },
  stepContainer: {
    willChange: 'transform',
    top: 0,
    left: 0,
    width: '100%',
  },
  step: {},
}
