import { colors, fonts } from '../../theme'


export default {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  text: {
    paddingTop: 0,
  },
  filterTitle: {
    ...fonts.FiraSansMedium,
    color: colors.darkBlue,
    fontSize: '1.8rem',
    margin: '0 1rem 1rem 2rem',
  },
  filters: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0 1rem',
    marginBottom: '3rem',
    zIndex: 3,
  },
  filterBlock: {
    margin: '0 1rem',
  },
  results: {
    padding: 0,
    position: 'relative',
  },
  empty: {
    ...fonts.FiraSansRegular,
    textAlign: 'center',
    padding: '2rem',
  },
  scrollTop: {
    position: 'absolute',
    top: '100%',
    right: '1rem',
  },
  divider: {
    width: '70%',
    height: '2px',
    alignSelf: 'center',
    backgroundColor: colors.gainsboro,
    marginTop: '3rem',
  },
}
