import { colors, fonts, tablet } from '../../theme'


export default {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: colors.pureWhite,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '94rem',
    alignItems: 'center',
    textAlign: 'left',
    padding: '4rem 2rem',
  },
  title: {
    ...fonts.FiraSansBold,
    fontSize: '2rem',
    lineHeight: '2.6rem',
    margin: '2rem 0 2rem',
    width: '100%',
    color: colors.nightRider,
    '& strong': {
      color: colors.darkBlue,
    },
    ...tablet({
      width: '60%',
      alignSelf: 'flex-start',
    }),
  },
  text: {
    ...fonts.FiraSansRegular,
    fontSize: '1.5rem',
    lineHeight: '120%',
    margin: '0 0 5rem',
    width: '100%',
    color: colors.nightRider,
    ...tablet({
      width: '60%',
      alignSelf: 'flex-start',
    }),
  },
  cards: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: '0 0 3rem',
    width: '100%',
    ...tablet({
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'stretch',
      justifyContent: 'center',
      marginLeft: '-1rem',
      marginRight: '-1rem',
      '& > *': {
        maxWidth: '28rem',
        marginLeft: '1rem',
        marginRight: '1rem',
      },
    }),
  },
  card: {
    marginBottom: '2rem',
    width: '100%',
    boxShadow: '0px 5px 30px rgba(84, 84, 84, 0.1)',
  },
}
