import { offerTypeConfig } from '../../graphql/enums/offerType'
import { offerBudgetConfig } from '../../graphql/enums/offerBudget'
import { offerDeadlineConfig } from '../../graphql/enums/offerDeadline'
import { offerSourceOptions } from '../../graphql/enums/offerSource'


export const offersPrefix = 'offersResults'

export const offersFiltersConfig = (t, categories) => [
  {
    isMulti: false,
    isPath: true,
    name: 'type',
    slug: 'type',
    prefix: 'offerType',
    options: offerTypeConfig,
  },
  {
    type: 'radio',
    isMulti: false,
    isPath: true,
    name: 'categories',
    slug: 'categories',
    prefix: 'filterCategories',
    options: categories?.map((s) => ({
      label: s.name,
      value: s.id,
      name: s.id,
      id: s.id,
    })),
  },
  {
    type: 'radio',
    isMulti: false,
    isPath: true,
    name: 'budget',
    slug: 'budget',
    prefix: 'offerBudget',
    options: offerBudgetConfig,
  },
  {
    type: 'checkbox',
    isPath: true,
    name: 'deadline',
    slug: 'deadline',
    prefix: 'offerDeadline',
    options: offerDeadlineConfig,
  },
  {
    visible: false,
    isPath: true,
    name: 'source',
    slug: 'source',
    prefix: 'offerSource',
    options: offerSourceOptions(t),
  },
]

export const offersSortConfig = [
  {
    name: 'date',
    slug: 'par-date',
    field: 'published_at',
    order: 'DESC',
  },
  {
    name: 'pertinence',
    slug: 'par-pertinence',
    field: 'pertinence',
    order: 'DESC',
  },
  {
    name: 'budget_asc',
    slug: 'par-budget-croissant',
    field: 'budget',
    order: 'ASC',
  },
  {
    name: 'budget_desc',
    slug: 'par-budget-decroissant',
    field: 'budget',
    order: 'DESC',
  },
  {
    name: 'city',
    slug: 'par-lieu',
    field: 'city',
    order: 'ASC',
  },
]
