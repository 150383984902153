import Article from '../../data/models/Article'
import routes from '../../routes'


export default (
  { text, highlighted, articlesByFilters, filters },
  { filterConfig, filterValues }
) => ({
  __typename: 'ArticleContainerBlock',
  filters,
  filterValues,
  filterConfig,
  ...text && {
    text,
  },
  ...articlesByFilters?.data && articlesByFilters?.data.length !== 0 && {
    articles: articlesByFilters?.data.map((articleProps) => Article(articleProps)),
  },
  ...articlesByFilters?.paginatorInfo && {
    pagination: {
      currentPage: articlesByFilters.paginatorInfo.currentPage,
      totalPage: articlesByFilters.paginatorInfo.lastPage,
      total: articlesByFilters.paginatorInfo.total,
      route: routes.pagePaginated.name,
      routeBase: routes.page.name,
    },
  },
})
