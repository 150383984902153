import { colors, fonts, sizes, tablet } from '../../../../theme'


export default {
  container: {
    background: colors.honeydew,
    ...tablet({
      padding: '14rem 0 0',
    }),
  },
  wrapper: {
    maxWidth: `${sizes.desktop / 10}rem`,
    padding: '5rem 2rem',
    margin: '0 auto',
    position: 'relative',
  },
  title: {
    ...fonts.FiraSansBold,
    fontSize: '3rem',
    lineHeight: '120%',
    marginTop: '0',
    marginBottom: '1rem',
    color: colors.nightRider,
    '& strong': {
      color: colors.darkBlue,
    },
    ...tablet({
      width: '60%',
    }),
  },
  text: {
    marginBottom: '2rem',
    ...tablet({
      width: '60%',
    }),
  },
  table: {
    background: colors.pureWhite,
    marginBottom: '3rem',
  },
  cta: {},
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}
