import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import MarkdownText from '../../../MarkdownText'
import withMemo from '../../../../decorators/WithMemo'
import HowToEntry from '../../../../data/models/HowToEntry'
import CloudinaryImage from '../../../CloudinaryImage'

import styles from './styles'


const useStyles = createUseStyles(styles)

const HowTo = (props) => {
  const classes = useStyles(props)
  const {
    className,
    title,
    entries,
  } = props

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.background}>
        <div className={classes.backgroundContent} />
      </div>
      <div className={classes.wrapper}>
        <MarkdownText
          tag="h2"
          className={classes.title}
          text={title}
          inline
        />
        <div className={classes.entries}>
          {
          entries?.map((entry, key) => (
            <div
              key={key}
              className={classes.entry}
            >
              <CloudinaryImage
                className={classes.entryLogo}
                id={entry.logo}
              />
              <MarkdownText
                className={classes.entryText}
                text={entry.text}
                inline
              />
            </div>
          ))
          }
        </div>
      </div>
    </div>
  )
}

HowTo.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  entries: PropTypes.arrayOf(HowToEntry.propTypes),
}

HowTo.defaultProps = {
  className: '',
  title: '',
  entries: null,
}

export default withMemo()(HowTo)
