import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import Linkable from '../../data/models/Linkable'
import EventModel from '../../data/models/Event'
import OfferLastOffers from '../OfferLastOffers'
import HowToEntry from '../../data/models/HowToEntry'
import MembershipEntry from '../../data/models/MembershipEntry'
import Banner from '../Banner'
import { bannerTypes } from '../Banner/types'

import styles from './styles'
import SubscriptionPlan from './fragments/SubscriptionPlan'
import LastEvents from './fragments/LastEvents'
import HowTo from './fragments/HowTo'
import Membership from './fragments/Membership'


const useStyles = createUseStyles(styles)

const HomePageBlock = (props) => {
  const classes = useStyles(props)
  const {
    className,
    registerBanner,
    registerTitle,
    registerCtaLabel,
    registerCtaLinkable,
    membershipTitle,
    membershipText,
    membershipEntries,
    manifestLabel,
    manifestLink,
    howToTitle,
    howToEntries,
    eventsTitle,
    eventsEntries,
    eventsCtaLabel,
    eventsCtaLinkable,
    eventsCtaUrl,
    offersTitle,
    offersText,
    premiumPrice,
    discount,
    offersCtaLabel,
    offersCtaLinkable,
    offersCtaUrl,
    lastOffers,
  } = props

  return (
    <div className={cx(classes.container, className)}>
      <Banner
        className={classes.banner}
        image={registerBanner}
        title={registerTitle}
        ctaLabel={registerCtaLabel}
        ctaLinkable={registerCtaLinkable}
        format={bannerTypes.HEADER_TYPE_HOMEPAGE}
      />
      <Membership
        className={classes.membership}
        title={membershipTitle}
        text={membershipText}
        entries={membershipEntries}
        ctaLabel={manifestLabel}
        ctaLinkable={manifestLink}
      />
      <HowTo
        className={classes.howto}
        title={howToTitle}
        entries={howToEntries}
      />
      <LastEvents
        className={classes.events}
        title={eventsTitle}
        events={eventsEntries}
        ctaLabel={eventsCtaLabel}
        ctaLinkable={eventsCtaLinkable}
        ctaUrl={eventsCtaUrl}
      />
      <SubscriptionPlan
        className={classes.offers}
        title={offersTitle}
        text={offersText}
        price={premiumPrice}
        discount={discount}
        ctaLabel={offersCtaLabel}
        ctaLinkable={offersCtaLinkable}
        ctaUrl={offersCtaUrl}
      />
      {lastOffers && <OfferLastOffers {...lastOffers} />}
    </div>
  )
}

HomePageBlock.propTypes = {
  className: PropTypes.string,
  registerBanner: PropTypes.string,
  registerTitle: PropTypes.string,
  registerCtaLabel: PropTypes.string,
  registerCtaLinkable: Linkable.propTypes,
  membershipTitle: PropTypes.string,
  membershipText: PropTypes.string,
  membershipEntries: PropTypes.arrayOf(MembershipEntry),
  manifestLabel: PropTypes.string,
  manifestLink: Linkable.propTypes,
  howToTitle: PropTypes.string,
  howToEntries: PropTypes.arrayOf(HowToEntry.propTypes),
  eventsTitle: PropTypes.string,
  eventsEntries: PropTypes.arrayOf(EventModel.propTypes),
  eventsCtaLabel: PropTypes.string,
  eventsCtaLinkable: Linkable.propTypes,
  eventsCtaUrl: PropTypes.string,
  offersTitle: PropTypes.string,
  offersText: PropTypes.string,
  premiumPrice: PropTypes.string,
  discount: PropTypes.string,
  offersCtaLabel: PropTypes.string,
  offersCtaUrl: PropTypes.string,
  offersCtaLinkable: Linkable.propTypes,
  lastOffers: PropTypes.object,
}

HomePageBlock.defaultProps = {
  className: '',
  registerBanner: null,
  registerTitle: null,
  registerCtaLabel: null,
  registerCtaLinkable: null,
  membershipTitle: null,
  membershipText: null,
  membershipEntries: null,
  manifestLabel: null,
  manifestLink: null,
  howToTitle: null,
  howToEntries: null,
  eventsTitle: null,
  eventsEntries: null,
  eventsCtaLabel: null,
  eventsCtaLinkable: null,
  eventsCtaUrl: null,
  offersTitle: '',
  offersText: '',
  premiumPrice: '',
  discount: '',
  offersCtaLabel: '',
  offersCtaUrl: '',
  offersCtaLinkable: null,
  lastOffers: null,
}

export default withMemo()(HomePageBlock)
