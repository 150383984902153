import { colors, desktop, textStyles } from '../../theme'


export default {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
  },
  background: {
    position: 'absolute',
    width: '100%',
    height: '12rem',
    top: 0,
    left: 0,
    background: 'linear-gradient(180deg, rgba(55, 105, 255, 0.2) 0%, rgba(243, 250, 255, 0) 100%);',
  },
  wrapper: {
    position: 'relative',
    width: '100%',
    maxWidth: '98rem',
    margin: '0 auto',
  },
  title: {
    ...textStyles.H1,
    color: colors.nightRider,
    position: 'relative',
    padding: '5rem 1.5rem 2rem',
    margin: 0,
    width: '100%',
    textAlign: 'center',
    ...desktop({
      paddingBottom: '3.5rem',
      textAlign: 'left',
    }),
  },
  content: {
    marginTop: '2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    ...desktop({
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    }),
  },
  main: {
    width: '100%',
    ...desktop({
      width: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    }),
  },
  side: {
    width: '100%',
    padding: '0 1.5rem 4rem',
    ...desktop({
      width: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& > *': {
        maxWidth: '46rem',
      },
    }),
  },
  solo: {
    ...desktop({
      width: '100%',
    }),
  },
}
