import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Router } from '../../routes'
import { selectors as AppSelectors } from '../../redux/app/redux'
import withMemo from '../../decorators/WithMemo'
import {
  buildQueryWithSlug,
  getFilterOptions,
  getPagination,
  getSortOptions,
  parseQueryParams
} from '../../helpers/FilterHelpers'
import { directoryType } from '../../graphql/enums/DirectoryType'
import { certificationType } from '../../graphql/enums/CertificationType'
import { selectors as AuthSelectors } from '../../redux/auth/redux'

import DirectoryList from './index'
import { companyFiltersConfig, companyPrefix, profilePrefix } from './config'


function DirectoryListWrapper(props) {
  const { t } = useTranslation()
  const route = useSelector(AppSelectors.route)
  const isOGCN = useSelector(AuthSelectors.isOGCN)
  const {
    sortConfig, filterConfig,
    filterValues, sortValue, filters,
    companies, profiles, type, pagination, ...rest
  } = props

  const handleSubmit = useCallback((p, resetPage = false) => {
    let href = buildQueryWithSlug(route, filterConfig, pagination, p, resetPage)

    if (route.query?.s) {
      href = `${href}&s=${route.query.s}`
    }

    Router.pushRoute(href)
  }, [route, filterConfig, pagination])

  const params = {
    ...filters,
    ...parseQueryParams(route?.asPath, route?.slug),
  }

  const sector = filterValues.find((value) => value.name === 'sector')?.value ?? params?.sector

  const certifications = useSelector(AppSelectors.certifications)
  const sectors = useSelector(AppSelectors.sectors)
  const domains = sectors.find((s) => s.id === sector)?.domains

  const rseCertifications = useMemo(() => (
    certifications
      ?.filter((c) => c.type === certificationType.CERTIFICATION_TYPE_RSE)
  ), [certifications])

  const isCompany = useMemo(() => type === directoryType.COMPANY, [type])
  const config = useMemo(() => (
    isCompany ? companyFiltersConfig(rseCertifications, sectors, domains, t) : filterConfig
  ), [domains, filterConfig, isCompany, rseCertifications, sectors, t])
  const prefix = useMemo(() => (isCompany ? companyPrefix : profilePrefix), [isCompany])
  const filterOptions = useMemo(() => getFilterOptions(t, config, prefix), [config, prefix, t])
  const sortOptions = useMemo(() => getSortOptions(t, sortConfig, prefix), [prefix, sortConfig, t])

  // OGCN filter only visible for OGCN members and directory type is company
  const newFilterOptions = isOGCN
    ? filterOptions
    : type === directoryType.COMPANY
      ? [filterOptions[0],
        { ...filterOptions[1], options: filterOptions[1].options.filter((o) => o.value !== 'ogcn') },
        filterOptions[2],
        filterOptions[3],
        filterOptions[4],
      ]
      : filterOptions

  // Pagination params
  const paginationProps = useMemo(() => {
    const p = getPagination(pagination, route?.asPath, route?.query, params)

    return p
  }, [pagination, params, route])

  const data = useMemo(() => ({
    ...props,
    sortBoxProps: {
      placeholder: t('companiesResults_sortPlaceholder'),
      options: sortOptions,
      value: sortValue,
    },
    filterBoxProps: {
      placeholder: isCompany ? t('companiesResults_filterPlaceholder') : t('profilesResults_filterPlaceholder'),
      filters: newFilterOptions,
      values: filterValues,
    },
    entries: isCompany ? companies : profiles,
    emptyText: isCompany ? t('companiesResults_emptyLabel') : t('profilesResults_emptyLabel'),
    pagination: paginationProps,
    isCompany,
  // eslint-disable-next-line max-len
  }), [companies, newFilterOptions, filterValues, isCompany, paginationProps, profiles, props, sortOptions, sortValue, t])

  return (
    <DirectoryList
      {...rest}
      {...data}
      handleSubmit={handleSubmit}
    />
  )
}

DirectoryListWrapper.propTypes = DirectoryList.propTypes

DirectoryListWrapper.defaultProps = DirectoryList.defaultProps

export default withMemo()(DirectoryListWrapper)
