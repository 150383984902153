import routes from '../../routes'
import { routeParamSlug } from '../../helpers/UrlHelpers'


export default (props) => ({
  __typename: 'OfferPublicBlock',
  ...props.title && {
    title: props.title,
  },
  ...props.introduction_before_blue && {
    introductionBeforeBlue: props.introduction_before_blue,
  },
  ...props.introduction_blue && {
    introductionBlue: props.introduction_blue,
  },
  ...props.introduction_after_blue && {
    introductionAfterBlue: props.introduction_after_blue,
  },
  ...props.premium_title && {
    premium_title: props.premium_title,
  },
  ...props.premium_text && {
    premium_text: props.premium_text,
  },
  ...props.premium_linkable && (props.premium_linkable?.__typename === 'Page' ? {
    route: routes.page.name,
    routeParams: {
      slug: routeParamSlug(props.premium_linkable.full_slug),
    },
  } : {
    route: props.premium_linkable?.key,
  }),
  ...props.premium_cta_title && props.url && props.url !== '' && {
    ctaLink: props.premium_url,
  },
  ...props.premium_cta_title && props.premium_linkable?.full_slug && {
    ctaLink: props.premium_linkable.full_slug,
  },
  ctaLabel: props.premium_cta_title,
  ...props.title_text_containers && {
    titleTextContainers: props.title_text_containers.map((title_text_container) => {
      const titleTextContainer = title_text_container

      titleTextContainer.titleTexts = titleTextContainer.title_texts

      return titleTextContainer
    }),
  },
})
